import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "./Onboarding.css";
import "./Responsive.css";
import AuthService from "../../../services/auth.service";
import OccupcatService from "../../../services/occupcat.service";
import StdOnboardService from "../../../services/stdonboard.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StepOne = ({ nextStep }) => {
  const [validated, setValidated] = useState(false);
  const [checked, setChecked] = useState("");
  const [checkedSubOccup, setCheckedSubOccup] = useState("");
  const [showSubOccup, setShowSubOccup] = useState(false);
  const [occupations, setOccupations] = useState([]);
  const [subOccupation, setSubOccupation] = useState([]);
  const [showPriority, setShowPriority] = useState(false);
  const [priority, setPriority] = useState("");

  const [selectedOpts, setSelectedOpts] = useState({
    occupation: "",
    suboccupation: [],
    priority: "",
  });

  const priorityOpts = [
    {
      id: "1",
      name: "Tracking",
    },
    {
      id: "2",
      name: "Completing assigned course",
    },
    {
      id: "3",
      name: "Finding new course",
    },
  ];

  const handleOccupationChange = (e, l) => {
    if (e.target.checked) {
      setChecked(l);
      OccupcatService.getSubOccupCat(l)
        .then((response) => {
          setShowSubOccup(true);
          setSubOccupation(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setChecked("");
    }
  };

  const handlePriorityChange = (e, l) => {
    if (e.target.checked) {
      setPriority(l);
    } else {
      setPriority("");
    }
  };

  const handleSubOccupationChange = (e, l) => {
    let selectedUserArr = [];
    if (e.target.checked) {
      selectedUserArr = [...checkedSubOccup, l.id];
      setCheckedSubOccup([...checkedSubOccup, l.id]);
      setShowPriority(true);
    } else {
      selectedUserArr = checkedSubOccup.filter((item) => item !== l.id);
      setCheckedSubOccup(checkedSubOccup.filter((item) => item !== l.id));
    }
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    OccupcatService.getOccupCat(user.user)
      .then((response) => {
        setOccupations(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    StdOnboardService.getOnboardStepData()
      .then((resp) => {
        setSelectedOpts({
          occupation: resp.data.occupation,
          suboccupation: resp.data.suboccupation.split(","),
          priority: resp.data.priority,
        });

        setChecked(resp.data.occupation);

        if (resp.data.suboccupation !== "") {
          setShowSubOccup(true);

          OccupcatService.getSubOccupCat(resp.data.occupation)
            .then((response) => {
              setShowSubOccup(true);
              setSubOccupation(response.data);
              setCheckedSubOccup(resp.data.suboccupation.split(","));
            })
            .catch((e) => {
              console.log(e);
            });
        }

        if (resp.data.priority !== "") {
          setShowPriority(true);
          setPriority(resp.data.priority);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      occupation: checked,
      suboccupation: checkedSubOccup,
      priority: priority,
    };

    StdOnboardService.onboardStepOne(data)
      .then((resp) => {
        toast.success("Onboarding data saved successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        nextStep();
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <ToastContainer />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="row learner-onboard mb-3">
          <div className="col-xl-6 col-lg-6">
            <div className="occup">
              <Form.Group controlId="formBasicOrgName">
                <Form.Label>Select which applies to you the best</Form.Label>
                <div className="multiple-input active admin">
                  <div className="addedCat grid">
                    {Array.isArray(occupations) &&
                      occupations.map((occupation, index) => {
                        return (
                          <div
                            className={
                              checked === occupation.id
                                ? "addedCat-item checked"
                                : "addedCat-item"
                            }
                            key={index}
                          >
                            <input
                              type="checkbox"
                              // value={selectedOpts.occupation}
                              checked={checked === occupation.id}
                              onChange={(e) =>
                                handleOccupationChange(e, occupation.id)
                              }
                            />
                            <span>{occupation.category_name}</span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Form.Group>
              {showSubOccup ? (
                <>
                  <Form.Group controlId="formBasicOrgName">
                    <Form.Label>Select topics you are interested in</Form.Label>
                    <div className="multiple-input active admin">
                      <div className="addedCat grid">
                        {Array.isArray(subOccupation) &&
                          subOccupation.map((subOccu, index) => {
                            return (
                              <div
                                className={
                                  checkedSubOccup.includes(subOccu.id)
                                    ? "addedCat-item checked"
                                    : "addedCat-item"
                                }
                                key={subOccu.id}
                              >
                                <input
                                  type="checkbox"
                                  value={checkedSubOccup.includes(
                                    selectedOpts.suboccupation
                                  )}
                                  checked={checkedSubOccup.includes(subOccu.id)}
                                  onChange={(e) =>
                                    handleSubOccupationChange(e, subOccu)
                                  }
                                />
                                <span>{subOccu.subcategory_name}</span>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </Form.Group>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            {showPriority ? (
              <>
                <div className="priority">
                  <label htmlFor="">Select Priority</label>
                  <div className="priority-list">
                    {Array.isArray(priorityOpts)
                      ? priorityOpts.map((priorityOpt) => {
                          return (
                            <div
                              className={
                                priority === priorityOpt.id
                                  ? "item checked"
                                  : "item"
                              }
                              key={priorityOpt.id}
                            >
                              <input
                                type="radio"
                                value={selectedOpts.priority}
                                checked={priority === priorityOpt.id}
                                onChange={(e) =>
                                  handlePriorityChange(e, priorityOpt.id)
                                }
                              />
                              <span>{priorityOpt.name}</span>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="flex-end">
          <input type="submit" className="onboard-btn-f" value="Next" />
        </div>
      </Form>
    </>
  );
};

export default StepOne;
