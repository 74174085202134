import React, { useEffect } from "react";

import UserService from "../services/user.service";

import AuthService from "../services/auth.service";

const LearnersContext = React.createContext();

const AllLearnersProvider = ({ children }) => {
  const [learners, setLerners] = React.useState([]);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    UserService.getLearners(user.user)
      .then((response) => {
        const learnersData = response.data;
        setLerners(learnersData);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <LearnersContext.Provider value={{ learners }}>
      {children}
    </LearnersContext.Provider>
  );
};

export const useLearnersListContext = () => React.useContext(LearnersContext);

export default AllLearnersProvider;
