import React from "react";
import { useState } from "react";

import Onboarding from "./Onboarding";
import Organization from "./Organisation";
import OccupationCat from "./OccupationCat";
import OccupationCatSecond from "./OccupationCatSecond";
import Permission from "./Permission";
import Categories from "./Categories";

const Steps = () => {
  const [step, setStep] = useState(1);

  const values = {};

  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const skip = () => {
    setStep(step + 2);
  };

  const skipBack = () => {
    setStep(step - 2);
  };

  // const handleChange = (count) => {
  //   setStep(count);
  // }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-4">
            <div className="left-flex">
              <div className="fixed">
                <div className="heading flex">
                  <h1>Configure LMS</h1>
                </div>
                <div className="right-border"></div>
                <Onboarding steps={step} />
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-8">
            <div className="onboard-right">
              {step === 1 ? (
                <>
                  <Organization
                    nextStep={nextStep}
                    // handleChange={(e) => handleChange(1)}
                    values={values}
                  />
                </>
              ) : step === 2 ? (
                <>
                  <OccupationCat
                    prevStep={prevStep}
                    nextStep={nextStep}
                    skip={skip}
                    // handleChange={(e) => handleChange(2)}
                    values={values}
                  />
                </>
              ) : step === 3 ? (
                <>
                  <OccupationCatSecond
                    prevStep={prevStep}
                    nextStep={nextStep}
                    // handleChange={handleChange}
                    values={values}
                  />
                </>
              ) : step === 4 ? (
                <>
                  <Permission
                    prevStep={prevStep}
                    nextStep={nextStep}
                    skipBack={skipBack}
                    // handleChange={handleChange}
                    values={values}
                  />
                </>
              ) : step === 5 ? (
                <>
                  <Categories
                    prevStep={prevStep}
                    nextStep={nextStep}
                    // handleChange={handleChange}
                    values={values}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Steps;
