import http from "../http-common";

const getEstoreCourses = () => {
  return http.get("/api/getestorcsguest", {
    headers: { "Content-Type": "application/json" },
  });
};

const getCourseById = (cid) => {
  return http.get(`/api/getestorcoresguest?cid=${cid}`, {
    headers: { "Content-Type": "application/json" },
  });
};

const getCourseActById = (csid) => {
  return http.get(`/api/getestorcsactguest?csid=${csid}`, {
    headers: { "Content-Type": "application/json" },
  });
};

const getEstoreCourseById = (cid) => {
  return http.get(`/api/getestorcsguestbid?cid=${cid}`, {
    headers: { "Content-Type": "application/json" },
  });
};

const getEstoreCatGuest = (cid) => {
  return http.get(`/api/getestorcscatguest`, {
    headers: { "Content-Type": "application/json" },
  });
};

const getEstoreOccupCatGuest = (cid) => {
  return http.get(`/api/getestoroccucatguest`, {
    headers: { "Content-Type": "application/json" },
  });
};

const getEstoreCourseFilterGuest = (data) => {
  return http.post(`/api/getcsoccupfilt`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

const getGuestAllCourses = () => {
  return http.get(`/api/guestcourse`, {
    headers: { "Content-Type": "application/json" },
  });
};

const getAllCourseFilterGuest = (data) => {
  return http.post(`/api/getcsfilt`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

const addGuestUser = (data) => {
  return http.post(`/api/guestRegister`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

const GuestuserService = {
  getEstoreCourses,
  getCourseById,
  getCourseActById,
  getEstoreCourseById,
  getEstoreCatGuest,
  getEstoreOccupCatGuest,
  getEstoreCourseFilterGuest,
  getGuestAllCourses,
  getAllCourseFilterGuest,
  addGuestUser,
};

export default GuestuserService;
