import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CertificatePreview from "./CertificatePreview";
import { ColorRing } from "react-loader-spinner";

const Preview = ({
  cerImgSrc,
  stageWidth,
  stageHeight,
  addedElements,
  currentUser,
  courseName,
  allCerts,
  cid,
  userId,
  certLoading,
  catid,
}) => {
  let courseShortName = "";

  if (allCerts && allCerts.course_name) {
    const words = allCerts.course_name.split(" ");
    const initials = words.map((word) => word[0]).join("");
    courseShortName = initials.toUpperCase();
  } else {
    console.log("Invalid data or missing course_name");
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = allCerts ? new Date(allCerts.awarded_on) : new Date();
  const monthName = months[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const formattedDate = `${monthName} ${day}, ${year}`;

  return (
    <div className="cert-flex">
      {certLoading ? (
        <>
          <div className="loading load-anim">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        </>
      ) : (
        <>
          <PDFViewer width="100%" height="410px">
            <CertificatePreview
              cerImgSrc={cerImgSrc}
              stageWidth={stageWidth}
              stageHeight={stageHeight}
              addedElements={addedElements}
              currentUser={currentUser}
              courseName={courseName}
              formattedDate={formattedDate}
              allCerts={allCerts}
              courseShortName={courseShortName}
              catid={catid}
            />
          </PDFViewer>
          <PDFDownloadLink
            document={
              <CertificatePreview
                cerImgSrc={cerImgSrc}
                stageWidth={stageWidth}
                stageHeight={stageHeight}
                addedElements={addedElements}
                currentUser={currentUser}
                courseName={courseName}
                formattedDate={formattedDate}
                allCerts={allCerts}
                courseShortName={courseShortName}
                catid={catid}
              />
            }
            fileName={`${currentUser}-${courseName}.pdf`}
            className="cert-down"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading..." : <button>Download Certificate</button>
            }
          </PDFDownloadLink>
        </>
      )}
    </div>
  );
};

export default Preview;
