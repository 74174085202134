import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Select from "react-select";
import "./events.css";
import { Form, Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import EventService from "../services/event.service";
import AuthService from "../services/auth.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserService from "../services/user.service";

import AllEvents from "./AllEvents";

const Event = () => {
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [userId, setUserId] = useState("");
  const [roleid, setRoleid] = useState("");
  const [value, onChange] = useState(new Date());
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const intialState = {
    id: "",
    title: "",
    date: "",
    timeFrom: "",
    timeTo: "",
    guests: [],
    link: "",
    description: "",
    eventColor: "",
  };
  const [eventDetails, setEventDetails] = useState(intialState);
  const [errors, setErrors] = useState({});
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleEditClose = () => {
    setShowEdit(false);
    setEventDetails(intialState);
  };

  const [eid, setEid] = useState("");
  const [allEvents, setAllEvents] = useState([]);

  const getGuestList = () => {
    UserService.getUser()
      .then((response) => {
        const guests = response.data;

        if (roleid !== "4") {
          setOptions(
            Array.isArray(guests)
              ? guests.map((guest) => ({
                  value: guest.id,
                  label: guest.email,
                }))
              : null
          );
        } else {
          setOptions(
            Array.isArray(guests)
              ? guests.map((guest) => ({
                  value: guest.id,
                  label: guest.email,
                  isReadOnly: true,
                }))
              : null
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOptionChange = (event) => {
    setSelectedOptions(event);
    const values = event.map((option) => option.value).toString();
    const valSplit = values.split(",");
    setEventDetails({ ...eventDetails, guests: valSplit });
  };

  const userInputChange = (event) => {
    const { name, value } = event.target;
    setEventDetails({ ...eventDetails, [name]: value });
  };

  const getAllEvents = (uid) => {
    EventService.getEventById(uid)
      .then((response) => {
        const disEvent = Array.isArray(response.data)
          ? response.data.map((data) => ({
              title: data.title,
              start: `${data.date}T${data.timefrom}`,
              end: `${data.date}T${data.timeto}`,
              backgroundColor: data.color,
              id: data.id,
              allDay: false,
            }))
          : null;

        setAllEvents(disEvent);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const eventTimeFormat = {
    hour: "numeric",
    minute: "2-digit",
    meridiem: true,
  };

  const addEvents = () => {
    const newEvent = {
      usp: userId,
      title: eventDetails.title,
      date: eventDetails.date,
      timefrom: eventDetails.timeFrom,
      timeto: eventDetails.timeTo,
      guest: eventDetails.guests,
      link: eventDetails.link,
      description: eventDetails.description,
      color: eventDetails.eventColor,
    };

    EventService.addEvent(newEvent)
      .then((response) => {
        if (response.data !== false) {
          setErrors(validate(newEvent));
          toast.success("Event created successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          const newArr = {
            title: newEvent.title,
            start: newEvent.date,
            end: newEvent.date,
            backgroundColor: newEvent.color,
          };

          setAllEvents([...allEvents, newArr]);
          getAllEvents(userId);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validate = (data) => {
    const error = {};

    if (!data.title) {
      error.title = "Title is required!";
    }

    if (!data.date) {
      error.date = "Date is required!";
    }

    if (!data.guest) {
      error.guest = "Please add guests1";
    }
    return error;
  };

  const showEvent = (info) => {
    setShowEdit(true);
    setEid(info.event.id);
    const eventId = info.event.id;

    EventService.getEventByEId(userId, eventId)
      .then((response) => {
        setEventDetails({
          id: response.data[0].id,
          title: response.data[0].title,
          date: response.data[0].date,
          timeFrom: response.data[0].timefrom,
          timeTo: response.data[0].timeto,
          guests: response.data[0].guest.split(","),
          link: response.data[0].link,
          description: response.data[0].description,
          eventColor: response.data[0].color,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateEvents = () => {
    const newEvent = {
      usp: userId,
      title: eventDetails.title,
      date: eventDetails.date,
      timefrom: eventDetails.timeFrom,
      timeto: eventDetails.timeTo,
      guest: eventDetails.guests,
      link: eventDetails.link,
      description: eventDetails.description,
      color: eventDetails.eventColor,
      eid: eid,
    };

    EventService.addEvent(newEvent)
      .then((response) => {
        toast.success("Updated event successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setUserId(user.user);
      setRoleid(user.role_id);
      getGuestList();
      getAllEvents(user.user);
    }
  }, []);

  const deleteEvent = (e, envid) => {
    e.preventDefault();

    const data = {
      envid: envid,
    };
    EventService.delEvent(data)
      .then((resp) => {
        setShowEdit(false);
        if (resp.data === false) {
          toast.warn("The Event is not created by you!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          const user = AuthService.getCurrentUser();
          getAllEvents(user.user);
          toast.success("Event deleted successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="row">
        <div className="col-xxl-3 col-xl-3">
          <div className="outer-box event-calendar">
            <Calendar onChange={onChange} value={value} />
            <div className="event events">
              <AllEvents />
            </div>
          </div>
        </div>
        <div className="col-xxl-9 col-xl-9">
          <div className="outer-box fullCal">
            <div className="responsive-calendar">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "title",
                  center: "today,prev,next",
                  right: "timeGridDay,timeGridWeek,dayGridMonth",
                }}
                editable={true}
                selectable={true}
                dayMaxEvents={true}
                eventTimeFormat={eventTimeFormat}
                events={allEvents}
                eventClick={showEvent}
              />
            </div>

            <button className="addEvent" onClick={handleShow}>
              Create Event
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={showEdit}
        onHide={handleEditClose}
        centered
        className="event-modal"
      >
        <Modal.Header>
          <Modal.Title>
            {roleid !== "4" ? <>Edit Event</> : <>Show Event</>}
          </Modal.Title>
          <div className="flex">
            <button
              type="button"
              class="btn-closes"
              aria-label="Close"
              onClick={(e) => {
                deleteEvent(e, eventDetails.id);
              }}
              title="Delete Event"
            >
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  stroke="none"
                >
                  <path
                    d="M2226 4469 c-62 -15 -153 -68 -197 -116 -65 -71 -101 -158 -107 -263
l-5 -89 -388 -3 c-374 -3 -389 -4 -415 -24 -67 -49 -69 -58 -69 -254 0 -196 2
-205 69 -254 27 -21 29 -21 1446 -21 1417 0 1419 0 1446 21 67 49 69 58 69
254 0 196 -2 205 -69 254 -26 20 -41 21 -415 24 l-388 3 -5 89 c-10 161 -86
278 -226 348 l-76 37 -315 2 c-174 1 -333 -3 -355 -8z m629 -334 c21 -20 25
-34 25 -80 l0 -55 -320 0 -320 0 0 55 c0 46 4 60 25 80 l24 25 271 0 271 0 24
-25z"
                  />
                  <path
                    d="M1315 3266 c-16 -8 -43 -28 -59 -45 -24 -25 -30 -42 -34 -88 -3 -56
178 -1997 198 -2118 29 -173 183 -325 368 -364 74 -15 1471 -15 1544 0 181 39
327 176 362 342 21 99 209 2079 204 2145 -5 59 -38 104 -95 128 -47 20 -2443
20 -2488 0z m858 -419 c64 -49 61 -30 112 -827 56 -895 55 -856 25 -905 -51
-82 -168 -98 -238 -32 -52 49 -51 37 -103 861 -28 429 -47 795 -44 813 19 106
161 157 248 90z m963 -2 c72 -55 71 -12 20 -839 -25 -408 -49 -767 -52 -798
-13 -130 -126 -203 -231 -149 -63 32 -88 81 -86 167 4 125 93 1508 99 1537 7
36 50 86 89 103 46 22 117 12 161 -21z"
                  />
                </g>
              </svg>
            </button>
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              onClick={handleEditClose}
              title="close"
            >
              <svg
                id="incorrect"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24.472 24.445"
              >
                <path
                  id="Path_11894"
                  data-name="Path 11894"
                  d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                  transform="translate(-42.855 468.853)"
                  // fill="#fff"
                />
              </svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="input-box">
              <Form.Control
                type="text"
                placeholder="New event title"
                name="title"
                value={eventDetails.title}
                onChange={userInputChange}
                required
                readOnly={roleid === "4"}
              />
              <span className="icon"></span>
            </div>
          </Form.Group>

          <div className="row mb-2">
            <div className="col-xl-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="input-box">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="30"
                      viewBox="0 0 81.923 76.58"
                    >
                      <g id="calendar" transform="translate(-15 482)">
                        <path
                          id="Path_13206"
                          data-name="Path 13206"
                          d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                        />
                        <path
                          id="Path_13207"
                          data-name="Path 13207"
                          d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                          transform="translate(0 -108.781)"
                        />
                      </g>
                    </svg>
                  </span>
                  <Form.Control
                    type="date"
                    name="date"
                    value={eventDetails.date}
                    onChange={userInputChange}
                    required
                    readOnly={roleid === "4"}
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-xl-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="input-box">
                  <Form.Control
                    type="time"
                    name="timeFrom"
                    value={eventDetails.timeFrom}
                    onChange={userInputChange}
                    required
                    readOnly={roleid === "4"}
                  />
                  <span className="icon createEvents-icon"></span>
                </div>
              </Form.Group>
            </div>
            <div className="col-xl-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="input-box">
                  <Form.Control
                    type="time"
                    name="timeTo"
                    value={eventDetails.timeTo}
                    onChange={userInputChange}
                    required
                    readOnly={roleid === "4"}
                  />
                  <span className="icon createEvents-icon"></span>
                </div>
              </Form.Group>
            </div>
          </div>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="input-box">
              <div className="act-flex">
                <div className="permission-flex">
                  <div className="multiple-permission">
                    <div className="">
                      {roleid !== "4" ? (
                        <>
                          <Select
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: "100%",
                                border: "none",
                                outline: "none",
                                borderRadius: "10px",
                                backgroundColor: state.isFocused
                                  ? "var(--body-dark-bg)"
                                  : "var(--body-dark-bg)",
                              }),
                              option: (
                                baseStyles,
                                { isFocused, isSelected }
                              ) => ({
                                ...baseStyles,
                                backgroundColor: isSelected
                                  ? "var(--primary-color)"
                                  : isFocused
                                  ? "var(--secondary-color)"
                                  : "var(--dark-bg)",
                                color: isSelected
                                  ? "var(--body-dark-bg)"
                                  : isFocused
                                  ? "var(--body-dark-bg)"
                                  : "var(--whiteColor)",
                              }),
                            }}
                            onChange={handleOptionChange}
                            options={options}
                            isMulti={true}
                            issearchable="true"
                            placeholder="Add Guests"
                            style={{ paddingLeft: 40 }}
                            value={
                              Array.isArray(options)
                                ? options.filter((option) =>
                                    eventDetails.guests.includes(option.value)
                                  )
                                : []
                            }
                          />
                        </>
                      ) : (
                        <>
                          <div className="input-box">
                            <div className="form-control mx-hight">
                              {Array.isArray(options)
                                ? options.map((option) => {
                                    const isGuestIncluded =
                                      eventDetails.guests.includes(
                                        option.value
                                      );

                                    return (
                                      <div key={option.value}>
                                        {isGuestIncluded ? option.label : ""}
                                      </div>
                                    );
                                  })
                                : []}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="input-box">
              <Form.Control
                type="text"
                placeholder="Copy Link"
                name="link"
                value={eventDetails.link}
                onChange={userInputChange}
                required
                style={{ paddingLeft: 40 }}
                readOnly={roleid === "4"}
              />
              <span className="icon createEvents-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="30"
                  viewBox="0 0 28.166 28.179"
                >
                  <g id="link" transform="translate(0 0)">
                    <path
                      id="Path_13445"
                      data-name="Path 13445"
                      d="M195.381-511.714a6.87,6.87,0,0,0-3.235,1.312c-.22.165-1.764,1.67-3.434,3.34a26.8,26.8,0,0,0-2.866,2.993,9.675,9.675,0,0,1,1.075-.039,7.245,7.245,0,0,1,2.469.364l.524.165,2.155-2.149c2.017-2.006,2.183-2.161,2.568-2.348a3.236,3.236,0,0,1,3.808.623,3.251,3.251,0,0,1,.827,3.313c-.231.744-.32.838-3.748,4.26-3.092,3.086-3.246,3.23-3.649,3.428a3.457,3.457,0,0,1-.953.292,2.291,2.291,0,0,1-1.058,0,3.413,3.413,0,0,1-1.742-.832,1.656,1.656,0,0,0-1.367-.524c-.513.039-.8.215-1.488.9l-.568.568.27.408a6.588,6.588,0,0,0,4.371,2.75,8.553,8.553,0,0,0,2.282-.028,7.653,7.653,0,0,0,1.775-.6c.838-.419,1.08-.639,4.426-3.974,1.8-1.791,3.406-3.439,3.577-3.66a7.155,7.155,0,0,0,1.235-2.794,7.752,7.752,0,0,0,.077-1.185,5.666,5.666,0,0,0-.309-2.056,6.655,6.655,0,0,0-4.845-4.4A10.261,10.261,0,0,0,195.381-511.714Z"
                      transform="translate(-174.545 511.732)"
                    />
                    <path
                      id="Path_13446"
                      data-name="Path 13446"
                      d="M11.751-343.719a6.9,6.9,0,0,0-2.7,1.053c-.479.314-6.812,6.592-7.3,7.237a7.051,7.051,0,0,0-1.2,2.645,8.194,8.194,0,0,0,0,2.645,6.677,6.677,0,0,0,5.159,5.159,8.191,8.191,0,0,0,2.645,0,6.975,6.975,0,0,0,2.645-1.2c.226-.171,1.78-1.681,3.445-3.351,3.02-3.031,3.031-3.042,2.783-3a12.058,12.058,0,0,1-2.342-.055,10.685,10.685,0,0,1-1.064-.265l-.568-.176-2.16,2.155c-2.023,2.012-2.188,2.166-2.574,2.353a3.236,3.236,0,0,1-3.808-.623,3.251,3.251,0,0,1-.827-3.312c.231-.744.314-.838,3.775-4.282,3.136-3.125,3.274-3.257,3.649-3.423a3.382,3.382,0,0,1,3.709.513,1.726,1.726,0,0,0,1.163.562c.606.044.9-.1,1.637-.827a7.808,7.808,0,0,0,.623-.65,3.615,3.615,0,0,0-.287-.468A6.6,6.6,0,0,0,11.751-343.719Z"
                      transform="translate(-0.45 353.052)"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="input-box">
              <Form.Control
                type="text"
                placeholder="Add Descriptions"
                name="description"
                value={eventDetails.description}
                onChange={userInputChange}
                required
                readOnly={roleid === "4"}
              />
              <span className="icon createEvents-icon"></span>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="input-box">
              <Form.Control
                type="color"
                name="eventColor"
                value={eventDetails.eventColor}
                onChange={userInputChange}
                required
                style={{ width: 50, height: 40 }}
                readOnly={roleid === "4"}
              />
              <span className="icon createEvents-icon"></span>
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {roleid !== "4" ? (
            <>
              <button className="no" onClick={handleEditClose}>
                No
              </button>
              <button
                className="yes"
                onClick={() => {
                  handleEditClose();
                  updateEvents();
                }}
              >
                Yes
              </button>
            </>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose} centered className="event-modal">
        <Modal.Header>
          <Modal.Title>Create Event</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div className="input-box">
              <Form.Control
                type="text"
                placeholder="New event title"
                name="title"
                value={eventDetails.title}
                onChange={userInputChange}
                required
              />
              <span className="icon"></span>
            </div>
            {errors.title ? (
              <p style={{ color: "red" }}>{errors.title}</p>
            ) : null}
          </Form.Group>

          <div className="row mb-2">
            <div className="col-xl-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="input-box">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="30"
                      viewBox="0 0 81.923 76.58"
                    >
                      <g id="calendar" transform="translate(-15 482)">
                        <path
                          id="Path_13206"
                          data-name="Path 13206"
                          d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                        />
                        <path
                          id="Path_13207"
                          data-name="Path 13207"
                          d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                          transform="translate(0 -108.781)"
                        />
                      </g>
                    </svg>
                  </span>
                  <Form.Control
                    type="date"
                    name="date"
                    value={eventDetails.date}
                    onChange={userInputChange}
                    required
                  />
                </div>
                {errors.date ? <p>{errors.date}</p> : null}
              </Form.Group>
            </div>
            <div className="col-xl-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="input-box">
                  <Form.Control
                    type="time"
                    name="timeFrom"
                    value={eventDetails.timeFrom}
                    onChange={userInputChange}
                    required
                  />
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 27.874 27.891"
                    >
                      <g id="clock" transform="translate(0 0)">
                        <path
                          id="Path_13444"
                          data-name="Path 13444"
                          d="M13.413-511.774a13.841,13.841,0,0,0-5.341,1.515,14.275,14.275,0,0,0-5.259,4.714,16.2,16.2,0,0,0-1.6,3.3,16.069,16.069,0,0,0-.616,2.671,21.242,21.242,0,0,0,0,3.434,14.114,14.114,0,0,0,1.319,4.36,12.919,12.919,0,0,0,2.692,3.766,12.938,12.938,0,0,0,3.712,2.665,12.244,12.244,0,0,0,2.725,1.008,13.816,13.816,0,0,0,8.121-.387,16.9,16.9,0,0,0,2.943-1.477A14.85,14.85,0,0,0,26.3-490.5a16.991,16.991,0,0,0,1.281-2.649,13.831,13.831,0,0,0-.627-10.862,13.35,13.35,0,0,0-2.5-3.532,13.2,13.2,0,0,0-3.875-2.823,13.334,13.334,0,0,0-4.905-1.39A18.57,18.57,0,0,0,13.413-511.774Zm1.575,5.919a1.409,1.409,0,0,1,.409.36l.169.234.016,3.739.011,3.744,2.856,2.861c2.442,2.453,2.856,2.894,2.91,3.085a1.1,1.1,0,0,1-.076.888,1.129,1.129,0,0,1-1.3.6c-.262-.06-.365-.158-3.368-3.15-1.739-1.728-3.145-3.172-3.2-3.286-.1-.2-.1-.338-.093-4.344l.016-4.137.164-.234A1.183,1.183,0,0,1,14.988-505.855Z"
                          transform="translate(-0.525 511.801)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </Form.Group>
            </div>
            <div className="col-xl-6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="input-box">
                  <Form.Control
                    type="time"
                    name="timeTo"
                    value={eventDetails.timeTo}
                    onChange={userInputChange}
                    required
                  />
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 27.874 27.891"
                    >
                      <g id="clock" transform="translate(0 0)">
                        <path
                          id="Path_13444"
                          data-name="Path 13444"
                          d="M13.413-511.774a13.841,13.841,0,0,0-5.341,1.515,14.275,14.275,0,0,0-5.259,4.714,16.2,16.2,0,0,0-1.6,3.3,16.069,16.069,0,0,0-.616,2.671,21.242,21.242,0,0,0,0,3.434,14.114,14.114,0,0,0,1.319,4.36,12.919,12.919,0,0,0,2.692,3.766,12.938,12.938,0,0,0,3.712,2.665,12.244,12.244,0,0,0,2.725,1.008,13.816,13.816,0,0,0,8.121-.387,16.9,16.9,0,0,0,2.943-1.477A14.85,14.85,0,0,0,26.3-490.5a16.991,16.991,0,0,0,1.281-2.649,13.831,13.831,0,0,0-.627-10.862,13.35,13.35,0,0,0-2.5-3.532,13.2,13.2,0,0,0-3.875-2.823,13.334,13.334,0,0,0-4.905-1.39A18.57,18.57,0,0,0,13.413-511.774Zm1.575,5.919a1.409,1.409,0,0,1,.409.36l.169.234.016,3.739.011,3.744,2.856,2.861c2.442,2.453,2.856,2.894,2.91,3.085a1.1,1.1,0,0,1-.076.888,1.129,1.129,0,0,1-1.3.6c-.262-.06-.365-.158-3.368-3.15-1.739-1.728-3.145-3.172-3.2-3.286-.1-.2-.1-.338-.093-4.344l.016-4.137.164-.234A1.183,1.183,0,0,1,14.988-505.855Z"
                          transform="translate(-0.525 511.801)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </Form.Group>
            </div>
            {roleid !== "4" ? (
              <>
                <div className="col-xl-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <div className="input-box guests">
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "100%",
                            height: "max-content",
                            border: "none",
                            outline: "none",
                            borderRadius: "5px",
                            backgroundColor: state.isFocused
                              ? "var(--body-dark-bg)"
                              : "var(--body-dark-bg)",
                          }),
                          option: (baseStyles, { isFocused, isSelected }) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? "var(--primary-color)"
                              : isFocused
                              ? "var(--secondary-color)"
                              : "var(--body-dark-bg)",
                            color: isSelected
                              ? "var(--body-dark-bg)"
                              : isFocused
                              ? "var(--body-dark-bg)"
                              : "var(--whiteColor)",
                          }),
                        }}
                        onChange={handleOptionChange}
                        value={selectedOptions}
                        options={options}
                        isMulti={true}
                        issearchable="true"
                        placeholder="Add Guests"
                        style={{ paddingLeft: 40 }}
                      />
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="23"
                          viewBox="0 0 31.564 36.613"
                        >
                          <g
                            id="Group_11420"
                            data-name="Group 11420"
                            transform="translate(0 0)"
                          >
                            <path
                              id="Path_11739"
                              data-name="Path 11739"
                              d="M253.821-367.713A6.51,6.51,0,0,0,249.156-363c-.275,1.2-.243,1.344.5,1.883a8.009,8.009,0,0,1,2.793,4.337c.1.434.19.846.212.92.1.339,1.513.688,2.8.688a5.948,5.948,0,0,0,4.232-1.693,6.084,6.084,0,0,0,2.031-4.623,6.048,6.048,0,0,0-1.894-4.57,6.534,6.534,0,0,0-3.089-1.714A8.462,8.462,0,0,0,253.821-367.713Z"
                              transform="translate(-234.489 367.869)"
                              fill="#fff"
                            />
                            <path
                              id="Path_11741"
                              data-name="Path 11741"
                              d="M153.993-305.489a6.568,6.568,0,0,0-4.337,3.438,5.713,5.713,0,0,0-.656,2.8,5.713,5.713,0,0,0,.656,2.8A6.435,6.435,0,0,0,154.078-293a6.414,6.414,0,0,0,7.3-4.115,6.451,6.451,0,0,0-.55-5.448,6.937,6.937,0,0,0-3.576-2.782A7.4,7.4,0,0,0,153.993-305.489Z"
                              transform="translate(-144.569 312.193)"
                              fill="#fff"
                            />
                            <path
                              id="Path_11744"
                              data-name="Path 11744"
                              d="M263.585-242.027a7.971,7.971,0,0,1-1.851,3.978l-.434.5.815.328a9.925,9.925,0,0,1,5.184,4.94,9.88,9.88,0,0,1,.92,4.39l.063,1.724.857-.063a25.612,25.612,0,0,0,7.2-1.555l.846-.36.032-3.745a25.588,25.588,0,0,0-.137-4.559,8.176,8.176,0,0,0-4.919-5.945c-1.259-.529-1.894-.6-5.289-.6h-3.131Z"
                              transform="translate(-245.664 256.209)"
                              fill="#fff"
                            />
                            <path
                              id="Path_11745"
                              data-name="Path 11745"
                              d="M113.482-180.761a8.293,8.293,0,0,0-6.739,5.988c-.233.8-.243.973-.243,4.856v4.02l1.375.4c6.95,2.021,14.652,1.957,19.56-.159l.8-.339v-3.819c0-4.083-.053-4.6-.561-5.871a7.79,7.79,0,0,0-1.82-2.708,8,8,0,0,0-4.6-2.359C120.485-180.878,114.318-180.888,113.482-180.761Z"
                              transform="translate(-106.5 200.636)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                    {errors.guest ? <p>{errors.guest}</p> : null}
                  </Form.Group>
                </div>
                <div className="col-xl-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <div className="input-box">
                      <Form.Control
                        type="text"
                        placeholder="Copy Link"
                        name="link"
                        value={eventDetails.link}
                        onChange={userInputChange}
                        required
                        style={{ paddingLeft: 40 }}
                      />
                      <span className="icon createEvents-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="30"
                          viewBox="0 0 28.166 28.179"
                        >
                          <g id="link" transform="translate(0 0)">
                            <path
                              id="Path_13445"
                              data-name="Path 13445"
                              d="M195.381-511.714a6.87,6.87,0,0,0-3.235,1.312c-.22.165-1.764,1.67-3.434,3.34a26.8,26.8,0,0,0-2.866,2.993,9.675,9.675,0,0,1,1.075-.039,7.245,7.245,0,0,1,2.469.364l.524.165,2.155-2.149c2.017-2.006,2.183-2.161,2.568-2.348a3.236,3.236,0,0,1,3.808.623,3.251,3.251,0,0,1,.827,3.313c-.231.744-.32.838-3.748,4.26-3.092,3.086-3.246,3.23-3.649,3.428a3.457,3.457,0,0,1-.953.292,2.291,2.291,0,0,1-1.058,0,3.413,3.413,0,0,1-1.742-.832,1.656,1.656,0,0,0-1.367-.524c-.513.039-.8.215-1.488.9l-.568.568.27.408a6.588,6.588,0,0,0,4.371,2.75,8.553,8.553,0,0,0,2.282-.028,7.653,7.653,0,0,0,1.775-.6c.838-.419,1.08-.639,4.426-3.974,1.8-1.791,3.406-3.439,3.577-3.66a7.155,7.155,0,0,0,1.235-2.794,7.752,7.752,0,0,0,.077-1.185,5.666,5.666,0,0,0-.309-2.056,6.655,6.655,0,0,0-4.845-4.4A10.261,10.261,0,0,0,195.381-511.714Z"
                              transform="translate(-174.545 511.732)"
                            />
                            <path
                              id="Path_13446"
                              data-name="Path 13446"
                              d="M11.751-343.719a6.9,6.9,0,0,0-2.7,1.053c-.479.314-6.812,6.592-7.3,7.237a7.051,7.051,0,0,0-1.2,2.645,8.194,8.194,0,0,0,0,2.645,6.677,6.677,0,0,0,5.159,5.159,8.191,8.191,0,0,0,2.645,0,6.975,6.975,0,0,0,2.645-1.2c.226-.171,1.78-1.681,3.445-3.351,3.02-3.031,3.031-3.042,2.783-3a12.058,12.058,0,0,1-2.342-.055,10.685,10.685,0,0,1-1.064-.265l-.568-.176-2.16,2.155c-2.023,2.012-2.188,2.166-2.574,2.353a3.236,3.236,0,0,1-3.808-.623,3.251,3.251,0,0,1-.827-3.312c.231-.744.314-.838,3.775-4.282,3.136-3.125,3.274-3.257,3.649-3.423a3.382,3.382,0,0,1,3.709.513,1.726,1.726,0,0,0,1.163.562c.606.044.9-.1,1.637-.827a7.808,7.808,0,0,0,.623-.65,3.615,3.615,0,0,0-.287-.468A6.6,6.6,0,0,0,11.751-343.719Z"
                              transform="translate(-0.45 353.052)"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </Form.Group>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="col-xl-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="input-box">
                  <Form.Control
                    type="text"
                    placeholder="Add Descriptions"
                    name="description"
                    value={eventDetails.description}
                    onChange={userInputChange}
                    required
                  />
                  <span className="icon createEvents-icon"></span>
                </div>
              </Form.Group>
            </div>
            <div className="col-xl-12">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <div className="input-box">
                  <Form.Control
                    type="color"
                    name="eventColor"
                    value={eventDetails.eventColor}
                    onChange={userInputChange}
                    required
                    style={{ width: 50, height: 40 }}
                  />
                  <span className="icon createEvents-icon"></span>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button
            className="yes"
            onClick={() => {
              handleClose();
              addEvents();
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Event;
