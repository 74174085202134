import React, { useEffect, useState } from "react";
import "./CourseManagement.css";
import Filters from "./Filters";
import ActionFlex from "./ActionFlex";
import { Link } from "react-router-dom";
import { Modal, Button, Alert } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../../../assets/css/datatable.css";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import 'datatables.net-responsive';
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import PathwayService from "../../../services/pathway.service";
import UserService from "../../../services/user.service";
import AuthService from "../../../services/auth.service";

const ManagePathway = ({ permissions, roleid }) => {
  // declarations
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState([]);
  const [pathways, setPathways] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [did, setDid] = useState("");
  const [deleteSelected, setDeleteSelected] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const [activePathway, setActivePathway] = useState("");
  const [inactivePathway, setInactivePathway] = useState("");
  const [allPathway, setAllPathway] = useState("");

  const [disableBulkDelete, setDisableBulkDelete] = useState(true);

  const [selectedPathways, setSelectedPathways] = useState([]);

  const [uid, setUid] = useState("");

  const handleDeleteClose = () => setDeleteSelected(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDtFilter = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDtFilter = (e) => {
    setEndDate(e.target.value);
  };

  const handleDeleteShow = (e) => {
    e.preventDefault();
    setDeleteSelected(true);
  };

  const getPathwayData = (uid) => {
    PathwayService.getPathway(uid)
      .then((response) => {
        const pathwayData = response.data;
        setPathways(pathwayData);

        getPathwaysCount(pathwayData);
        loadDatatable();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getPathwaysCount = (learnersData) => {
    setAllPathway(Array.isArray(learnersData) ? learnersData.length : null);

    setActivePathway(
      Array.isArray(learnersData)
        ? learnersData.filter((learner) => learner.status === "1").length
        : null
    );
    setInactivePathway(
      Array.isArray(learnersData)
        ? learnersData.filter((learner) => learner.status === "0").length
        : null
    );
  };

  const loadDatatable = () => {
    if (!$.fn.DataTable.isDataTable("#pathway")) {
      function filterGlobal() {
        $("#pathway")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#pathway")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $.fn.dataTable.ext.search.pop();

      var firstInputChanged = false;
      var secondInputChanged = false;

      // Function to apply the custom filter
      function applyStatusFilter(status) {
        $.fn.dataTable.ext.search.pop();

        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          var rowData = data[1];

          if (rowData === status) {
            return true;
          }
          return false;
        });

        $("#pathway").DataTable().draw();
      }

      function compareDates() {
        $.fn.dataTable.ext.search.pop();

        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          var minDate = $("#minDate").val();
          var maxDate = $("#maxDate").val();

          let date = data[2];

          if (minDate === date || (minDate <= date && date <= maxDate)) {
            return true;
          }
          return false;
        });

        $("#pathway").DataTable().draw();

        // Clear the custom filter if the date input is empty
        if (!$(this).val()) {
          $.fn.dataTable.ext.search.pop();
        }
      }

      // Button click event handlers
      $("#activePathway").on("click", function () {
        applyStatusFilter("1");
      });

      $("#inactivePathway").on("click", function () {
        applyStatusFilter("0");
      });

      $("#allPathway").on("click", function () {
        // Remove the custom filter
        $.fn.dataTable.ext.search.pop();
        $("#pathway").DataTable().draw();
      });

      $("#minDate, #maxDate").on("change", function () {
        compareDates();
      });

      $("#clearFilter").on("click", function () {
        $.fn.dataTable.ext.search.pop();
        $("#pathway").DataTable().draw();
        $("#minDate").val("");
        $("#maxDate").val("");
      });

      $(document).ready(function () {
        setTimeout(function () {
          $("#pathway").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            select: true,
            bDestroy: true,
            // scrollCollapse: true,
            // scrollY: '450px',
            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
        }, 0);
      });
    }
  };

  const filterDates = () => {
    const user = AuthService.getCurrentUser();
    PathwayService.getPathway(user.user)
      .then((response) => {
        const newData = response.data;
        if (startDate && endDate) {
          let filterData = Array.isArray(newData)
            ? newData.filter(
                (data) =>
                  data.start_date >= startDate && data.end_date <= endDate
              )
            : null;
          setPathways(filterData);

          loadDatatable();
        } else {
          setPathways(newData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterEndDate = () => {
    const user = AuthService.getCurrentUser();
    PathwayService.getPathway(user.user)
      .then((response) => {
        const newData = response.data;
        if (endDate && !startDate) {
          let filterData = Array.isArray(newData)
            ? newData.filter((data) => data.end_date <= endDate)
            : null;
          setPathways(filterData);

          loadDatatable();
        } else {
          setPathways(newData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterStartData = () => {
    const user = AuthService.getCurrentUser();
    PathwayService.getPathway(user.user)
      .then((response) => {
        const newData = response.data;

        if (startDate && !endDate) {
          let filterData = Array.isArray(newData)
            ? newData.filter((data) => data.start_date >= startDate)
            : null;
          setPathways(filterData);

          loadDatatable();
        } else {
          setPathways(newData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getStaffData = (uid) => {
    UserService.getSystemManagers(uid)
      .then((response) => {
        setStaffs(response.data);
        localStorage.setItem("staffdata", JSON.stringify(response.data));
      })
      .catch((e) => {
        console.log(e);
      });

    const storedStaffData = localStorage.getItem("staffdata");

    if (storedStaffData) {
      const stafttApiData = JSON.parse(storedStaffData);
      setStaffs(stafttApiData);
    } else {
      // Fetch data from the API
      UserService.getSystemManagers(uid)
        .then((response) => {
          const newData = response.data;
          setStaffs(newData);
          localStorage.setItem("staffdata", JSON.stringify(response.data));
          setStaffs(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setUid(user.user);
      getPathwayData(user.user);
      getStaffData(user.user);
    }
  }, []);

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allLearners = pathways.map((l) => l.id);
      setChecked(allLearners);
      setSelectedPathways(allLearners);
      setDisableBulkDelete(false);
    } else {
      setChecked([]);
      setSelectedPathways([]);
      setDisableBulkDelete(true);
    }
  };

  const handleLearnerChange = (e, l) => {
    let selectedUserArr = [];
    if (e.target.checked) {
      selectedUserArr = [...checked, l.id];
      setChecked([...checked, l.id]);
      setSelectedPathways([...checked, l.id]);
    } else {
      selectedUserArr = checked.filter((item) => item !== l.id);
      setChecked(checked.filter((item) => item !== l.id));
      setSelectedPathways(checked.filter((item) => item !== l.id));
    }
    if (selectedUserArr.length > 0) {
      setDisableBulkDelete(false);
    } else {
      setDisableBulkDelete(true);
    }
  };

  function getStaffName(staffid, staffs) {
    const staff = staffs.find((r) => r.user_id === staffid);
    return staff ? staff.first_name + " " + staff.last_name : "Super Admin";
  }

  const deleteSelectedPathway = (e) => {
    e.preventDefault();
    const data = {
      usp: uid,
      delid: selectedPathways,
    };

    PathwayService.deleteSelectedPathway(data)
      .then((response) => {
        setDeleteSelected(false);
        toast.success("Selected pathway deleted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getPathwayData(uid);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deletePathway = (e) => {
    e.preventDefault();
    PathwayService.deletePathway(uid, did)
      .then((response) => {
        toast.success(response.data.massage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShow(false);
        setPathways(pathways.filter((pathway) => pathway.id !== did));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (startDate && !endDate) {
      filterStartData();
    } else if (endDate && !startDate) {
      filterEndDate();
    } else if (startDate && endDate) {
      filterDates();
    } else {
      if (user) {
        getPathwayData(user.user);
      }
    }
  }, [startDate, endDate]);

  const [showNotify, setShowNotify] = useState(false);

  const showNotification = () => {
    setShowNotify(true);
  };

  const hideNotification = () => {
    setShowNotify(false);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <Filters
              handleStartDtFilter={handleStartDtFilter}
              handleEndDtFilter={handleEndDtFilter}
            />
          </div>
          <div className="col-xl-9 col-lg-9">
            <div className="box-outer right">
              <div className="box-inner">
                <ActionFlex permissions={permissions} roleid={roleid} />

                <div className="act-flex">
                  <div className="search-bar">
                    <input
                      type="text"
                      className="global_filter"
                      id="global_filter"
                      placeholder="Start typing to search..."
                    />
                  </div>
                  <div className="permission-flex flex-end">
                    <div className="act-permission">
                      <button
                        onClick={handleDeleteShow}
                        disabled={disableBulkDelete}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                <div className="user-status-flex">
                  <div className="user-status all" id="allPathway">
                    <span>All</span>
                    <span>{allPathway}</span>
                  </div>
                  <div className="user-status active" id="activePathway">
                    <span>Active</span>
                    <span>{activePathway}</span>
                  </div>
                  <div className="user-status inactive" id="inactivePathway">
                    <span>Inactive</span>
                    <span>{inactivePathway}</span>
                  </div>
                </div>
                <ToastContainer />
                <div className="admin-users courses-list">
                  <div className="table-responsive">
                    <table className="table" id="pathway">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Status</th>
                          <th hidden>Date</th>
                          <th>Courses</th>
                          <th>Learners</th>
                          <th>Created By</th>
                          {(permissions && permissions.includes("mp5")) ||
                          (permissions && permissions.includes("mp3")) ||
                          (permissions && permissions.includes("mp4")) ||
                          roleid === "1" ||
                          roleid === "2" ? (
                            <>
                              <th
                                style={{ textAlign: "center", width: "50px" }}
                                className="action"
                              >
                                Action
                              </th>
                            </>
                          ) : (
                            <></>
                          )}
                          <th className="action">
                            <div className="checkbox">
                              <input
                                className="styled-checkbox"
                                id="styled-checkbox-1"
                                type="checkbox"
                                value="value1"
                                onChange={handleCheckAllChange}
                                checked={checked.length === pathways.length}
                              />
                              <label htmlFor="styled-checkbox-1"></label>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(pathways)
                          ? pathways.map((pathway) => (
                              <tr key={pathway.id}>
                                <td>
                                  <div className="user-flex">
                                    <div className="user-info">
                                      <span className="name">
                                        {pathway.name}
                                      </span>
                                      <span className="role">
                                        {pathway.email}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className={
                                      pathway.status === "1"
                                        ? "user-status active"
                                        : "user-status inactive"
                                    }
                                  >
                                    {pathway.status}
                                  </div>
                                </td>
                                <td hidden>
                                  <span>{pathway.created_on}</span>
                                </td>
                                <td>
                                  <span>
                                    {pathway.course_count === null
                                      ? 0
                                      : pathway.course_count}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {pathway.learner_count === null
                                      ? 0
                                      : pathway.learner_count}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    {getStaffName(pathway.created_by, staffs)}
                                  </span>
                                </td>
                                {(permissions && permissions.includes("mp5")) ||
                                (permissions && permissions.includes("mp3")) ||
                                (permissions && permissions.includes("mp4")) ||
                                roleid === "1" ||
                                roleid === "2" ? (
                                  <>
                                    <td>
                                      <div className="table-act">
                                        {(permissions &&
                                          permissions.includes("mp5")) ||
                                        roleid === "1" ||
                                        roleid === "2" ? (
                                          <>
                                            <button
                                              className="manage-learners"
                                              onClick={() => {
                                                if (
                                                  pathway.course_count === null
                                                ) {
                                                  showNotification();
                                                }
                                              }}
                                            >
                                              {pathway.course_count !== null ? (
                                                <>
                                                  <Link
                                                    to={"/PathwayParticipants"}
                                                    state={{
                                                      pid: pathway.id,
                                                      course_count:
                                                        pathway.course_count,
                                                      learner_count:
                                                        pathway.learner_count,
                                                    }}
                                                  >
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="15"
                                                        viewBox="0 0 164.427 134.024"
                                                      >
                                                        <g
                                                          id="user-_3_"
                                                          transform="translate(-56.783 418.477)"
                                                        >
                                                          <path
                                                            id="Path_14330"
                                                            data-name="Path 14330"
                                                            d="M150.651-418.239a28.85,28.85,0,0,0-23.3,19.961,32.523,32.523,0,0,0-.66,14.228,29.2,29.2,0,0,0,7.63,14.022c5.526,5.568,11.465,8.248,19.012,8.537a27.048,27.048,0,0,0,18.435-5.485,29.885,29.885,0,0,0,10.681-15.837,32.241,32.241,0,0,0-.206-14.847,26.661,26.661,0,0,0-7.465-12.414,26.885,26.885,0,0,0-18.435-8.372A35.585,35.585,0,0,0,150.651-418.239Z"
                                                            transform="translate(-40.783)"
                                                          />
                                                          <path
                                                            id="Path_14331"
                                                            data-name="Path 14331"
                                                            d="M293.127-299.34c-.371.454-.66,2.227-.784,5.031l-.206,4.289-2.474.907a35.358,35.358,0,0,0-10.022,5.774,29.492,29.492,0,0,1-2.392,1.9c-.124,0-1.691-.866-3.505-1.9-3.588-2.062-4.99-2.351-6.145-1.155-1.443,1.567-6.6,11.011-6.6,12.166,0,1.443.536,1.98,4.372,4.165,2.969,1.691,3.093,1.815,2.887,3.011a53.131,53.131,0,0,0-.247,13.28,8.471,8.471,0,0,1,.165,3.3c-.206.082-1.856.99-3.67,2.1-4.5,2.639-4.5,2.969-.577,9.9,1.65,2.887,3.258,5.568,3.588,5.939.99,1.2,2.639.866,6.1-1.113a23.048,23.048,0,0,1,3.712-1.856,3.284,3.284,0,0,1,1.4,1.031c2.062,2.186,8.248,5.732,11.877,6.846l1.732.495v4.289c0,6.062-.247,5.9,8.661,5.9s8.661.165,8.661-5.9v-4.207l2.8-.99a34.094,34.094,0,0,0,10.063-5.774,8.217,8.217,0,0,1,2.309-1.691,23.946,23.946,0,0,1,3.629,1.856c3.505,1.98,5.031,2.268,6.021,1.113a123.187,123.187,0,0,0,6.434-11.259c.66-1.65-.165-2.722-3.835-4.866l-3.3-1.938.206-2.062a88.761,88.761,0,0,0-.124-13.774l-.33-1.856,3.464-2.021c4-2.309,4.784-3.341,3.835-5.238-1.361-2.763-6.1-10.64-6.681-11.094-1.031-.907-2.433-.536-5.9,1.443-2.928,1.691-3.464,1.856-4.083,1.4-.371-.33-1.526-1.237-2.557-2.062a35.177,35.177,0,0,0-9.238-5.155l-2.516-.907-.206-4.289c-.124-2.8-.412-4.578-.784-5.031-.495-.577-1.567-.66-7.877-.66S293.622-299.918,293.127-299.34Zm13.857,29.529a14.485,14.485,0,0,1,6.351,6.516,15.492,15.492,0,0,1,.165,11.218,15.229,15.229,0,0,1-6.681,6.8c-1.98.99-2.8,1.155-5.815,1.155s-3.835-.165-5.815-1.155a15.229,15.229,0,0,1-6.681-6.8,15.492,15.492,0,0,1,.165-11.218,14.48,14.48,0,0,1,8.619-7.465,17.309,17.309,0,0,1,4.33-.206A10.891,10.891,0,0,1,306.984-269.812Z"
                                                            transform="translate(-119.996 -69.616)"
                                                          />
                                                          <path
                                                            id="Path_14332"
                                                            data-name="Path 14332"
                                                            d="M100.011-251.182a57.317,57.317,0,0,0-20.249,8.331,64.033,64.033,0,0,0-13.115,12.785,52.512,52.512,0,0,0-9.857,29.611c-.041,3.629.082,4.454.99,6.392a13.521,13.521,0,0,0,6.516,6.516l2.268,1.072h47.427c36.54,0,47.675-.124,48.582-.495.949-.454,1.113-.742,1.113-2.186,0-1.567-.082-1.691-2.145-2.722-1.2-.619-2.8-1.526-3.629-2.062l-1.526-1.031L154.16-193.9a10.722,10.722,0,0,1-9.815-.247,12.833,12.833,0,0,1-3.505-2.557,144.325,144.325,0,0,1-7.753-13.4,12.076,12.076,0,0,1,0-8.826c.866-1.98,3.712-5.073,5.073-5.485.7-.247.784-.7.784-4.7,0-4.372,0-4.454-1.072-5.031a13.691,13.691,0,0,1-5.155-6.434,12.7,12.7,0,0,1,.247-7.671c.742-1.773.949-1.608-3.3-2.639C125.539-251.842,104.712-252.048,100.011-251.182Z"
                                                            transform="translate(0 -97.978)"
                                                          />
                                                        </g>
                                                      </svg>
                                                    </span>
                                                  </Link>
                                                </>
                                              ) : (
                                                <>
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="20"
                                                      height="15"
                                                      viewBox="0 0 164.427 134.024"
                                                    >
                                                      <g
                                                        id="user-_3_"
                                                        transform="translate(-56.783 418.477)"
                                                      >
                                                        <path
                                                          id="Path_14330"
                                                          data-name="Path 14330"
                                                          d="M150.651-418.239a28.85,28.85,0,0,0-23.3,19.961,32.523,32.523,0,0,0-.66,14.228,29.2,29.2,0,0,0,7.63,14.022c5.526,5.568,11.465,8.248,19.012,8.537a27.048,27.048,0,0,0,18.435-5.485,29.885,29.885,0,0,0,10.681-15.837,32.241,32.241,0,0,0-.206-14.847,26.661,26.661,0,0,0-7.465-12.414,26.885,26.885,0,0,0-18.435-8.372A35.585,35.585,0,0,0,150.651-418.239Z"
                                                          transform="translate(-40.783)"
                                                        />
                                                        <path
                                                          id="Path_14331"
                                                          data-name="Path 14331"
                                                          d="M293.127-299.34c-.371.454-.66,2.227-.784,5.031l-.206,4.289-2.474.907a35.358,35.358,0,0,0-10.022,5.774,29.492,29.492,0,0,1-2.392,1.9c-.124,0-1.691-.866-3.505-1.9-3.588-2.062-4.99-2.351-6.145-1.155-1.443,1.567-6.6,11.011-6.6,12.166,0,1.443.536,1.98,4.372,4.165,2.969,1.691,3.093,1.815,2.887,3.011a53.131,53.131,0,0,0-.247,13.28,8.471,8.471,0,0,1,.165,3.3c-.206.082-1.856.99-3.67,2.1-4.5,2.639-4.5,2.969-.577,9.9,1.65,2.887,3.258,5.568,3.588,5.939.99,1.2,2.639.866,6.1-1.113a23.048,23.048,0,0,1,3.712-1.856,3.284,3.284,0,0,1,1.4,1.031c2.062,2.186,8.248,5.732,11.877,6.846l1.732.495v4.289c0,6.062-.247,5.9,8.661,5.9s8.661.165,8.661-5.9v-4.207l2.8-.99a34.094,34.094,0,0,0,10.063-5.774,8.217,8.217,0,0,1,2.309-1.691,23.946,23.946,0,0,1,3.629,1.856c3.505,1.98,5.031,2.268,6.021,1.113a123.187,123.187,0,0,0,6.434-11.259c.66-1.65-.165-2.722-3.835-4.866l-3.3-1.938.206-2.062a88.761,88.761,0,0,0-.124-13.774l-.33-1.856,3.464-2.021c4-2.309,4.784-3.341,3.835-5.238-1.361-2.763-6.1-10.64-6.681-11.094-1.031-.907-2.433-.536-5.9,1.443-2.928,1.691-3.464,1.856-4.083,1.4-.371-.33-1.526-1.237-2.557-2.062a35.177,35.177,0,0,0-9.238-5.155l-2.516-.907-.206-4.289c-.124-2.8-.412-4.578-.784-5.031-.495-.577-1.567-.66-7.877-.66S293.622-299.918,293.127-299.34Zm13.857,29.529a14.485,14.485,0,0,1,6.351,6.516,15.492,15.492,0,0,1,.165,11.218,15.229,15.229,0,0,1-6.681,6.8c-1.98.99-2.8,1.155-5.815,1.155s-3.835-.165-5.815-1.155a15.229,15.229,0,0,1-6.681-6.8,15.492,15.492,0,0,1,.165-11.218,14.48,14.48,0,0,1,8.619-7.465,17.309,17.309,0,0,1,4.33-.206A10.891,10.891,0,0,1,306.984-269.812Z"
                                                          transform="translate(-119.996 -69.616)"
                                                        />
                                                        <path
                                                          id="Path_14332"
                                                          data-name="Path 14332"
                                                          d="M100.011-251.182a57.317,57.317,0,0,0-20.249,8.331,64.033,64.033,0,0,0-13.115,12.785,52.512,52.512,0,0,0-9.857,29.611c-.041,3.629.082,4.454.99,6.392a13.521,13.521,0,0,0,6.516,6.516l2.268,1.072h47.427c36.54,0,47.675-.124,48.582-.495.949-.454,1.113-.742,1.113-2.186,0-1.567-.082-1.691-2.145-2.722-1.2-.619-2.8-1.526-3.629-2.062l-1.526-1.031L154.16-193.9a10.722,10.722,0,0,1-9.815-.247,12.833,12.833,0,0,1-3.505-2.557,144.325,144.325,0,0,1-7.753-13.4,12.076,12.076,0,0,1,0-8.826c.866-1.98,3.712-5.073,5.073-5.485.7-.247.784-.7.784-4.7,0-4.372,0-4.454-1.072-5.031a13.691,13.691,0,0,1-5.155-6.434,12.7,12.7,0,0,1,.247-7.671c.742-1.773.949-1.608-3.3-2.639C125.539-251.842,104.712-252.048,100.011-251.182Z"
                                                          transform="translate(0 -97.978)"
                                                        />
                                                      </g>
                                                    </svg>
                                                  </span>
                                                </>
                                              )}
                                            </button>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        {(permissions &&
                                          permissions.includes("mp3")) ||
                                        roleid === "1" ||
                                        roleid === "2" ? (
                                          <>
                                            <button className="edit">
                                              <Link
                                                to={"/EditPathway"}
                                                state={{ id: pathway.id }}
                                              >
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="15"
                                                    height="15"
                                                    viewBox="0 0 22.58 22.583"
                                                  >
                                                    <g
                                                      id="Group_11449"
                                                      data-name="Group 11449"
                                                      transform="translate(-2402.326 -6200.833)"
                                                    >
                                                      <path
                                                        id="Path_11890"
                                                        data-name="Path 11890"
                                                        d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                        transform="translate(752.656 5510.995)"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                      />
                                                      <path
                                                        id="Path_11892"
                                                        data-name="Path 11892"
                                                        d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                        transform="translate(751.921 5511.583)"
                                                      />
                                                    </g>
                                                  </svg>
                                                </span>
                                              </Link>
                                            </button>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        {(permissions &&
                                          permissions.includes("mp4")) ||
                                        roleid === "1" ||
                                        roleid === "2" ? (
                                          <>
                                            <button
                                              onClick={handleShow}
                                              data-bs-target={pathway.id}
                                              className="delete"
                                            >
                                              <span>
                                                <svg
                                                  id="delete"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="12"
                                                  height="15"
                                                  viewBox="0 0 16.079 20.664"
                                                >
                                                  <path
                                                    id="Path_13633"
                                                    data-name="Path 13633"
                                                    d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                                    transform="translate(-57 512)"
                                                  />
                                                  <path
                                                    id="Path_13634"
                                                    data-name="Path 13634"
                                                    d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                                    transform="translate(-83.869 402.606)"
                                                  />
                                                </svg>
                                              </span>
                                            </button>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <td>
                                  <div className="checkbox">
                                    <input
                                      className="styled-checkbox"
                                      id="one"
                                      type="checkbox"
                                      value="value1"
                                      checked={checked.includes(pathway.id)}
                                      onChange={(e) =>
                                        handleLearnerChange(e, pathway)
                                      }
                                    />
                                    <label htmlFor="one"></label>
                                  </div>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Pathway</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this pathway?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deletePathway}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteSelected} onHide={handleDeleteClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Selected Pathways</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleDeleteClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete selected Cohorts?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={deleteSelectedPathway}>
            Yes
          </button>
          <button className="yes" onClick={handleDeleteClose}>
            No
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showNotify} onHide={hideNotification} centered>
        <Modal.Header>
          <Modal.Title>Pathway </Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={hideNotification}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>
            There is no courses added in this pathway. Please add atleast one
            course.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManagePathway;
