import http from "../http-common";

const token = JSON.parse(localStorage.getItem("token"));

const getCourse = (baps) => {
  return http.get("/api/course?baps=" + baps, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getPubCourses = (baps) => {
  return http.get("/api/stdpubcs" + baps, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getAllLearnerscourses = (baps) => {
  return http.get("/api/stdcors?baps=" + baps, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getLearnersCoursesWithoutPath = (baps) => {
  return http.get("/api/stdphcors?baps=" + baps, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getLearnersPathwayCourses = (baps) => {
  return http.get("/api/stdphcours?baps=" + baps, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getCourseById = (baps, cid) => {
  return http.get("/api/cidcurs?baps=" + baps + "&cid=" + cid, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getEstoreCourses = (data) => {
  return http.post("/api/corsesto", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const addCourseDetails = (data) => {
  return http.post("/api/crtcourse", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

const getPrerequisites = (corsid, baps) => {
  return http.get(`/api/precourse?corsid=${corsid}&baps=${baps}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const addPrerequisites = (data) => {
  return http.post("/api/cpathcourse", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const uploadScrom = (data) => {
  return http.post("/api/coursecact", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

const uploadMedia = (data) => {
  return http.post("/api/courseflo", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

const getCourseActivity = (corsid) => {
  return http.get("/api/coursecactfetc?act=" + corsid, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getCourseActivityById = (data) => {
  return http.post("/api/ftcorsactifo", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const addCourseCerEle = (data) => {
  return http.post("/api/cerelem", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

const getCourseCerEle = (corsid) => {
  return http.get("/api/cerelemftch?corsid=" + corsid, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getCourseCerEleById = (elima) => {
  return http.get("/api/cerelemaftch?elima=" + elima, {
    headers: { Authorization: "Bearer " + token },
  });
};

const applyCerEle = (usp, data) => {
  return http.post(`/api/corselemaappl?usp=${usp}`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const removeElement = (baps, elima) => {
  return http.get(`/api/corselemaexpappl?elima=${elima}&baps=${baps}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const updateCourse = (data) => {
  return http.put("/api/addcoursecategorydata", data, {
    headers: { Authorization: "Bearer " + token },
  });
};

const deleteCourse = (data) => {
  return http.post("/api/corsdl", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const getLearnersCourses = (bccid) => {
  return http.get("/api/stfcsdata?bccid=" + bccid, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getLearnersCoursesId = (baps) => {
  return http.get("/api/gstccrs?baps=" + baps, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getStudentsMaster = (baps) => {
  return http.get("/api/stfcsdataall?baps=" + baps, {
    headers: { Authorization: "Bearer " + token },
  });
};

const publishCourse = (data) => {
  return http.post("/api/corsupstschg", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const uploadScromResult = (data) => {
  return http.post("/api/pvrpro", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const fetchActivityResult = (data) => {
  return http.post("/api/fchtresdata", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const fetchActivityResultCondition = (data) => {
  return http.post("/api/ftstdrescon", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const addStudentsInCourse = (data) => {
  return http.post("/api/stdasgcse", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const addCourseInStudents = (data) => {
  return http.post("/api/incorsstd", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};
const removeCourseInStudents = (data) => {
  return http.post("/api/rmvcorsstd", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const getStudentsInCourses = () => {
  return http.get("/api/stdMaster", {
    headers: { Authorization: "Bearer " + token },
  });
};

const getCourseConditions = (baps, cid, aid) => {
  return http.get(
    `/api/getcoursecon?baps=${baps}&course_id=${cid}&activityId=${aid}`,
    { headers: { Authorization: "Bearer " + token } }
  );
};

const addCourseConditions = (data) => {
  return http.post(`/api/addcoursecon`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const updateCourseActPos = (data) => {
  return http.post(`/api/upactposition`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const uploadCourseCerTemp = (data) => {
  return http.post(`/api/upcorstemp`, data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const addCertConds = (data) => {
  return http.post("/api/strcorscnd", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const getCertConds = (data) => {
  return http.post("/api/fhcorscnd", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const getAllActRes = (data) => {
  return http.post("/api/fhstdcorsact", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const getLearnersCoursesByCsid = (bccid, csid) => {
  return http.get(`/api/stfcsdatabycs?bccid=${bccid}&csid=${csid}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const givFeedback = (data) => {
  return http.post("/api/feedps", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const getAllFeedbacks = (data) => {
  return http.post("/api/feedget", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const suspendCourse = (data) => {
  return http.post("/api/suscourse", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const resetAttempt = (data) => {
  return http.post("/api/daCtamt", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const launchCourse = (data) => {
  return http.post("/api/launchscrom", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const CourseService = {
  getCourse,
  getPubCourses,
  getAllLearnerscourses,
  getLearnersCoursesWithoutPath,
  getLearnersPathwayCourses,
  getCourseById,
  getEstoreCourses,
  addCourseDetails,
  getPrerequisites,
  addPrerequisites,
  uploadScrom,
  uploadMedia,
  getCourseActivity,
  getCourseActivityById,
  addCourseCerEle,
  getCourseCerEle,
  getCourseCerEleById,
  applyCerEle,
  removeElement,
  updateCourse,
  deleteCourse,
  getLearnersCourses,
  getLearnersCoursesId,
  publishCourse,
  uploadScromResult,
  fetchActivityResult,
  fetchActivityResultCondition,
  addStudentsInCourse,
  getStudentsMaster,
  addCourseInStudents,
  removeCourseInStudents,
  getStudentsInCourses,
  getCourseConditions,
  addCourseConditions,
  updateCourseActPos,
  uploadCourseCerTemp,
  addCertConds,
  getCertConds,
  getAllActRes,
  getLearnersCoursesByCsid,
  givFeedback,
  getAllFeedbacks,
  suspendCourse,
  resetAttempt,
  launchCourse,
};

export default CourseService;
