import http from "../http-common";
import authHeader from "./auth-header";

const token = JSON.parse(localStorage.getItem("token"));

// const API_URL = '/api/read-users';

const getUser = () => {
  return http.get("/api/read-users", {
    headers: { Authorization: "Bearer " + token },
  });
};

const getLearners = (baps) => {
  return http.get(`api/stddata?baps=${baps}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getLearnersResetPsw = () => {
  return http.get(`api/fetchlearn`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getInactiveLearners = (baps) => {
  return http.get(`api/stddatainact`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getProfileById = (uid) => {
  return http.get(`api/userdatabyrole?uid=${uid}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getParticipants = () => {
  return http.get("../api/stddata", {
    headers: { Authorization: "Bearer " + token },
  });
};

const getLearnerById = (baps, sid) => {
  return http.get(`api/stddatabid?baps=${baps}&sid=${sid}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const addLearners = (data) => {
  return http.post("api/addstd", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const bulkUploadLearners = (data) => {
  return http.post("api/addstdbulk", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateLearners = (data) => {
  return http.put("api/upstd", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const updateProfile = (data) => {
  return http.put("api/upuprofile", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const deleteLearners = (baps, uid) => {
  return http.delete(`api/delstd?baps=${baps}&uid=${uid}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const deleteMultipleLearners = (data) => {
  return http.post("api/muldelstd", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

const getSystemManagers = (baps) => {
  return http.get(`api/stfdata?baps=${baps}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getSystemManagerById = (baps, usid) => {
  return http.get(`api/stfdatabid?baps=${baps}&usid=${usid}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const addSystemManagers = (data) => {
  return http.post("api/stfins", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const updateSystemManagers = (data) => {
  return http.put("api/upstf", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const deleteSystemManagers = (baps, stfid) => {
  return http.delete(`api/delstf?baps=${baps}&stfid=${stfid}`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const suspendUsers = (data) => {
  return http.post("api/susp", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const deleteMultipleSystemManagers = (data) => {
  return http.post("api/muldstf", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const logoutUsers = () => {
  return http.get(`/api/logout`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const getCourseLearners = () => {
  return http.get(`/api/getcoursestd`, {
    headers: { Authorization: "Bearer " + token },
  });
};

const changePassword = (data) => {
  return http.post("api/stdrepas", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const resetPassword = (data) => {
  return http.post("api/stdrchpas", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const resetAssessmentAtmpAdmin = (data) => {
  return http.post("api/csactatmt", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const UserService = {
  getLearners,
  getLearnersResetPsw,
  getInactiveLearners,
  bulkUploadLearners,
  getParticipants,
  getLearnerById,
  addLearners,
  updateLearners,
  deleteLearners,
  deleteMultipleLearners,
  getSystemManagers,
  getSystemManagerById,
  addSystemManagers,
  updateSystemManagers,
  deleteSystemManagers,
  deleteMultipleSystemManagers,
  suspendUsers,
  getProfileById,
  updateProfile,
  getUser,
  logoutUsers,
  getCourseLearners,
  changePassword,
  resetPassword,
  resetAssessmentAtmpAdmin,
};

export default UserService;
