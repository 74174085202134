import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AuthService from "../../../services/auth.service";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
const LearnerOnboarding = () => {
  const [step, setStep] = useState(1);
  const [currentUser, setCurrentUser] = useState({});

  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setCurrentUser(user);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="heading flex">
              <div>
                <h1>Hi, {currentUser.name}</h1>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12">
            {step === 1 ? (
              <>
                <StepOne nextStep={nextStep} />
              </>
            ) : step === 2 ? (
              <>
                <StepTwo prevStep={prevStep} nextStep={nextStep} />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default LearnerOnboarding;
