import http from "../http-common";

const token = JSON.parse(localStorage.getItem("token"));

const sendOTP = (eml) => {
  return http.post("/api/restmemo", eml, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const verifyOTP = (data) => {
  return http.post("/api/verfota", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const verifyOTPPass = (data) => {
  return http.post("/api/verfotpass", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const changePassword = (data) => {
  return http.post("/api/restpas", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const deleteOTP = (data) => {
  return http.post("/api/demal", data, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const getEmail = (eml) => {
  return http.post("/api/getEmlo", eml, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const VerigyOTPService = {
  sendOTP,
  verifyOTP,
  changePassword,
  deleteOTP,
  getEmail,
  verifyOTPPass,
};

export default VerigyOTPService;
