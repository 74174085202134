import React from "react";
import { Link, useLocation } from "react-router-dom";

const ActionFlex = ({ permissions, roleid }) => {
  const location = useLocation();
  return (
    <>
      <div className="action-flex">
        {(permissions && permissions.includes("mc1")) ||
        roleid === "1" ||
        roleid === "2" ? (
          <>
            <Link
              to="/ManageCourses"
              className={
                location.pathname.includes("/ManageCourses")
                  ? "action-item active"
                  : "action-item"
              }
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="24"
                  viewBox="0 0 28.05 26.757"
                >
                  <g id="online-course" transform="translate(-11.176 488.877)">
                    <path
                      id="Path_12961"
                      data-name="Path 12961"
                      d="M12.9-488.826a2.073,2.073,0,0,0-1.571,1.25l-.132.327-.017,7.934c-.006,5.222.006,8.043.046,8.249a1.978,1.978,0,0,0,1.043,1.45l.361.195,4.781.017,4.781.011-.04.573a5.12,5.12,0,0,1-1.043,3.2c-.487.527-.694.6-1.943.636-.906.029-1.061.046-1.261.155a1.471,1.471,0,0,0-.774,1.5c0,.654.115.917.487,1.095.235.115.327.115,7.7.1,7.429-.017,7.458-.017,7.613-.138.321-.241.4-.4.418-.923a1.559,1.559,0,0,0-.722-1.605c-.269-.155-.31-.161-1.307-.189-.957-.034-1.055-.046-1.359-.183a2.27,2.27,0,0,1-1.072-1.158,7.106,7.106,0,0,1-.579-2.74v-.332l4.747-.011,4.741-.017.327-.155a2.053,2.053,0,0,0,.957-.963l.149-.315v-16.567l-.195-.361a1.974,1.974,0,0,0-1.508-1.049C37.088-488.9,13.333-488.89,12.9-488.826Zm24.4,8.925.011,7.011H13.115v-6.982c0-3.841.017-7.005.04-7.022s5.457-.034,12.078-.029l12.044.017Zm-9.562,10.846a10.041,10.041,0,0,0,.315,2.15,4.557,4.557,0,0,0,.8,1.6l.264.327H21.3l.292-.321a5.887,5.887,0,0,0,1.129-3.52l.04-.573h4.97Z"
                      transform="translate(0 0)"
                    />
                    <path
                      id="Path_12962"
                      data-name="Path 12962"
                      d="M117.2-425.867c-.109.023-1.943.705-4.07,1.513a33.533,33.533,0,0,0-4,1.617.55.55,0,0,0-.132.418c0,.367.149.522.705.728l.441.166v2.809l-.166.1c-.344.189-.378.35-.378,1.657,0,1.29.029,1.422.327,1.576a1.586,1.586,0,0,0,.568.08c.384,0,.418-.011.6-.195l.195-.195v-1.215c0-1.364-.034-1.508-.378-1.725l-.195-.12v-1.255c0-.969.017-1.255.075-1.255a5.176,5.176,0,0,1,.527.189l.459.183.029,1.863a6.621,6.621,0,0,0,.126,2.047,5.2,5.2,0,0,0,2.568,1.25,11.858,11.858,0,0,0,3.29.3,9.512,9.512,0,0,0,4.133-.837c.636-.3.98-.568,1.095-.848A9.085,9.085,0,0,0,123.1-419v-1.794l1.221-.459c1.37-.516,1.588-.654,1.588-1.026a.656.656,0,0,0-.355-.625c-.1-.046-1.863-.722-3.9-1.5a37.057,37.057,0,0,0-4.225-1.519C117.41-425.913,117.306-425.89,117.2-425.867Zm4.317,2.029c2.356.894,3.795,1.473,3.795,1.519,0,.1-7.6,2.987-7.854,2.987-.149,0-3.268-1.141-5.389-1.972l-.338-.138.281-.034c.161-.017,1.45-.149,2.866-.292s2.637-.287,2.711-.315a.213.213,0,0,0,.126-.224c0-.338.12-.338-3.7.046l-3.525.361-.441-.172c-.258-.1-.447-.2-.447-.246s1.33-.585,3.806-1.525c2.092-.8,3.858-1.45,3.927-1.462a.75.75,0,0,1,.252.006C117.656-425.288,119.427-424.629,121.514-423.838Zm-10.92,5.8c.12.086.126.115.126,1.175v1.089l-.269-.017-.275-.017v-1.078c0-1,.006-1.089.109-1.164C110.427-418.151,110.439-418.146,110.594-418.037Z"
                      transform="translate(-92.216 -59.348)"
                    />
                  </g>
                </svg>
              </span>
              <span>Courses</span>
            </Link>
          </>
        ) : (
          <></>
        )}

        {(permissions && permissions.includes("mp1")) ||
        roleid === "1" ||
        roleid === "2" ? (
          <>
            <Link
              to="/ManagePathway"
              className={
                location.pathname.includes("/ManagePathway")
                  ? "action-item active"
                  : "action-item"
              }
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 33.717 33.709"
                >
                  <g
                    id="Group_11666"
                    data-name="Group 11666"
                    transform="translate(-8090 -24258)"
                  >
                    <path
                      id="Path_14247"
                      data-name="Path 14247"
                      d="M52.011-431.894a1.346,1.346,0,0,0-.681.721c-.065.162-.089-.122-.089,7.8v11.458h-1.3v-18.167l-.567.032a1.265,1.265,0,0,0-1.167.583c-.17.219-.17.276-.194,9.537L48-410.615H62.885l.049-1a8.24,8.24,0,0,1,1.167-3.946,8.4,8.4,0,0,1,6.75-4.108,8.463,8.463,0,0,1,7.268,3.411l.348.478-.016-9.643-.024-5.753-.235-.3c-.438-.575.551-.535-13.354-.535C54.531-432,52.214-431.983,52.011-431.894Zm1.823,1.831v.648h-1.3v-1.3h1.3Zm2.593,0v.648h-1.3v-1.3h1.3Zm2.593,0v.648h-1.3v-1.3h.648Zm18.151,1.3v.648H73.282v-1.3h3.889Zm-20.1,2.593v.648H52.538v-1.3h4.538Zm20.1,0v.648H73.282v-1.3h3.889Zm-22.089,2.69c.365.105,1.183.851,1.183,1.07,0,.113.219.13,1.783.13s1.783-.016,1.783-.13c0-.219.818-.964,1.183-1.07a1.8,1.8,0,0,1,1.961.527,1.822,1.822,0,0,1,.421.543c0,.113.186.13,1.459.13s1.459-.016,1.459-.13c0-.219.818-.964,1.183-1.07a1.8,1.8,0,0,1,1.961.527,1.822,1.822,0,0,1,.421.543c0,.113.219.13,1.783.13s1.783-.016,1.783-.13c0-.219.818-.964,1.183-1.07a1.775,1.775,0,0,1,1.937.51,1.752,1.752,0,0,1,0,2.674,1.758,1.758,0,0,1-2.69-.016,1.759,1.759,0,0,1-.429-.543c0-.113-.219-.13-1.783-.13s-1.783.016-1.783.13c0,.219-.818.964-1.183,1.07a1.787,1.787,0,0,1-1.953-.527,1.759,1.759,0,0,1-.429-.543c0-.113-.186-.13-1.459-.13s-1.459.016-1.459.13c0,.219-.818.964-1.183,1.07a1.787,1.787,0,0,1-1.953-.527,1.759,1.759,0,0,1-.429-.543c0-.113-.219-.13-1.783-.13s-1.783.016-1.783.13c0,.219-.818.964-1.183,1.07a1.775,1.775,0,0,1-1.937-.51,1.752,1.752,0,0,1,0-2.674A1.789,1.789,0,0,1,55.082-423.483Zm.7,5.737v.648H53.186v-1.3h2.593Zm7.131,0v.648H60.317v-1.3H62.91Zm-.648,3.241v.648H53.186v-1.3h9.075Z"
                      transform="translate(8042 24690.008)"
                    />
                    <path
                      id="Path_14249"
                      data-name="Path 14249"
                      d="M120.337-311.8a.64.64,0,0,0,.446,1.094.684.684,0,0,0,.648-.648.684.684,0,0,0-.648-.648A.632.632,0,0,0,120.337-311.8Z"
                      transform="translate(7975.699 24579.725)"
                    />
                    <path
                      id="Path_14250"
                      data-name="Path 14250"
                      d="M208.337-311.8a.64.64,0,0,0,.446,1.094.684.684,0,0,0,.648-.648.684.684,0,0,0-.648-.648A.632.632,0,0,0,208.337-311.8Z"
                      transform="translate(7894.83 24579.725)"
                    />
                    <path
                      id="Path_14251"
                      data-name="Path 14251"
                      d="M288.2-311.8a.632.632,0,0,0-.2.446.632.632,0,0,0,.2.446.632.632,0,0,0,.446.2.632.632,0,0,0,.446-.2.632.632,0,0,0,.2-.446.632.632,0,0,0-.2-.446.632.632,0,0,0-.446-.2A.632.632,0,0,0,288.2-311.8Z"
                      transform="translate(7821.448 24579.725)"
                    />
                    <path
                      id="Path_14252"
                      data-name="Path 14252"
                      d="M376.2-311.8a.632.632,0,0,0-.2.446.632.632,0,0,0,.2.446.632.632,0,0,0,.446.2.632.632,0,0,0,.446-.2.632.632,0,0,0,.2-.446.632.632,0,0,0-.2-.446.632.632,0,0,0-.446-.2A.632.632,0,0,0,376.2-311.8Z"
                      transform="translate(7740.578 24579.725)"
                    />
                    <path
                      id="Path_14253"
                      data-name="Path 14253"
                      d="M440-398.276v9.724h1.945l-.016-9.3-.024-9.31-.235-.3a1.35,1.35,0,0,0-1.224-.535H440Z"
                      transform="translate(7681.764 24667.945)"
                    />
                    <path
                      id="Path_14256"
                      data-name="Path 14256"
                      d="M48-150.906c0,1.264.073,1.523.535,1.872l.3.235,7.933.024,7.925.016-.276-.381a10.254,10.254,0,0,1-1.11-2.277L63.12-152H48Z"
                      transform="translate(8042 24432.689)"
                    />
                    <path
                      id="Path_14257"
                      data-name="Path 14257"
                      d="M425.91-151.417a10.635,10.635,0,0,1-.5,1.207l-.308.616.438.429.429.421.729-.024c.786-.032,1-.13,1.345-.592.154-.194.17-.357.195-1.434L428.26-152H426.1Z"
                      transform="translate(7695.457 24432.689)"
                    />
                    <path
                      id="Path_14259"
                      data-name="Path 14259"
                      d="M172.335-95.9c-.016.057-.373,1.224-.778,2.6l-.745,2.488H168v1.3h14.261v-1.3h-2.82l-.122-.381c-.057-.219-.324-1.1-.583-1.977l-.478-1.588-.567-.284a8.124,8.124,0,0,1-1.021-.624L176.233-96H174.3A6.273,6.273,0,0,0,172.335-95.9Z"
                      transform="translate(7931.724 24381.227)"
                    />
                    <path
                      id="Path_14261"
                      data-name="Path 14261"
                      d="M174.138-270.7a1.157,1.157,0,0,0-.269.189c-.145.131-.167.218-.167.749v.6l-.378.124a5.22,5.22,0,0,0-.713.291l-.327.167-.451-.429c-.291-.283-.509-.429-.626-.429-.284,0-.96.56-1.31,1.09-.211.313-.153.487.306.967l.429.451-.131.254a4.626,4.626,0,0,0-.291.7l-.16.458h-.6c-.553,0-.611.015-.778.189s-.175.24-.175.988c0,.785,0,.8.2.974.182.153.276.174.778.174h.567l.16.451a4.449,4.449,0,0,0,.291.712l.131.254-.429.451c-.284.291-.429.509-.429.625A2.631,2.631,0,0,0,171-259.338c.291.109.451.036.88-.4l.393-.4.335.167a5.218,5.218,0,0,0,.713.291l.378.124.022.625c.022.589.036.632.226.778s.306.153.975.153c.742,0,.764-.007.938-.2.153-.182.175-.276.175-.778v-.567l.458-.16a4.622,4.622,0,0,0,.706-.291l.255-.131.451.429c.291.283.509.429.626.429a2.632,2.632,0,0,0,1.368-1.221c.109-.291.036-.451-.4-.879l-.4-.392.167-.334a5.3,5.3,0,0,0,.291-.719l.131-.378h.575c.517,0,.6-.022.757-.174.218-.218.284-.56.24-1.25-.029-.436-.073-.574-.2-.719-.153-.167-.226-.182-.771-.182h-.6l-.16-.458a4.629,4.629,0,0,0-.291-.7l-.131-.254.429-.451c.284-.291.429-.509.429-.625,0-.283-.56-.959-1.091-1.308-.313-.211-.487-.153-.968.305l-.451.429-.327-.167a5.219,5.219,0,0,0-.713-.291l-.378-.124v-.6c0-.545-.015-.618-.182-.77s-.262-.182-.895-.2A4.221,4.221,0,0,0,174.138-270.7Zm1.761,3.961a2.726,2.726,0,0,1,1.317,1.286,1.889,1.889,0,0,1,.233,1.1,1.889,1.889,0,0,1-.233,1.1,2.757,2.757,0,0,1-1.317,1.286,1.975,1.975,0,0,1-1.033.2,1.975,1.975,0,0,1-1.033-.2,2.757,2.757,0,0,1-1.317-1.286,1.889,1.889,0,0,1-.233-1.1,1.93,1.93,0,0,1,.189-1.025A2.612,2.612,0,0,1,175.9-266.744Z"
                      transform="translate(7938.316 24543.229)"
                    />
                  </g>
                </svg>
              </span>
              <span>Pathways</span>
            </Link>
          </>
        ) : (
          <></>
        )}
        {(permissions && permissions.includes("mp2")) ||
        roleid === "1" ||
        roleid === "2" ? (
          <>
            <Link to="/CreatePathway" className="action-item">
              <div>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 28.345 28.343"
                  >
                    <g id="add" transform="translate(-72.15 439.827)">
                      <path
                        id="Path_13773"
                        data-name="Path 13773"
                        d="M85.636-439.709a2.284,2.284,0,0,0-.663.447c-.594.594-.578.385-.578,6.375v5.3h-5.3c-5.99,0-5.782-.015-6.375.578a1.889,1.889,0,0,0,0,2.7c.594.594.385.578,6.375.578h5.3v5.3c0,5.99-.015,5.782.578,6.375a1.889,1.889,0,0,0,2.7,0c.594-.594.578-.385.578-6.375v-5.3h5.3c5.99,0,5.782.015,6.375-.578a1.889,1.889,0,0,0,0-2.7c-.594-.594-.385-.578-6.375-.578h-5.3v-5.3c0-5.99.015-5.782-.578-6.375A1.921,1.921,0,0,0,85.636-439.709Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </span>
                &nbsp;&nbsp;
                <span>Create New Pathway</span>
              </div>
            </Link>
          </>
        ) : (
          <></>
        )}

        {(permissions && permissions.includes("mc2")) ||
        roleid === "1" ||
        roleid === "2" ? (
          <>
            <Link
              to="/CreateCourse"
              className={
                location.pathname.includes("/CreateCourse")
                  ? "action-item btn active"
                  : "action-item btn"
              }
            >
              <div>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 28.345 28.343"
                  >
                    <g id="add" transform="translate(-72.15 439.827)">
                      <path
                        id="Path_13773"
                        data-name="Path 13773"
                        d="M85.636-439.709a2.284,2.284,0,0,0-.663.447c-.594.594-.578.385-.578,6.375v5.3h-5.3c-5.99,0-5.782-.015-6.375.578a1.889,1.889,0,0,0,0,2.7c.594.594.385.578,6.375.578h5.3v5.3c0,5.99-.015,5.782.578,6.375a1.889,1.889,0,0,0,2.7,0c.594-.594.578-.385.578-6.375v-5.3h5.3c5.99,0,5.782.015,6.375-.578a1.889,1.889,0,0,0,0-2.7c-.594-.594-.385-.578-6.375-.578h-5.3v-5.3c0-5.99.015-5.782-.578-6.375A1.921,1.921,0,0,0,85.636-439.709Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </span>
                &nbsp;&nbsp;
                <span>Create New Course</span>
              </div>
            </Link>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ActionFlex;
