import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import SettingsService from "../../services/settings.service";

const LargeDatasetComponent = () => {
  const [activityLogs, setActivityLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 100;
  const pagesToShow = 10; // Number of pages to show in pagination

  useEffect(() => {
    const limit = "100000";
    const page = "1";
    SettingsService.getActivityLognew(limit, page)
      .then((resp) => {
        setActivityLogs(resp.data);
      })
      .catch((e) => console.log(e));
  }, []); // Empty dependency array to ensure the effect runs only once after the component mounts

  // Calculate index of the first and last record on the current page
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = activityLogs.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Change page
  const totalPages = Math.ceil(activityLogs.length / recordsPerPage);

  const pageNumbers = [];
  for (
    let i = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    i <= Math.min(totalPages, currentPage + Math.floor(pagesToShow / 2));
    i++
  ) {
    pageNumbers.push(i);
  }

  return (
    <div>
      <h1>Activity Logs</h1>
      <ul>
        {currentRecords.map((record) => (
          <li key={record.id}>{record.username}</li>
        ))}
      </ul>
      <div>
        <Pagination>
          <Pagination.First
            onClick={() => paginate(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {pageNumbers.map((number) => (
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => paginate(number)}
            >
              {number}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => paginate(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default LargeDatasetComponent;
