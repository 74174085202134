import React, { useState, useEffect } from "react";

import { Line } from "react-chartjs-2";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const EstorePerformance = ({
  estoreCourseData,
  themeMode,
  estoreCourseValue,
}) => {
  const [lastSevenDates, setLastSevenDates] = useState([]);

  const formatDateString = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}-${month}`;
  };

  useEffect(() => {
    const today = new Date();
    const lastSevenDates = [];

    for (let i = 0; i < 15; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      lastSevenDates.push(formatDateString(date));
    }

    setLastSevenDates(lastSevenDates);
  }, []);

  var style = getComputedStyle(document.body);

  if (themeMode === "0") {
    var whiteColor = "#f5f5f5";
  } else if (themeMode === "1") {
    var whiteColor = "#010001";
  } else {
    var whiteColor = style.getPropertyValue("--whiteColor");
  }

  const data = {
    labels: estoreCourseData,
    datasets: [
      {
        label: "Estore Course Selling",
        data: estoreCourseValue,
        backgroundColor: "#56D364",
        borderColor: "#56D364",
        fill: false,
        borderWidth: 2,
        tension: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        // text: (ctx) => 'Student Course Enrollment'
      },
      tooltip: {
        mode: "index",
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          filter: function (legendItem, data) {
            return legendItem.index != 1;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1, // set the step size to 1 to display integer values
        },
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <>
      <div className="bar-area">
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default EstorePerformance;
