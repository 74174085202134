import React, { useState, useEffect } from "react";
import "./UserManagement.css";
import "./responsive.css";
import Filters from "./Filters";
import { Modal, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminUser from "../../../assets/images/profile.jpg";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import UserService from "../../../services/user.service";
import CohortsService from "../../../services/cohorts.service";
import AuthService from "../../../services/auth.service";

const ManageCohortParticipants = () => {
  const location = useLocation();
  const { id, courses_count } = location.state;

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [checkedState, setCheckedState] = useState([]);

  const [cohort, setCohort] = useState({});

  const [learners, setLearners] = useState([]);

  const [cohortsPart, setCohortsPart] = useState({
    cohotid: "",
    stdid: "",
  });

  const [uid, setUid] = useState("");

  const [learnersCount, setLearnersCount] = useState("");

  const [learnerToModify, setLearnerToModify] = useState(null);

  const getAllLearners = (uids) => {
    UserService.getLearners(uids)
      .then((response) => {
        setLearners(response.data);
        const learner = response.data;

        if (learner.length > 0) {
          if (!$.fn.DataTable.isDataTable("#cohortsPart")) {
            function filterGlobal() {
              $("#cohortsPart")
                .DataTable()
                .search(
                  $("#global_filter").val(),
                  $("#global_regex").prop("checked"),
                  $("#global_smart").prop("checked")
                )
                .draw();
            }
            function filterColumn(i) {
              $("#cohortsPart")
                .DataTable()
                .column(i)
                .search(
                  $("#col" + i + "_filter").val(),
                  $("#col" + i + "_regex").prop("checked"),
                  $("#col" + i + "_smart").prop("checked")
                )
                .draw();
            }

            $(document).ready(function () {
              setTimeout(function () {
                $("#cohortsPart").DataTable({
                  pagingType: "full_numbers",
                  responsive: true,
                  pageLength: 10,
                  select: true,
                  bDestroy: true,

                  lengthMenu: [
                    [10, 20, 30, 50, -1],
                    [10, 20, 30, 50, "All"],
                  ],
                  columnDefs: [
                    {
                      targets: 0,
                      render: function (data, type, row, meta) {
                        return type === "export" ? meta.row + 1 : data;
                      },
                    },
                  ],
                });

                $("input.global_filter").on("keyup click", function () {
                  filterGlobal();
                });

                $("input.column_filter").on("keyup click", function () {
                  filterColumn($(this).parents("tr").attr("data-column"));
                });
              }, 0);
            });
          }
        }

        CohortsService.getCohortsById(id)
          .then((response) => {
            setCohort(response.data[0]);
            setSelectedOptions(response.data[0].ocuupation_category);

            let cohortStId = response.data[0].student.split(",");

            setLearnersCount(cohortStId.length);

            setCheckedState(
              Array.from({ length: learner.length }, (_, index) =>
                cohortStId.includes(learner[index].user_id)
              )
            );
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setUid(user.user);
    getAllLearners(user.user);
  }, []);

  const [add, setAdd] = useState(false);
  const [remove, setRemove] = useState(false);

  const handleAddClose = (e) => {
    e.preventDefault();
    setAdd(false);
    getAllLearners(uid);
  };
  const handleAddShow = (e) => {
    e.preventDefault();
    setAdd(true);
  };

  const handleRemoveClose = (e) => {
    e.preventDefault();
    setRemove(false);
    getAllLearners(uid);
  };
  const handleRemoveShow = (e) => {
    e.preventDefault();
    setRemove(true);
  };

  const changeStatus = (position, uids) => {
    setLearnerToModify(position);

    setCohortsPart({
      stdid: uids,
    });

    if (checkedState[position] === false || checkedState.length === 0) {
      setAdd(true);
      setRemove(false);
    } else {
      setRemove(true);
      setAdd(false);
    }
  };

  const addLearner = () => {
    if (learnerToModify !== null) {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === learnerToModify ? !item : item
      );

      setCheckedState(updatedCheckedState);
    }

    const data = {
      cohotid: id,
      stdid: cohortsPart.stdid,
      cohortsts: "1",
      usp: uid,
    };

    CohortsService.addRemoveParticipants(data)
      .then((response) => {
        toast.success("Learner Added to Cohort Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setAdd(false);
        setRemove(false);
        getAllLearners(uid);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const removeLearner = () => {
    const data = {
      cohotid: id,
      stdid: cohortsPart.stdid,
      cohortsts: "0",
      usp: uid,
    };

    CohortsService.addRemoveParticipants(data)
      .then((response) => {
        toast.success("Learner removed from Cohort Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setRemove(false);

        getAllLearners(uid);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <Filters />
          </div>
          <div className="col-xl-9 col-lg-9">
            <div className="box-outer right">
              <div className="box-inner">
                <div className="action-flex">
                  <div className="action-item back">
                    <Link to="/Cohorts">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 19.379 19.382"
                        >
                          <g
                            id="left-arrow-in-circular-button-black-symbol"
                            transform="translate(-0.6 511.567)"
                          >
                            <path
                              id="Path_13057"
                              data-name="Path 13057"
                              d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                              transform="translate(0 0)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Back</span>
                    </Link>
                  </div>
                  <div className="action-item">
                    <span>{cohort.name}</span>
                  </div>
                  <div className="action-item">
                    <span>
                      Participants: {learnersCount ? learnersCount : 0}
                    </span>
                  </div>
                  <div className="action-item">
                    <span>Courses: {courses_count}</span>
                  </div>
                </div>
                <div className="search-bar">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                  />
                </div>
                <ToastContainer />
                <div className="admin-users cohorts">
                  <div className="table-responsive">
                    <table className="table" id="cohortsPart">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Cohorts</th>
                          <th>Organization</th>
                          <th>Courses</th>
                          <th style={{ textAlign: "center", width: "50px" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {learners.map((learner, index) => (
                          <>
                            <tr>
                              <td>
                                <div className="user-flex">
                                  <div className="user-pic">
                                    <img src={AdminUser} width="40px" alt="" />
                                  </div>
                                  <div className="user-info">
                                    <span className="name">
                                      {learner.first_name} {learner.last_name}
                                    </span>
                                    <span className="role">
                                      {learner.email}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  className={
                                    learner.status === "1"
                                      ? "user-status active"
                                      : "user-status inactive"
                                  }
                                ></div>
                              </td>
                              <td>
                                <span>{learner.cohortscount}</span>
                              </td>
                              <td>
                                <span>{learner.organization}</span>
                              </td>
                              <td>
                                <span>{learner.coursecount}</span>
                              </td>
                              <td>
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id={`learner-${index}`}
                                    name={learner.name}
                                    value={learner.name}
                                    checked={checkedState[index]}
                                    onChange={() =>
                                      changeStatus(index, learner.user_id)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    for="flexSwitchCheckDefault"
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={add} onHide={handleAddClose} centered>
        <Modal.Header>
          <Modal.Title>Add Learner in Cohort</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleAddClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to Add this Learner into this cohort?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleAddClose}>
            No
          </button>
          <button className="yes" onClick={addLearner}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header>
          <Modal.Title>Remove Learner from Cohort</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleRemoveClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this Learner from this cohort?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleRemoveClose}>
            No
          </button>
          <button className="yes" onClick={removeLearner}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageCohortParticipants;
