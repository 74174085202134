import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import "./login.css";
import VerigyOTPService from "../../services/verifyotp.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Forgot = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);

  const onChangeEmail = (e) => {
    const username = e.target.value;
    setEmail(username);
  };

  const sendOTP = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      toast.warn("*Please fill all required fields!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const data = {
      eml: email,
    };

    toast.warn("*Mail sending please wait...", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    VerigyOTPService.sendOTP(data)
      .then((res) => {
        if (res.data !== false) {
          toast.success(
            "OTP Sent successfully to your registered email with us. OTP is valid for 5 minutes only.",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );

          localStorage.setItem("email", email);

          setTimeout(() => {
            navigate("/ChangePassword");
          }, 5000);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="login_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <Form noValidate validated={validated} onSubmit={sendOTP}>
                <div className="login active">
                  <div className="heading">
                    <h1>Forget Password</h1>
                  </div>
                  <ToastContainer />
                  <div className="form">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="input"
                        required
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required]}
                      />
                      <label htmlFor="">Email</label>
                    </div>
                    <div className="form-group action">
                      <div className="flex_form-group">
                        <button
                          className="fill_btn"
                          type="submit"
                          name="btn_login"
                        >
                          Send OTP
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            <div className="col-xl-6 col-lg-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Forgot;
