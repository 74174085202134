import React from "react";
import Loading from "../assets/images/Loading.gif";

const PageNotFound = () => {
  return (
    <div className="pagenotfound">
      <img src={Loading} width="200px" alt="" />
      <h1>404</h1>
      <h2>Look like you're lost</h2>
      <p>the page you are looking for not available!</p>
    </div>
  );
};

export default PageNotFound;
