import React, { useEffect, useState, useRef } from "react";
import "./CourseManagement.css";
import { Form, FloatingLabel, Modal, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import CourseService from "../../../services/course.service";
import PathwayService from "../../../services/pathway.service";
import OccupcatService from "../../../services/occupcat.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AuthService from "../../../services/auth.service";

const Prerequisites = ({ prevStep, nextStep, cid }) => {
  const [prereq, setPrereq] = useState({
    usp: "", // usp
    courseid: cid, // courseid
    sts: "0", // status
    pathid: "", // pathway id
    precid: "", // course in pathway
    occup: "", // occupation
    comcid: "", // completing course
  });

  const [inputBox, setInputBox] = useState(false);

  const [pathways, setPatways] = useState({});
  const [pathwayCourses, setPatwayCourses] = useState({});
  const [occup, setOccup] = useState({});
  const [courses, setCourses] = useState({});

  const [selectedPathway, setSelectedPathway] = useState();
  const [selectedPathCourses, setSelectedPathCourses] = useState();
  const [selectedOccup, setSelectedOccup] = useState();
  const [selectedCourses, setSelectedCourses] = useState();

  const [pathwayid, setPathwayid] = useState();

  const [validated, setValidated] = useState(false);

  const [chnageData, setChangeData] = useState(false);

  let value = "0";

  const showInputBox = () => {
    setInputBox(!inputBox);
    value = inputBox ? "0" : "1";
    setPrereq({ ...prereq, sts: value });
    setChangeData(true);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setPrereq({ ...prereq, usp: user.user });

    CourseService.getCourse(user.user)
      .then((response) => {
        if (response.data !== false) {
          setCourses(
            response.data.map((course) => ({
              value: course.id,
              label: course.name,
            }))
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });

    PathwayService.getPathway(user.user)
      .then((response) => {
        if (response.data.length > 0) {
          setPatways(
            response.data.map((pathway) => ({
              value: pathway.id,
              label: pathway.name,
            }))
          );
        } else {
          setPatways([]);
          setPrereq({ ...prereq, pathid: "0" });
          setPrereq({ ...prereq, precid: "0" });
        }
      })
      .catch((e) => {
        console.log(e);
      });

    OccupcatService.getOccupCat(user.user)
      .then((response) => {
        if (response.data !== false) {
          setOccup(
            response.data.map((occup) => ({
              value: occup.id,
              label: occup.category_name,
            }))
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });

    if (pathwayid) {
      PathwayService.getPathwayById(pathwayid)
        .then((response) => {
          if (response.data !== false) {
            setPatwayCourses(
              response.data.map((pathway) => ({
                value: pathway.course_id,
                label: pathway.coursename,
              }))
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setPatwayCourses([]);
    }

    CourseService.getPrerequisites(cid, user.user)
      .then((response) => {
        setPrereq({
          usp: user.user, // usp
          courseid: cid, // courseid
          sts: response.data[0].pathsts, // status
          pathid: response.data[0].pathway_id, // pathway id
          precid: response.data[0].precid, // course in pathway
          occup: response.data[0].occup, // occupation
          comcid: response.data[0].comcid, // completing course
        });

        setPathwayid(response.data[0].pathway_id);

        if (response.data[0].pathway_id) {
          PathwayService.getPathwayById(response.data[0].pathway_id)
            .then((response) => {
              if (response.data !== false) {
                setPatwayCourses(
                  response.data.map((pathway) => ({
                    value: pathway.course_id,
                    label: pathway.coursename,
                  }))
                );
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }

        if (response.data[0].pathsts === "1") {
          setInputBox(true);
        } else {
          setInputBox(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handlePathway = (selectedPathway) => {
    setSelectedPathway(selectedPathway);
    const value = selectedPathway ? selectedPathway.value : "";
    setPrereq({ ...prereq, pathid: value });
    setPathwayid(value);

    if (value !== 0) {
      PathwayService.getPathwayById(value)
        .then((response) => {
          if (response.data !== false) {
            setPatwayCourses(
              response.data.map((pathway) => ({
                value: pathway.course_id,
                label: pathway.coursename,
              }))
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    setChangeData(true);
  };

  const handlePathwayCourses = (selectedPathCourses) => {
    setSelectedPathCourses(selectedPathCourses);
    const value = selectedPathCourses ? selectedPathCourses.value : "";
    setPrereq({ ...prereq, precid: value });
    setChangeData(true);
  };

  const handleOccup = (selectedOccup) => {
    setSelectedOccup(selectedOccup);
    const value = selectedOccup ? selectedOccup.value : "";
    setPrereq({ ...prereq, occup: value });
    setChangeData(true);
  };

  const handleCourses = (selectedCourses) => {
    setSelectedCourses(selectedCourses);
    const value = selectedCourses ? selectedCourses.value : "";
    setPrereq({ ...prereq, comcid: value });
    setChangeData(true);
  };

  const prevPage = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      usp: prereq.usp, // user id
      courseid: cid, // courseid
      sts: prereq.sts, // status
      pathid: prereq.pathid === null ? "0" : prereq.pathid, // pathway id
      precid: prereq.precid === null ? "0" : prereq.precid, // course in pathway
      occup: prereq.occup, // occupation
      comcid: prereq.comcid, // completing course
    };

    if (chnageData) {
      CourseService.addPrerequisites(data)
        .then((response) => {
          toast.success("Prerequisites Added/Updated Succefully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            nextStep();
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      nextStep();
    }
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <ToastContainer />
        <div className="add-user-form">
          <Form.Group className="mb-3" controlId="formBasicOrgName">
            <div className="flex-column">
              <Form.Label>Is this course part of a Pathway?</Form.Label>
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={showInputBox}
                  name="sts"
                  value={prereq.sts}
                  checked={inputBox}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </Form.Group>
          {inputBox ? (
            <>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Add to Existing Pathway</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: "none",
                        outline: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--dark-bg)"
                          : isFocused
                          ? "var(--dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    // className="react-select-container"
                    // classNamePrefix="react-select"
                    onChange={handlePathway}
                    // value={selectedPathway}
                    options={pathways}
                    issearchable="true"
                    placeholder="Select Pathway"
                    value={
                      Array.isArray(pathways)
                        ? pathways.filter(
                            (option) => prereq.pathid === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              {pathwayid ? (
                <>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Previous Course in Pathway</Form.Label>
                    <div className="input-box">
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: "none",
                            outline: "none",
                            borderRadius: "10px",
                            backgroundColor: state.isFocused
                              ? "var(--dark-bg)"
                              : "var(--dark-bg)",
                            height: "45px",
                          }),
                          option: (baseStyles, { isFocused, isSelected }) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? "var(--primary-color)"
                              : isFocused
                              ? "var(--secondary-color)"
                              : "var(--dark-bg)",
                            color: isSelected
                              ? "var(--dark-bg)"
                              : isFocused
                              ? "var(--dark-bg)"
                              : "var(--whiteColor)",
                          }),
                        }}
                        // className="react-select-container"
                        // classNamePrefix="react-select"
                        onChange={handlePathwayCourses}
                        // value={selectedPathCourses}
                        options={pathwayCourses}
                        issearchable="true"
                        placeholder="Select Course in Pathway"
                        value={
                          Array.isArray(pathwayCourses)
                            ? pathwayCourses.filter(
                                (option) => prereq.precid === option.value
                              )
                            : []
                        }
                      />
                    </div>
                  </Form.Group>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Prerequisites, only for</Form.Label>
            <div className="input-box">
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: state.isFocused
                      ? "var(--dark-bg)"
                      : "var(--dark-bg)",
                    height: "45px",
                  }),
                  option: (baseStyles, { isFocused, isSelected }) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? "var(--primary-color)"
                      : isFocused
                      ? "var(--secondary-color)"
                      : "var(--dark-bg)",
                    color: isSelected
                      ? "var(--dark-bg)"
                      : isFocused
                      ? "var(--dark-bg)"
                      : "var(--whiteColor)",
                  }),
                }}
                // className="react-select-container"
                // classNamePrefix="react-select"
                onChange={handleOccup}
                // value={selectedOccup}
                options={occup}
                issearchable="true"
                placeholder="Select Occupation Category"
                value={
                  Array.isArray(occup)
                    ? occup.filter((option) => prereq.occup === option.value)
                    : []
                }
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Prerequisites, only completing course</Form.Label>
            <div className="input-box">
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: state.isFocused
                      ? "var(--dark-bg)"
                      : "var(--dark-bg)",
                    height: "45px",
                  }),
                  option: (baseStyles, { isFocused, isSelected }) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? "var(--primary-color)"
                      : isFocused
                      ? "var(--secondary-color)"
                      : "var(--dark-bg)",
                    color: isSelected
                      ? "var(--dark-bg)"
                      : isFocused
                      ? "var(--dark-bg)"
                      : "var(--whiteColor)",
                  }),
                }}
                // className="react-select-container"
                // classNamePrefix="react-select"
                onChange={handleCourses}
                // value={selectedCourses}
                options={courses}
                issearchable="true"
                placeholder="Select Course"
                value={
                  Array.isArray(courses)
                    ? courses.filter((option) => prereq.comcid === option.value)
                    : []
                }
              />
            </div>
          </Form.Group>
        </div>
        <div className="flex-row">
          <input
            type="submit"
            className="onboard-btn-o"
            value="Back"
            onClick={prevPage}
          />
          <input type="submit" className="onboard-btn-f" value="Next" />
        </div>
      </Form>
    </>
  );
};

export default Prerequisites;
