import React, { useEffect, useState, useRef } from "react";
import "./CourseManagement.css";
import Filters from "./Filters";
import { Form, FloatingLabel, Modal, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

import PathwayService from "../../../services/pathway.service";
import CourseService from "../../../services/course.service";
import AuthService from "../../../services/auth.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreatePathway = () => {
  const [pathway, setPathway] = useState({
    name: "",
    start_date: "",
    end_date: "",
    coursess: [],
    courseCons: [],
  });

  const [coursesCon, setCoursesCon] = useState([]);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState({});
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);

  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [uid, setUid] = useState("");

  const conditionOpts = [
    { value: "1", label: "None" },
    { value: "2", label: "After previous course has been completed" },
  ];

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setUid(user.user);

    CourseService.getCourse(user.user)
      .then((response) => {
        setCourses(response.data);

        const courses = response.data;

        const filteredCourses = courses.filter(
          (course) => course.pubsts === "1"
        );

        setOptions(
          Array.isArray(filteredCourses)
            ? filteredCourses.map((course) => ({
                value: course.id,
                label: course.name,
              }))
            : null
        );
      })
      .catch((e) => {
        console.log(e);
      });

    setCoursesCon(
      Array.isArray(selectedCourse)
        ? selectedCourse.map((course) => ({
            courseid: course.id,
            conditions: "",
          }))
        : null
    );
  }, []);

  const [selectedOption, setSelectedOption] = useState();

  const [selectedCondition, setSelectedCondition] = useState({});

  const [selectedValues, setSelectedValues] = useState([]);

  const [validated, setValidated] = useState(false);

  const handleSelectChange = (id, event) => {
    setSelectedCondition((prevState) => ({
      ...prevState,
      [id]: event, // Store the event value using the course id as the key
    }));

    const conditions = event ? event.value : "";

    setCoursesCon((prevState) =>
      Array.isArray(prevState)
        ? prevState.map((input) =>
            input.courseid === id ? { ...input, conditions } : input
          )
        : null
    );
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...selectedCourse];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setSelectedCourse(copyListItems);
  };

  const [show, setShow] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPathway({ ...pathway, [name]: value });
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event);
    const values = Array.isArray(event)
      ? event.map((option) => option.value).toString()
      : null;
    setSelectedValues(values);
    setPathway({ ...pathway, coursess: values });
    const valSplit = values.split(",");

    if (valSplit[0] !== "") {
      const selectedCourses = courses.filter((course) =>
        valSplit.includes(course.id)
      );
      setSelectedCourse(selectedCourses);

      setCoursesCon(
        Array.isArray(selectedCourses)
          ? selectedCourses.map((course) => ({
              courseid: course.id,
              conditions: "",
            }))
          : null
      );
    } else {
      setSelectedCourse();
    }
  };

  const handleRemoveItem = (index) => {
    setCourses(courses.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      toast.warn("Please fill all required fields!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const data = {
      name: pathway.name,
      start_date: pathway.start_date,
      end_date: pathway.end_date,
      coursess: pathway.coursess,
      courseCons: coursesCon,
    };

    PathwayService.createPathway(uid, data)
      .then((response) => {
        toast.success("Pathway created successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setPathway({
          name: "",
          start_date: "",
          end_date: "",
          coursess: [],
          courseCons: [],
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <Filters />
          </div>
          <div className="col-xl-9 col-lg-9">
            <div className="box-outer right">
              <div className="box-inner">
                <ToastContainer />
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="action-flex">
                    <div className="heading">
                      <h1>Create Pathway</h1>
                    </div>
                    <div className="courses-act">
                      <Link to="/ManagePathway" className="action-item">
                        <div>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 19.379 19.382"
                            >
                              <g
                                id="left-arrow-in-circular-button-black-symbol"
                                transform="translate(-0.6 511.567)"
                              >
                                <path
                                  id="Path_13057"
                                  data-name="Path 13057"
                                  d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                                  transform="translate(0 0)"
                                />
                              </g>
                            </svg>
                          </span>
                          &nbsp;&nbsp;
                          <span>Back</span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="form-flex">
                    <div className="text-input">
                      <Form.Group
                        controlId="formBasicOrgName"
                        className="input-box"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter Pathway Name *"
                          name="name"
                          onChange={handleInputChange}
                          value={pathway.name}
                          required
                        />
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 20.199 20.201"
                          >
                            <g
                              id="Group_11227"
                              data-name="Group 11227"
                              transform="translate(-2402.326 -6200.833)"
                            >
                              <path
                                id="Path_11890"
                                data-name="Path 11890"
                                d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                transform="translate(752.656 5510.435)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                id="Path_11892"
                                data-name="Path 11892"
                                d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                transform="translate(751.221 5511.583)"
                              />
                            </g>
                          </svg>
                        </span>
                      </Form.Group>
                    </div>

                    <div className="date-input">
                      <Form.Group
                        controlId="formBasicOrgName"
                        className="date-input"
                      >
                        <div className="input-box">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Start Date"
                            className="mb-3"
                          >
                            <Form.Control
                              type="date"
                              name="start_date"
                              onChange={handleInputChange}
                              value={pathway.start_date}
                              min={new Date().toJSON().slice(0, 10)}
                            />
                          </FloatingLabel>
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14.02"
                              viewBox="0 0 81.923 76.58"
                            >
                              <g id="calendar" transform="translate(-15 482)">
                                <path
                                  id="Path_13206"
                                  data-name="Path 13206"
                                  d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                                />
                                <path
                                  id="Path_13207"
                                  data-name="Path 13207"
                                  d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                                  transform="translate(0 -108.781)"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicOrgName"
                        className="date-input"
                      >
                        <div className="input-box">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="End Date"
                            className="mb-3"
                          >
                            <Form.Control
                              type="date"
                              name="end_date"
                              onChange={handleInputChange}
                              value={pathway.end_date}
                              min={new Date().toJSON().slice(0, 10)}
                            />
                          </FloatingLabel>
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14.02"
                              viewBox="0 0 81.923 76.58"
                            >
                              <g id="calendar" transform="translate(-15 482)">
                                <path
                                  id="Path_13206"
                                  data-name="Path 13206"
                                  d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                                />
                                <path
                                  id="Path_13207"
                                  data-name="Path 13207"
                                  d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                                  transform="translate(0 -108.781)"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="form-flex">
                    <div className="add-course">
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "100%",
                            outline: "none",
                            borderRadius: "10px",
                            backgroundColor: state.isFocused
                              ? "transperent"
                              : "transperent",
                            border: "1px solid #707070",
                            height: "45px",
                          }),
                          option: (baseStyles, { isFocused, isSelected }) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? "var(--primary-color)"
                              : isFocused
                              ? "var(--secondary-color)"
                              : "var(--dark-bg)",
                            color: isSelected
                              ? "var(--body-dark-bg)"
                              : isFocused
                              ? "var(--body-dark-bg)"
                              : "var(--whiteColor)",
                          }),
                        }}
                        // className="react-select-container"
                        // classNamePrefix="react-select"
                        options={options}
                        isMulti={true}
                        issearchable="true"
                        placeholder="Add Courses *"
                        onChange={handleOptionChange}
                        value={
                          Array.isArray(options)
                            ? options.filter((option) =>
                                pathway.coursess.includes(option.value)
                              )
                            : []
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="reorder-flex">
                    <Form.Label className="reorder-label">
                      Reorder Courses in Pathway
                    </Form.Label>
                    <div className="course-flex">
                      {Array.isArray(selectedCourse)
                        ? selectedCourse.map((course, index) => {
                            return (
                              <div
                                className="course"
                                key={index}
                                draggable
                                onDragStart={(e) => dragStart(e, index)}
                                onDragEnter={(e) => dragEnter(e, index)}
                                onDragEnd={drop}
                              >
                                <div className="course-count">
                                  <span>{index + 1}</span>
                                  <span>
                                    <i className="uil uil-draggabledots"></i>
                                  </span>
                                </div>
                                <div className="draggable-course">
                                  <div className="course-info">
                                    <div className="course-name">
                                      <span>{course.name}</span>
                                    </div>
                                    <div className="conditions">
                                      <label htmlFor="conditions1">
                                        Set conditions
                                      </label>
                                      <Select
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            outline: "none",
                                            border: "none",
                                            borderRadius: "10px",
                                            backgroundColor: state.isFocused
                                              ? "var(--body-dark-bg)"
                                              : "var(--body-dark-bg)",
                                            height: "45px",
                                            width: "500px",
                                            maxWidth: "100%",
                                            color: "var(--whiteColor)",
                                          }),
                                          option: (
                                            baseStyles,
                                            { isFocused, isSelected }
                                          ) => ({
                                            ...baseStyles,
                                            backgroundColor: isSelected
                                              ? "var(--primary-color)"
                                              : isFocused
                                              ? "var(--secondary-color)"
                                              : "var(--body-dark-bg)",
                                            color: isSelected
                                              ? "var(--body-dark-bg)"
                                              : isFocused
                                              ? "var(--body-dark-bg)"
                                              : "var(--whiteColor)",
                                          }),
                                        }}
                                        options={conditionOpts}
                                        issearchable="true"
                                        placeholder="Set conditions"
                                        onChange={(event) =>
                                          handleSelectChange(course.id, event)
                                        }
                                        // value={selectedCondition}
                                        value={
                                          selectedCondition[course.id] || null
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="course-action">
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => handleRemoveItem(index)}
                                    >
                                      <i className="uil uil-trash"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                    <div className="act-btn">
                      <button>Submit</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Remove Course</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove Course form this pathway?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={handleClose}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreatePathway;
