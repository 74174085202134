import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../../../services/auth.service";

const ActionFlex = ({ permissions }) => {
  const location = useLocation();

  const [roleid, setRoleid] = useState("");

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setRoleid(user.role_id);
    }
  }, []);

  return (
    <>
      <div className="action-flex">
        {roleid === "1" ? (
          <>
            <Link
              to="/SystemManagers"
              className={
                location.pathname.includes("/SystemManagers") ||
                location.pathname.includes("/PresetRoles")
                  ? "action-item active"
                  : "action-item"
              }
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 29.869 29.869"
                >
                  <g
                    id="user_1_"
                    data-name="user (1)"
                    transform="translate(0.5 0.5)"
                  >
                    <g
                      id="Group_11122"
                      data-name="Group 11122"
                      transform="translate(4.528 -0.5)"
                    >
                      <path
                        id="Path_12694"
                        data-name="Path 12694"
                        d="M90.921-.5h1.75a6.181,6.181,0,0,1,2.392,11.347,6.334,6.334,0,0,1-5.309.583A6.114,6.114,0,0,1,85.7,4.867,6.159,6.159,0,0,1,90.921-.5Z"
                        transform="translate(-85.681 0.5)"
                        fillRule="evenodd"
                      />
                    </g>
                    <g
                      id="Group_11123"
                      data-name="Group 11123"
                      transform="translate(14.094 13.266)"
                    >
                      <path
                        id="Path_12695"
                        data-name="Path 12695"
                        d="M264.938,240.546v.7a1.251,1.251,0,0,1-.35.438,13.471,13.471,0,0,0-1.2.9l-.029.263a9.392,9.392,0,0,1,.029,1.6,13.471,13.471,0,0,0,1.2.9,1.251,1.251,0,0,1,.35.438v.7q-.589.972-1.138,1.983a1.015,1.015,0,0,1-1.138.438l-1.488-.613q-.4.236-.788.5a4.331,4.331,0,0,1-.875.379q-.073.878-.2,1.75a1.4,1.4,0,0,1-.613.642h-2.8a1.159,1.159,0,0,1-.554-.583q-.131-.894-.262-1.779a9.226,9.226,0,0,1-1.6-.9l-1.488.613a1.038,1.038,0,0,1-1.079-.321q-.606-1.007-1.167-2.042a1.038,1.038,0,0,1,.117-.992,16.563,16.563,0,0,1,1.313-1.021.255.255,0,0,0,.117-.233,9.2,9.2,0,0,1-.029-1.721q-.755-.55-1.458-1.167a1.12,1.12,0,0,1,.058-1.167l1.108-1.867a1.063,1.063,0,0,1,1.021-.263,8.748,8.748,0,0,0,1.575.583,8.729,8.729,0,0,1,1.546-.9q.091-.879.233-1.75a.928.928,0,0,1,.438-.5,18.871,18.871,0,0,1,2.917-.058,1.178,1.178,0,0,1,.613.613q.1.88.263,1.75a8.011,8.011,0,0,1,1.6.875l1.488-.613a1.054,1.054,0,0,1,1.138.438Q264.349,239.574,264.938,240.546Zm-8.109.758a2.257,2.257,0,1,1-1.721,2.625A2.339,2.339,0,0,1,256.829,241.3Z"
                        transform="translate(-249.663 -235.468)"
                        fillRule="evenodd"
                      />
                    </g>
                    <g
                      id="Group_11124"
                      data-name="Group 11124"
                      transform="translate(-0.5 13.387)"
                    >
                      <path
                        id="Path_12696"
                        data-name="Path 12696"
                        d="M18.285,253.518H.667A1.836,1.836,0,0,1-.5,252.41v-4.842a11.188,11.188,0,0,1,15.81-9.013A3.378,3.378,0,0,0,14,239.575q-.5.919-1.05,1.808a2.687,2.687,0,0,0,1.079,3.85.5.5,0,0,1,0,.467,2.687,2.687,0,0,0-1.079,3.85q.472.736.875,1.517a2.561,2.561,0,0,0,3,1.313A9.7,9.7,0,0,0,17.76,252a2.985,2.985,0,0,1,.321.233A8.822,8.822,0,0,0,18.285,253.518Z"
                        transform="translate(0.5 -237.535)"
                        fillRule="evenodd"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span>System Managers</span>
            </Link>
          </>
        ) : (
          <></>
        )}
        {(permissions && permissions.includes("ml1")) ||
        roleid === "1" ||
        roleid === "2" ? (
          <>
            <Link
              to="/AllLearners"
              className={
                location.pathname.includes("/AllLearners")
                  ? "action-item active"
                  : "action-item"
              }
            >
              <span>
                <svg
                  id="Group_11662"
                  data-name="Group 11662"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  width="20"
                  height="24"
                  viewBox="0 0 32.774 39.017"
                >
                  <defs>
                    <clipPath id="clipPath">
                      <rect
                        id="Rectangle_179"
                        data-name="Rectangle 179"
                        width="32.774"
                        height="39.017"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="Group_10841"
                    data-name="Group 10841"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="Path_11720"
                      data-name="Path 11720"
                      d="M104.545,0a16.786,16.786,0,0,1,1.678.46,6.465,6.465,0,1,1-3.264-.393A1.383,1.383,0,0,0,103.173,0Z"
                      transform="translate(-87.476)"
                    />
                    <path
                      id="Path_11721"
                      data-name="Path 11721"
                      d="M42.056,194.831c-.984-.367-1.944-.724-2.9-1.083l-8.263-3.1a1.244,1.244,0,0,1-1.013-1.443c0-.7,0-1.393,0-2.115.286-.017.55-.014.809-.052a3.285,3.285,0,0,0,2.943-2.942,20.125,20.125,0,0,0,0-3.955,3.323,3.323,0,0,0-3.428-2.983c-.089,0-.177,0-.326,0v-1.136c0-.432,0-.864,0-1.3a1.149,1.149,0,0,1,1.66-1.141q3.085,1.147,6.164,2.308c1.354.508,2.706,1.021,4.062,1.521a.39.39,0,0,1,.317.426q-.013,8.346-.007,16.693c0,.086-.009.172-.017.3"
                      transform="translate(-26.834 -155.814)"
                    />
                    <path
                      id="Path_11722"
                      data-name="Path 11722"
                      d="M184.24,187.123c0,.875.031,1.711-.011,2.544-.028.562-.446.843-.944,1.027q-2.871,1.064-5.735,2.145l-4.986,1.869c-.153.057-.307.109-.491.174-.008-.138-.019-.246-.019-.354q0-8.29-.007-16.579a.437.437,0,0,1,.339-.49q5.064-1.882,10.12-3.785a1.168,1.168,0,0,1,1.743,1.21q0,1.105,0,2.21a.268.268,0,0,1-.019.069,11.3,11.3,0,0,0-1.228.18,3.289,3.289,0,0,0-2.528,2.953,25.981,25.981,0,0,0,.005,3.77,3.368,3.368,0,0,0,3.371,3.058c.114,0,.228,0,.395,0"
                      transform="translate(-154.525 -155.87)"
                    />
                    <path
                      id="Path_11723"
                      data-name="Path 11723"
                      d="M73.643,138.906a12.84,12.84,0,0,1,3.975-2.448.521.521,0,0,1,.38.069,8.655,8.655,0,0,0,9.048.011.6.6,0,0,1,.448-.059,12.705,12.705,0,0,1,3.81,2.329.81.81,0,0,1,.087.124l-2.1.789c-2.135.8-4.268,1.609-6.408,2.4a1.013,1.013,0,0,1-.646.013c-2.758-1.018-5.508-2.055-8.26-3.087-.094-.035-.185-.078-.329-.139"
                      transform="translate(-66.143 -122.548)"
                    />
                    <path
                      id="Path_11724"
                      data-name="Path 11724"
                      d="M4.568,234.7c0,.483.006.965,0,1.448a1.148,1.148,0,0,1-1.2,1.2,3.846,3.846,0,0,1-1.9-.285,2.666,2.666,0,0,1,1.1-5.046c.292-.009.585-.012.876,0a1.135,1.135,0,0,1,1.117,1.124c.015.521,0,1.042,0,1.563"
                      transform="translate(0 -208.375)"
                    />
                    <path
                      id="Path_11725"
                      data-name="Path 11725"
                      d="M276.883,234.714c0-.483-.006-.966,0-1.448a1.135,1.135,0,0,1,1.173-1.182,6.745,6.745,0,0,1,1.288.07,2.636,2.636,0,0,1,2.1,2.816,2.677,2.677,0,0,1-2.542,2.44c-.279.011-.559.009-.838,0a1.144,1.144,0,0,1-1.184-1.175c-.009-.508,0-1.016,0-1.524"
                      transform="translate(-248.684 -208.448)"
                    />
                  </g>
                </svg>
              </span>
              <span>All Learners</span>
            </Link>
          </>
        ) : (
          <></>
        )}

        {(permissions && permissions.includes("mgc1")) ||
        roleid === "1" ||
        roleid === "2" ? (
          <>
            <Link
              to="/Cohorts"
              className={
                location.pathname.includes("/Cohorts")
                  ? "action-item active"
                  : "action-item"
              }
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="20"
                  viewBox="0 0 47.6 25.004"
                >
                  <g
                    id="Group_11680"
                    data-name="Group 11680"
                    transform="translate(8751 6533.146)"
                  >
                    <path
                      id="Path_14243"
                      data-name="Path 14243"
                      d="M220.887-408.511a5.805,5.805,0,0,0-3.433,3,8.354,8.354,0,0,0-.215,5.056c1.235,5.185,5.415,7.21,8.5,4.122a9.321,9.321,0,0,0,2.4-6.923,4.339,4.339,0,0,0-.5-2.327A5.588,5.588,0,0,0,220.887-408.511Z"
                      transform="translate(-8949.408 -6124.365)"
                    />
                    <path
                      id="Path_14241"
                      data-name="Path 14241"
                      d="M128.98-387.451a4.336,4.336,0,0,0-2.7,2.6,8.27,8.27,0,0,0,0,3.663c1.063,4.051,4.381,5.487,6.679,2.858a7.941,7.941,0,0,0,1.35-6.55A4.363,4.363,0,0,0,128.98-387.451Z"
                      transform="translate(-8871.577 -6142.452)"
                    />
                    <path
                      id="Path_14246"
                      data-name="Path 14246"
                      d="M329.871-387.487a4.541,4.541,0,0,0-2.715,2.571,8.525,8.525,0,0,0,.575,5.286c1.25,2.743,3.936,3.4,5.875,1.465a8.4,8.4,0,0,0,1.695-3.375,6.97,6.97,0,0,0-.3-3.806A4.313,4.313,0,0,0,329.871-387.487Z"
                      transform="translate(-9044.316 -6142.739)"
                    />
                    <path
                      id="Path_14242"
                      data-name="Path 14242"
                      d="M93.273-305.456a8.468,8.468,0,0,0-4.97,3.835,5.544,5.544,0,0,0-.445,2.787l-.057,1.953,1.221.3a38,38,0,0,0,6.521.977l1.767.1v-1.781a12.845,12.845,0,0,1,.215-2.643,9.86,9.86,0,0,1,3.3-5.113c.546-.388.618-.488.417-.56a1.475,1.475,0,0,0-.919.316,6.138,6.138,0,0,1-2.729.833,6.887,6.887,0,0,1-2.887-.847l-.661-.4Z"
                      transform="translate(-8838.8 -6212.64)"
                    />
                    <path
                      id="Path_14244"
                      data-name="Path 14244"
                      d="M177.5-305.641c-3.146,1.106-5.415,2.988-6.248,5.214a11.173,11.173,0,0,0-.273,3.332s22.942.3,24.547-.9c0-3.232-2.14-5.846-6.061-7.412-1.724-.689-1.925-.689-2.916-.086a6,6,0,0,1-3.375.934,5.922,5.922,0,0,1-3.361-.934,4.1,4.1,0,0,0-1.077-.5A7.024,7.024,0,0,0,177.5-305.641Z"
                      transform="translate(-8910.018 -6212.383)"
                    />
                    <path
                      id="Path_14245"
                      data-name="Path 14245"
                      d="M330.136-305.614c-.2.072-.1.2.488.646a9.788,9.788,0,0,1,3.217,5.042,12.845,12.845,0,0,1,.215,2.643v1.781l1.767-.1a38,38,0,0,0,6.521-.977l1.221-.3-.057-1.953a5.544,5.544,0,0,0-.445-2.787,8.414,8.414,0,0,0-4.97-3.835l-.776-.244-.661.4a6.887,6.887,0,0,1-2.887.847,6.138,6.138,0,0,1-2.729-.833A1.593,1.593,0,0,0,330.136-305.614Z"
                      transform="translate(-9046.966 -6212.963)"
                    />
                  </g>
                </svg>
              </span>
              <span>Cohorts</span>
            </Link>
          </>
        ) : (
          <></>
        )}

        {location.pathname.includes("/Cohorts") ? (
          <>
            {(permissions && permissions.includes("mgc2")) ||
            roleid === "1" ||
            roleid === "2" ? (
              <>
                <Link
                  to="/AddCohort"
                  className={
                    location.pathname.includes("/AddCohort")
                      ? "action-item btn active"
                      : "action-item btn"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 23.624 23.627"
                    >
                      <g id="plus-_1_" transform="translate(269.818 -70.15)">
                        <path
                          id="Path_12960"
                          data-name="Path 12960"
                          d="M11.452-511.874a1.963,1.963,0,0,0-.974.554,2,2,0,0,0-.568,1.034c-.037.171-.051,1.459-.051,4.164v3.924H5.936c-2.705,0-3.993.014-4.164.051a2.021,2.021,0,0,0-1.034.568,2.037,2.037,0,0,0,0,2.913,2.063,2.063,0,0,0,1.034.572c.171.037,1.417.051,4.164.051H9.86v3.924c0,2.705.014,3.993.051,4.164a1.972,1.972,0,0,0,.568,1.029,2.033,2.033,0,0,0,2.913,0,2.063,2.063,0,0,0,.572-1.034c.037-.171.051-1.417.051-4.164v-3.924h3.924c2.705,0,3.993-.014,4.164-.051a1.972,1.972,0,0,0,1.029-.568,2.039,2.039,0,0,0,0-2.918,2.023,2.023,0,0,0-1.048-.572c-.143-.028-1.8-.046-4.155-.046H14.015v-3.924c0-2.705-.014-3.993-.051-4.164a2.021,2.021,0,0,0-.568-1.034A2.013,2.013,0,0,0,11.452-511.874Z"
                          transform="translate(-269.943 582.086)"
                        />
                      </g>
                    </svg>
                  </span>
                  <span>Add New Cohort</span>
                </Link>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {(permissions && permissions.includes("ml2")) ||
            roleid === "1" ||
            roleid === "2" ? (
              <>
                <Link
                  to="/AddUser"
                  className={
                    location.pathname.includes("/AddUser")
                      ? "action-item btn active"
                      : "action-item btn"
                  }
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 23.624 23.627"
                    >
                      <g id="plus-_1_" transform="translate(269.818 -70.15)">
                        <path
                          id="Path_12960"
                          data-name="Path 12960"
                          d="M11.452-511.874a1.963,1.963,0,0,0-.974.554,2,2,0,0,0-.568,1.034c-.037.171-.051,1.459-.051,4.164v3.924H5.936c-2.705,0-3.993.014-4.164.051a2.021,2.021,0,0,0-1.034.568,2.037,2.037,0,0,0,0,2.913,2.063,2.063,0,0,0,1.034.572c.171.037,1.417.051,4.164.051H9.86v3.924c0,2.705.014,3.993.051,4.164a1.972,1.972,0,0,0,.568,1.029,2.033,2.033,0,0,0,2.913,0,2.063,2.063,0,0,0,.572-1.034c.037-.171.051-1.417.051-4.164v-3.924h3.924c2.705,0,3.993-.014,4.164-.051a1.972,1.972,0,0,0,1.029-.568,2.039,2.039,0,0,0,0-2.918,2.023,2.023,0,0,0-1.048-.572c-.143-.028-1.8-.046-4.155-.046H14.015v-3.924c0-2.705-.014-3.993-.051-4.164a2.021,2.021,0,0,0-.568-1.034A2.013,2.013,0,0,0,11.452-511.874Z"
                          transform="translate(-269.943 582.086)"
                        />
                      </g>
                    </svg>
                  </span>
                  {roleid !== "1" || roleid !== "2" ? (
                    <>
                      <span>Add New Learner</span>
                    </>
                  ) : (
                    <>
                      <span>Add New User</span>
                    </>
                  )}
                </Link>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ActionFlex;
