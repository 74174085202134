import React from "react";
import { useLearnersListContext } from "./providers/AllLearnersProvider";

const LearnersList = () => {
  const { learners } = useLearnersListContext() || {};

  return (
    <div>
      <h1>All Learners</h1>
      {learners && learners.length > 0 ? (
        <ul>
          {learners.map((learner) => (
            <li key={learner.id}>{learner.first_name}</li>
          ))}
        </ul>
      ) : (
        <p>No learners available.</p>
      )}
    </div>
  );
};

export default LearnersList;
