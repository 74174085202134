import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ErrorreqService from "../services/errorreq.service";
import CourseImg from "../assets/images/course-placeholder.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import CourseService from "../services/course.service";
import AuthService from "../services/auth.service";
import CertService from "../services/cert.service";
import UserService from "../services/user.service";

const CoursesList = ({
  permissions,
  roleid,
  selectedOccuCat,
  selectedCourseCat,
}) => {
  const location = useLocation();
  const userdata = useSelector((state) => state.userDataReducer);
  const [show, setShow] = useState(false);
  const [courses, setCourses] = useState([]);
  const [certificateIssued, setCertificateIssued] = useState([]);
  const [activeCourses, setActiveCourses] = useState("0");
  const [inactiveCourses, setInactiveCourses] = useState("0");
  const [draftCourses, setDraftCourses] = useState("0");
  const [learnersInCourse, setLearnersInCourse] = useState("0");
  const [supportIssues, setSupportIssues] = useState("0");
  const [did, setDid] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const fetchCourseData = (uid) => {
    CourseService.getCourse(uid)
      .then((response) => {
        const newData = response.data;
        setCourses(newData);
        loadDatatable();

        setActiveCourses(
          Array.isArray(newData)
            ? newData.filter(
                (course) => course.status === "1" && course.pubsts === "1"
              ).length
            : null
        );

        setInactiveCourses(
          Array.isArray(newData)
            ? newData.filter((course) => course.status === "0").length
            : null
        );

        setDraftCourses(
          Array.isArray(newData)
            ? newData.filter((course) => course.pubsts === "0").length
            : null
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadDatatable = () => {
    if (!$.fn.DataTable.isDataTable("#dtHorizontalExample")) {
      function filterGlobal() {
        $("#dtHorizontalExample")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#dtHorizontalExample")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $.fn.dataTable.ext.search.pop();

      function applyStaffsStatusFilter(status) {
        $.fn.dataTable.ext.search.pop();

        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          var rowData = data[2];

          if (rowData === status) {
            return true;
          }
          return false;
        });

        $("#dtHorizontalExample").DataTable().draw();
      }

      function applyOccupCatFilter(roleName) {
        if (roleName !== "0") {
          $.fn.dataTable.ext.search.pop();

          $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
            var rowData = data[4];

            if (rowData === roleName) {
              return true;
            }
            return false;
          });

          $("#dtHorizontalExample").DataTable().draw();
        } else {
          $.fn.dataTable.ext.search.pop();
          $("#dtHorizontalExample").DataTable().draw();
        }
      }

      function applyCourseCatFilter(roleName) {
        if (roleName !== "0") {
          $.fn.dataTable.ext.search.pop();

          $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
            var rowData = data[5];

            if (rowData === roleName) {
              return true;
            }
            return false;
          });

          $("#dtHorizontalExample").DataTable().draw();
        } else {
          $.fn.dataTable.ext.search.pop();
          $("#dtHorizontalExample").DataTable().draw();
        }
      }

      function compareDates() {
        $.fn.dataTable.ext.search.pop();

        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          var minDate = $("#minDate").val();
          var maxDate = $("#maxDate").val();

          let date = data[3];

          if (minDate === date || (minDate <= date && date <= maxDate)) {
            return true;
          }
          return false;
        });

        $("#dtHorizontalExample").DataTable().draw();

        if (!$(this).val()) {
          $.fn.dataTable.ext.search.pop();
        }
      }

      $("#activeCourses").on("click", function () {
        applyStaffsStatusFilter("1");
      });

      $("#inactiveCourses").on("click", function () {
        applyStaffsStatusFilter("0");
      });

      $("#draftCourses").on("click", function () {
        applyStaffsStatusFilter("2");
      });

      $("#allCourses").on("click", function () {
        $.fn.dataTable.ext.search.pop();
        $("#dtHorizontalExample").DataTable().draw();
      });

      $("#minDate, #maxDate").on("change", function () {
        compareDates();
      });

      $("#filterOccupCat").on("change", function () {
        var selectedValue = $(this).val();

        applyOccupCatFilter(selectedValue);
      });

      $("#filterCourseCat").on("change", function () {
        var selectedValue = $(this).val();

        applyCourseCatFilter(selectedValue);
      });

      $("#clearFilter").on("click", function () {
        $.fn.dataTable.ext.search.pop();
        $("#dtHorizontalExample").DataTable().draw();
        $("#minDate").val("");
        $("#maxDate").val("");
        $("#filterCourseCat").val("0");
        $("#filterOccupCat").val("0");
      });

      $(document).ready(function () {
        setTimeout(function () {
          $("#dtHorizontalExample").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            select: true,
            bDestroy: true,
            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
        }, 0);
      });
    }
  };

  const getErrorReqData = () => {
    ErrorreqService.getErrorReq()
      .then((responsse) => {
        const errorReqData = responsse.data;

        setSupportIssues(
          errorReqData.filter((errorReqData) => errorReqData.status !== "2")
            .length
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterOccuData = (uid) => {
    CourseService.getCourse(uid)
      .then((response) => {
        const newData = response.data;

        if (selectedOccuCat !== "0") {
          let filterData = Array.isArray(newData)
            ? newData.filter(
                (course) => course.category_name === selectedOccuCat
              )
            : null;
          setCourses(filterData);

          loadDatatable();
        } else {
          setCourses(newData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterCourseData = (uid) => {
    CourseService.getCourse(uid)
      .then((response) => {
        const newData = response.data;

        if (selectedCourseCat !== "0") {
          let filterData = Array.isArray(newData)
            ? newData.filter(
                (course) => course.course_catename === selectedCourseCat
              )
            : null;
          setCourses(filterData);

          loadDatatable();
        } else {
          setCourses(newData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterCatData = (uid) => {
    CourseService.getCourse(uid)
      .then((response) => {
        const newData = response.data;

        if (selectedOccuCat !== "0" && selectedCourseCat !== "0") {
          let filterData = Array.isArray(newData)
            ? newData.filter(
                (course) =>
                  course.category_name === selectedOccuCat &&
                  course.course_catename === selectedCourseCat
              )
            : null;
          setCourses(filterData);

          loadDatatable();
        } else {
          setCourses(newData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCertificateData = () => {
    const user = AuthService.getCurrentUser();
    CertService.getAllCerts(user.user)
      .then((resp) => {
        setCertificateIssued(resp.data.length);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (userdata) {
      fetchCourseData(userdata.user);
    }

    UserService.getLearners(userdata.user)
      .then((response) => {
        const learnersData = response.data;

        setLearnersInCourse(
          Array.isArray(learnersData)
            ? learnersData.filter((learner) => learner.course_count === "0")
                .length
            : null
        );
      })
      .catch((e) => {
        console.log(e);
      });

    getErrorReqData();
    getCertificateData();
  }, []);

  useEffect(() => {
    let user = AuthService.getCurrentUser();
    if (user) {
      if (selectedOccuCat && !selectedCourseCat) {
        filterOccuData(user.user);
      } else if (selectedCourseCat && !selectedOccuCat) {
        filterCourseData(user.user);
      } else if (selectedOccuCat && selectedCourseCat) {
        filterCatData(user.user);
      } else {
        fetchCourseData(user.user);
      }
    }
  }, [selectedOccuCat, selectedCourseCat]);

  const handleDeleteCourse = () => {
    const user = AuthService.getCurrentUser();

    if (user) {
      const data = {
        usp: user.user,
        corsid: did,
      };

      CourseService.deleteCourse(data)
        .then((resp) => {
          setShow(false);
          toast.success("Course deleted successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          fetchCourseData(user.user);
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="user-status-flex">
        {location.pathname.includes("/Dashboard") ||
        location.pathname === "/" ? (
          <>
            <div className="course-status">
              <span>Draft Courses</span>
              <span>{draftCourses}</span>
            </div>
            <div className="course-status">
              <span>Unassigned Learners</span>
              <span>{learnersInCourse}</span>
            </div>
            <div className="course-status">
              <span>Certificates issued</span>
              <span>{certificateIssued}</span>
            </div>
            <div className="course-status">
              <span>Issues to Solve</span>
              <span>{supportIssues}</span>
            </div>
            {(permissions && permissions.includes("mc2")) ||
            roleid === "1" ||
            roleid === "2" ? (
              <>
                <div className="course-status btn">
                  <Link to="/CreateCourse">
                    <span>Create New Course +</span>
                  </Link>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : location.pathname.includes("/ManageCourses") ? (
          <>
            <div
              className="user-status all"
              id="allCourses"
              title="Filter All Courses"
            >
              <span>All</span>
              <span>{courses.length}</span>
            </div>
            <div
              className="user-status active"
              id="activeCourses"
              title="Filter Active Courses"
            >
              <span>Active</span>
              <span>{activeCourses}</span>
            </div>
            <div
              className="user-status inactive"
              id="inactiveCourses"
              title="Filter Inactive Courses"
            >
              <span>Inactive</span>
              <span>{inactiveCourses}</span>
            </div>
            {(permissions &&
              permissions.includes("mc3") &&
              permissions.includes("mc4")) ||
            roleid === "1" ||
            roleid === "2" ? (
              <>
                <div
                  className="user-status unassigned"
                  id="draftCourses"
                  title="Filter Draft Courses"
                >
                  <span>Drafts</span>
                  <span>{draftCourses}</span>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        className={
          location.pathname.includes("/Dashboard") || location.pathname === "/"
            ? "admin-users courses-list dash-course-list"
            : "admin-users courses-list"
        }
      >
        <div className="table-responsive">
          {location.pathname.includes("/Dashboard") ||
          location.pathname === "/" ? (
            <>
              <table className="table">
                <thead>
                  <th hidden>id</th>
                  <th>Name</th>
                  <th>Pathway</th>
                  <th>Modules</th>
                  {(permissions &&
                    permissions.includes("mc3") &&
                    permissions.includes("mc4")) ||
                  roleid === "1" ||
                  roleid === "2" ? (
                    <>
                      <th
                        style={{ textAlign: "center", width: "50px" }}
                        className="action"
                      >
                        Action
                      </th>
                    </>
                  ) : (
                    <></>
                  )}
                </thead>
                <tbody>
                  {Array.isArray(courses)
                    ? courses.map((course) => (
                        <tr key={course.id}>
                          <td hidden>
                            <span>{course.id}</span>
                          </td>
                          <td>
                            {/* <Link to={"/Course"} state={{ cid: course.id }}> */}
                            <div className="user-flex">
                              <div className="user-pic course-img">
                                {course.course_image ? (
                                  <>
                                    <img
                                      src={
                                        "https://elearning.gaa.aero/cit/" +
                                        course.course_image
                                      }
                                      height="50px"
                                      alt=""
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img src={CourseImg} height="50px" alt="" />
                                  </>
                                )}
                              </div>
                              <div className="user-info">
                                <span className="name">{course.name}</span>
                                <span className="role">{course.email}</span>
                              </div>
                            </div>
                            {/* </Link> */}
                          </td>
                          <td>
                            <td>
                              {course.pathsts === "1" ? (
                                <>
                                  <button
                                    style={{
                                      background: "var(--body-dark-bg)",
                                      color: "rgb(0, 200, 83)",
                                      width: "50px",
                                      height: "25px",
                                      borderRadius: "25px",
                                    }}
                                  >
                                    <span>Yes</span>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    style={{
                                      background: "var(--body-dark-bg)",
                                      color: "rgb(216, 67, 21)",
                                      width: "50px",
                                      height: "25px",
                                      borderRadius: "25px",
                                    }}
                                  >
                                    <span>No</span>
                                  </button>
                                </>
                              )}
                            </td>
                          </td>
                          <td>
                            <span>
                              {" "}
                              {course.activity_count === null
                                ? 0
                                : course.activity_count}
                            </span>
                          </td>
                          <td>
                            <div className="table-act">
                              {course.pubsts === "0" ? (
                                <>
                                  {roleid === "1" || roleid === "2" ? (
                                    <>
                                      <button className="draft">
                                        <Link
                                          to={"/CourseDraft"}
                                          state={{ cid: course.id }}
                                          title="Draft"
                                        >
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="15"
                                              height="15"
                                              viewBox="0 0 128.229 132.34"
                                            >
                                              <g
                                                id="pencil_1_"
                                                data-name="pencil (1)"
                                                transform="translate(-63.048 -63.438)"
                                              >
                                                <g
                                                  id="Group_11718"
                                                  data-name="Group 11718"
                                                  transform="translate(63.048 63.437)"
                                                >
                                                  <path
                                                    id="Path_14333"
                                                    data-name="Path 14333"
                                                    d="M67.866,63.458q34.334-.083,68.668.167,5.853,2.451,4.833,8.834a7.55,7.55,0,0,1-5.167,5.167q-33.834.334-67.668,0-6.8-2.35-5.167-9.5A8.435,8.435,0,0,1,67.866,63.458Z"
                                                    transform="translate(-63.048 -63.437)"
                                                    fillRule="evenodd"
                                                    opacity="0.986"
                                                  />
                                                </g>
                                                <g
                                                  id="Group_11719"
                                                  data-name="Group 11719"
                                                  transform="translate(144.201 84.76)"
                                                >
                                                  <path
                                                    id="Path_14334"
                                                    data-name="Path 14334"
                                                    d="M321.834,127.436q20.935-.789,29.834,18a29.6,29.6,0,0,1-3.333,27.334l-1.667,1.667q-20.417-19.75-40.167-40.167A28.394,28.394,0,0,1,321.834,127.436Z"
                                                    transform="translate(-306.5 -127.405)"
                                                    fillRule="evenodd"
                                                    opacity="0.977"
                                                  />
                                                </g>
                                                <g
                                                  id="Group_11720"
                                                  data-name="Group 11720"
                                                  transform="translate(63.185 91.771)"
                                                >
                                                  <path
                                                    id="Path_14335"
                                                    data-name="Path 14335"
                                                    d="M68.806,148.458q23.168-.084,46.334.167,6.248,2.242,5.167,8.834a6.461,6.461,0,0,1-4.833,5.167q-23.5.334-47,0a6.877,6.877,0,0,1-5-7,6.72,6.72,0,0,1,2-5.333A24.82,24.82,0,0,1,68.806,148.458Z"
                                                    transform="translate(-63.458 -148.437)"
                                                    fillRule="evenodd"
                                                    opacity="0.983"
                                                  />
                                                </g>
                                                <g
                                                  id="Group_11721"
                                                  data-name="Group 11721"
                                                  transform="translate(80.199 101.459)"
                                                >
                                                  <path
                                                    id="Path_14336"
                                                    data-name="Path 14336"
                                                    d="M168.167,177.5q20.4,20.15,40.668,40.5l-47.668,47.668a10.646,10.646,0,0,1-4,1.333L124.5,271.669q-11.294,1.374-9.834-9.834l4.667-32.667a10.647,10.647,0,0,1,1.333-4Q144.517,201.4,168.167,177.5Z"
                                                    transform="translate(-114.499 -177.5)"
                                                    fillRule="evenodd"
                                                    opacity="0.99"
                                                  />
                                                </g>
                                                <g
                                                  id="Group_11722"
                                                  data-name="Group 11722"
                                                  transform="translate(63.122 120.073)"
                                                >
                                                  <path
                                                    id="Path_14337"
                                                    data-name="Path 14337"
                                                    d="M69.012,233.395a177.5,177.5,0,0,1,18.334.5q5.957,3,4.167,9.5a8.031,8.031,0,0,1-5.5,4.5q-8.5.333-17,0-6.927-2.266-5.5-9.5A8.225,8.225,0,0,1,69.012,233.395Z"
                                                    transform="translate(-63.268 -233.342)"
                                                    fillRule="evenodd"
                                                    opacity="0.959"
                                                  />
                                                </g>
                                              </g>
                                            </svg>
                                          </span>
                                        </Link>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {(permissions &&
                                    permissions.includes("mc3")) ||
                                  roleid === "1" ||
                                  roleid === "2" ? (
                                    <>
                                      <button
                                        className={
                                          course.student_count > 0 &&
                                          roleid !== "1"
                                            ? "edit disabled"
                                            : "edit"
                                        }
                                        disabled={
                                          course.student_count > 0 &&
                                          roleid !== "1"
                                            ? true
                                            : false
                                        }
                                      >
                                        {course.student_count > 0 &&
                                        roleid !== "1" ? (
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="15"
                                              height="15"
                                              viewBox="0 0 22.58 22.583"
                                            >
                                              <g
                                                id="Group_11449"
                                                data-name="Group 11449"
                                                transform="translate(-2402.326 -6200.833)"
                                              >
                                                <path
                                                  id="Path_11890"
                                                  data-name="Path 11890"
                                                  d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                  transform="translate(752.656 5510.995)"
                                                  fill="none"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="2"
                                                />
                                                <path
                                                  id="Path_11892"
                                                  data-name="Path 11892"
                                                  d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                  transform="translate(751.921 5511.583)"
                                                />
                                              </g>
                                            </svg>
                                          </span>
                                        ) : (
                                          <Link
                                            to={
                                              roleid === "1"
                                                ? "/CourseDraft"
                                                : "/EditCourse"
                                            }
                                            state={{ cid: course.id }}
                                          >
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 22.58 22.583"
                                              >
                                                <g
                                                  id="Group_11449"
                                                  data-name="Group 11449"
                                                  transform="translate(-2402.326 -6200.833)"
                                                >
                                                  <path
                                                    id="Path_11890"
                                                    data-name="Path 11890"
                                                    d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                    transform="translate(752.656 5510.995)"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                  />
                                                  <path
                                                    id="Path_11892"
                                                    data-name="Path 11892"
                                                    d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                    transform="translate(751.921 5511.583)"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </Link>
                                        )}
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {(permissions &&
                                    permissions.includes("mc4")) ||
                                  roleid === "1" ||
                                  roleid === "2" ? (
                                    <>
                                      <button
                                        onClick={
                                          course.student_count === null ||
                                          roleid === "1"
                                            ? handleShow
                                            : null
                                        }
                                        className={
                                          course.student_count > 0 &&
                                          roleid !== "1"
                                            ? "delete disabled"
                                            : "delete"
                                        }
                                        title="Delete Course"
                                        data-bs-target={course.id}
                                        disabled={
                                          course.student_count > 0 &&
                                          roleid !== "1"
                                            ? true
                                            : false
                                        }
                                      >
                                        <span>
                                          <svg
                                            id="delete"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="15"
                                            viewBox="0 0 16.079 20.664"
                                          >
                                            <path
                                              id="Path_13633"
                                              data-name="Path 13633"
                                              d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                              transform="translate(-57 512)"
                                            />
                                            <path
                                              id="Path_13634"
                                              data-name="Path 13634"
                                              d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                              transform="translate(-83.869 402.606)"
                                            />
                                          </svg>
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {(permissions &&
                                    permissions.includes("mc5")) ||
                                  roleid === "1" ||
                                  roleid === "2" ? (
                                    <>
                                      <button className="manage-learners">
                                        <Link
                                          to={"/ManageParticipants"}
                                          state={{ cid: course.id }}
                                          title="Manage Participants"
                                        >
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="15"
                                              viewBox="0 0 164.427 134.024"
                                            >
                                              <g
                                                id="user-_3_"
                                                transform="translate(-56.783 418.477)"
                                              >
                                                <path
                                                  id="Path_14330"
                                                  data-name="Path 14330"
                                                  d="M150.651-418.239a28.85,28.85,0,0,0-23.3,19.961,32.523,32.523,0,0,0-.66,14.228,29.2,29.2,0,0,0,7.63,14.022c5.526,5.568,11.465,8.248,19.012,8.537a27.048,27.048,0,0,0,18.435-5.485,29.885,29.885,0,0,0,10.681-15.837,32.241,32.241,0,0,0-.206-14.847,26.661,26.661,0,0,0-7.465-12.414,26.885,26.885,0,0,0-18.435-8.372A35.585,35.585,0,0,0,150.651-418.239Z"
                                                  transform="translate(-40.783)"
                                                />
                                                <path
                                                  id="Path_14331"
                                                  data-name="Path 14331"
                                                  d="M293.127-299.34c-.371.454-.66,2.227-.784,5.031l-.206,4.289-2.474.907a35.358,35.358,0,0,0-10.022,5.774,29.492,29.492,0,0,1-2.392,1.9c-.124,0-1.691-.866-3.505-1.9-3.588-2.062-4.99-2.351-6.145-1.155-1.443,1.567-6.6,11.011-6.6,12.166,0,1.443.536,1.98,4.372,4.165,2.969,1.691,3.093,1.815,2.887,3.011a53.131,53.131,0,0,0-.247,13.28,8.471,8.471,0,0,1,.165,3.3c-.206.082-1.856.99-3.67,2.1-4.5,2.639-4.5,2.969-.577,9.9,1.65,2.887,3.258,5.568,3.588,5.939.99,1.2,2.639.866,6.1-1.113a23.048,23.048,0,0,1,3.712-1.856,3.284,3.284,0,0,1,1.4,1.031c2.062,2.186,8.248,5.732,11.877,6.846l1.732.495v4.289c0,6.062-.247,5.9,8.661,5.9s8.661.165,8.661-5.9v-4.207l2.8-.99a34.094,34.094,0,0,0,10.063-5.774,8.217,8.217,0,0,1,2.309-1.691,23.946,23.946,0,0,1,3.629,1.856c3.505,1.98,5.031,2.268,6.021,1.113a123.187,123.187,0,0,0,6.434-11.259c.66-1.65-.165-2.722-3.835-4.866l-3.3-1.938.206-2.062a88.761,88.761,0,0,0-.124-13.774l-.33-1.856,3.464-2.021c4-2.309,4.784-3.341,3.835-5.238-1.361-2.763-6.1-10.64-6.681-11.094-1.031-.907-2.433-.536-5.9,1.443-2.928,1.691-3.464,1.856-4.083,1.4-.371-.33-1.526-1.237-2.557-2.062a35.177,35.177,0,0,0-9.238-5.155l-2.516-.907-.206-4.289c-.124-2.8-.412-4.578-.784-5.031-.495-.577-1.567-.66-7.877-.66S293.622-299.918,293.127-299.34Zm13.857,29.529a14.485,14.485,0,0,1,6.351,6.516,15.492,15.492,0,0,1,.165,11.218,15.229,15.229,0,0,1-6.681,6.8c-1.98.99-2.8,1.155-5.815,1.155s-3.835-.165-5.815-1.155a15.229,15.229,0,0,1-6.681-6.8,15.492,15.492,0,0,1,.165-11.218,14.48,14.48,0,0,1,8.619-7.465,17.309,17.309,0,0,1,4.33-.206A10.891,10.891,0,0,1,306.984-269.812Z"
                                                  transform="translate(-119.996 -69.616)"
                                                />
                                                <path
                                                  id="Path_14332"
                                                  data-name="Path 14332"
                                                  d="M100.011-251.182a57.317,57.317,0,0,0-20.249,8.331,64.033,64.033,0,0,0-13.115,12.785,52.512,52.512,0,0,0-9.857,29.611c-.041,3.629.082,4.454.99,6.392a13.521,13.521,0,0,0,6.516,6.516l2.268,1.072h47.427c36.54,0,47.675-.124,48.582-.495.949-.454,1.113-.742,1.113-2.186,0-1.567-.082-1.691-2.145-2.722-1.2-.619-2.8-1.526-3.629-2.062l-1.526-1.031L154.16-193.9a10.722,10.722,0,0,1-9.815-.247,12.833,12.833,0,0,1-3.505-2.557,144.325,144.325,0,0,1-7.753-13.4,12.076,12.076,0,0,1,0-8.826c.866-1.98,3.712-5.073,5.073-5.485.7-.247.784-.7.784-4.7,0-4.372,0-4.454-1.072-5.031a13.691,13.691,0,0,1-5.155-6.434,12.7,12.7,0,0,1,.247-7.671c.742-1.773.949-1.608-3.3-2.639C125.539-251.842,104.712-252.048,100.011-251.182Z"
                                                  transform="translate(0 -97.978)"
                                                />
                                              </g>
                                            </svg>
                                          </span>
                                        </Link>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {(permissions &&
                                    permissions.includes("mc3")) ||
                                  roleid === "1" ||
                                  roleid === "2" ? (
                                    <>
                                      <button
                                        className={
                                          course.student_count > 0 &&
                                          roleid !== "1"
                                            ? "edit disabled"
                                            : "edit"
                                        }
                                        disabled={
                                          course.student_count > 0 &&
                                          roleid !== "1"
                                            ? true
                                            : false
                                        }
                                      >
                                        {course.student_count > 0 &&
                                        roleid !== "1" ? (
                                          <span>
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 22.58 22.583"
                                              >
                                                <g
                                                  id="Group_11449"
                                                  data-name="Group 11449"
                                                  transform="translate(-2402.326 -6200.833)"
                                                >
                                                  <path
                                                    id="Path_11890"
                                                    data-name="Path 11890"
                                                    d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                    transform="translate(752.656 5510.995)"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                  />
                                                  <path
                                                    id="Path_11892"
                                                    data-name="Path 11892"
                                                    d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                    transform="translate(751.921 5511.583)"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </span>
                                        ) : (
                                          <Link
                                            to={
                                              roleid === "1"
                                                ? "/CourseDraft"
                                                : "/EditCourse"
                                            }
                                            state={{ cid: course.id }}
                                          >
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 22.58 22.583"
                                              >
                                                <g
                                                  id="Group_11449"
                                                  data-name="Group 11449"
                                                  transform="translate(-2402.326 -6200.833)"
                                                >
                                                  <path
                                                    id="Path_11890"
                                                    data-name="Path 11890"
                                                    d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                    transform="translate(752.656 5510.995)"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                  />
                                                  <path
                                                    id="Path_11892"
                                                    data-name="Path 11892"
                                                    d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                    transform="translate(751.921 5511.583)"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </Link>
                                        )}
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {(permissions &&
                                    permissions.includes("mc4")) ||
                                  roleid === "1" ||
                                  roleid === "2" ? (
                                    <>
                                      <button
                                        onClick={
                                          course.student_count === null &&
                                          roleid === "1"
                                            ? handleShow
                                            : null
                                        }
                                        className={
                                          course.student_count > 0 &&
                                          roleid !== "1"
                                            ? "delete disabled"
                                            : "delete"
                                        }
                                        title="Delete Course"
                                        data-bs-target={course.id}
                                      >
                                        <span>
                                          <svg
                                            id="delete"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="15"
                                            viewBox="0 0 16.079 20.664"
                                          >
                                            <path
                                              id="Path_13633"
                                              data-name="Path 13633"
                                              d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                              transform="translate(-57 512)"
                                            />
                                            <path
                                              id="Path_13634"
                                              data-name="Path 13634"
                                              d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                              transform="translate(-83.869 402.606)"
                                            />
                                          </svg>
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table className="table" id="dtHorizontalExample">
                <thead>
                  <tr>
                    <th hidden>id</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th hidden>Date</th>
                    <th hidden>Occup Cat</th>
                    <th hidden>Course Cat</th>
                    <th>Pathway</th>
                    <th>Modules</th>
                    <th>Estore</th>
                    <th>Learners</th>
                    {(permissions &&
                      permissions.includes("mc3") &&
                      permissions.includes("mc4")) ||
                    roleid === "1" ||
                    roleid === "2" ? (
                      <>
                        <th
                          style={{ textAlign: "center", width: "50px" }}
                          className="action"
                        >
                          Action
                        </th>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(courses)
                    ? courses.map((course) => (
                        <tr key={course.id}>
                          {location.pathname.includes("/Dashboard") ? (
                            <>
                              <td hidden>
                                <span>{course.id}</span>
                              </td>
                              <td>
                                {/* <Link to={"/Course"} state={{ cid: course.id }}> */}
                                <div className="user-flex">
                                  <div className="user-pic course-img">
                                    {course.course_image ? (
                                      <>
                                        <img
                                          src={
                                            "https://elearning.gaa.aero/cit/" +
                                            course.course_image
                                          }
                                          height="50px"
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={CourseImg}
                                          height="50px"
                                          alt=""
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className="user-info">
                                    <span className="name">{course.name}</span>
                                    <span className="role">{course.email}</span>
                                  </div>
                                </div>
                                {/* </Link> */}
                              </td>
                              <td>
                                {course.activity_count === null
                                  ? 0
                                  : course.activity_count}
                              </td>
                              {(permissions &&
                                permissions.includes("mc3") &&
                                permissions.includes("mc4")) ||
                              roleid === "1" ||
                              roleid === "2" ? (
                                <>
                                  <td>
                                    <div className="table-act">
                                      {course.pubsts === "0" ? (
                                        <>
                                          {roleid === "1" || roleid === "2" ? (
                                            <>
                                              <button className="draft">
                                                <Link
                                                  to={"/CourseDraft"}
                                                  state={{ cid: course.id }}
                                                  title="Draft"
                                                >
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="15"
                                                      height="15"
                                                      viewBox="0 0 128.229 132.34"
                                                    >
                                                      <g
                                                        id="pencil_1_"
                                                        data-name="pencil (1)"
                                                        transform="translate(-63.048 -63.438)"
                                                      >
                                                        <g
                                                          id="Group_11718"
                                                          data-name="Group 11718"
                                                          transform="translate(63.048 63.437)"
                                                        >
                                                          <path
                                                            id="Path_14333"
                                                            data-name="Path 14333"
                                                            d="M67.866,63.458q34.334-.083,68.668.167,5.853,2.451,4.833,8.834a7.55,7.55,0,0,1-5.167,5.167q-33.834.334-67.668,0-6.8-2.35-5.167-9.5A8.435,8.435,0,0,1,67.866,63.458Z"
                                                            transform="translate(-63.048 -63.437)"
                                                            fillRule="evenodd"
                                                            opacity="0.986"
                                                          />
                                                        </g>
                                                        <g
                                                          id="Group_11719"
                                                          data-name="Group 11719"
                                                          transform="translate(144.201 84.76)"
                                                        >
                                                          <path
                                                            id="Path_14334"
                                                            data-name="Path 14334"
                                                            d="M321.834,127.436q20.935-.789,29.834,18a29.6,29.6,0,0,1-3.333,27.334l-1.667,1.667q-20.417-19.75-40.167-40.167A28.394,28.394,0,0,1,321.834,127.436Z"
                                                            transform="translate(-306.5 -127.405)"
                                                            fillRule="evenodd"
                                                            opacity="0.977"
                                                          />
                                                        </g>
                                                        <g
                                                          id="Group_11720"
                                                          data-name="Group 11720"
                                                          transform="translate(63.185 91.771)"
                                                        >
                                                          <path
                                                            id="Path_14335"
                                                            data-name="Path 14335"
                                                            d="M68.806,148.458q23.168-.084,46.334.167,6.248,2.242,5.167,8.834a6.461,6.461,0,0,1-4.833,5.167q-23.5.334-47,0a6.877,6.877,0,0,1-5-7,6.72,6.72,0,0,1,2-5.333A24.82,24.82,0,0,1,68.806,148.458Z"
                                                            transform="translate(-63.458 -148.437)"
                                                            fillRule="evenodd"
                                                            opacity="0.983"
                                                          />
                                                        </g>
                                                        <g
                                                          id="Group_11721"
                                                          data-name="Group 11721"
                                                          transform="translate(80.199 101.459)"
                                                        >
                                                          <path
                                                            id="Path_14336"
                                                            data-name="Path 14336"
                                                            d="M168.167,177.5q20.4,20.15,40.668,40.5l-47.668,47.668a10.646,10.646,0,0,1-4,1.333L124.5,271.669q-11.294,1.374-9.834-9.834l4.667-32.667a10.647,10.647,0,0,1,1.333-4Q144.517,201.4,168.167,177.5Z"
                                                            transform="translate(-114.499 -177.5)"
                                                            fillRule="evenodd"
                                                            opacity="0.99"
                                                          />
                                                        </g>
                                                        <g
                                                          id="Group_11722"
                                                          data-name="Group 11722"
                                                          transform="translate(63.122 120.073)"
                                                        >
                                                          <path
                                                            id="Path_14337"
                                                            data-name="Path 14337"
                                                            d="M69.012,233.395a177.5,177.5,0,0,1,18.334.5q5.957,3,4.167,9.5a8.031,8.031,0,0,1-5.5,4.5q-8.5.333-17,0-6.927-2.266-5.5-9.5A8.225,8.225,0,0,1,69.012,233.395Z"
                                                            transform="translate(-63.268 -233.342)"
                                                            fillRule="evenodd"
                                                            opacity="0.959"
                                                          />
                                                        </g>
                                                      </g>
                                                    </svg>
                                                  </span>
                                                </Link>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {(permissions &&
                                            permissions.includes("mc3")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button
                                                className={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? "edit disabled"
                                                    : "edit"
                                                }
                                                disabled={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {course.student_count > 0 &&
                                                roleid !== "1" ? (
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="15"
                                                      height="15"
                                                      viewBox="0 0 22.58 22.583"
                                                    >
                                                      <g
                                                        id="Group_11449"
                                                        data-name="Group 11449"
                                                        transform="translate(-2402.326 -6200.833)"
                                                      >
                                                        <path
                                                          id="Path_11890"
                                                          data-name="Path 11890"
                                                          d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                          transform="translate(752.656 5510.995)"
                                                          fill="none"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        />
                                                        <path
                                                          id="Path_11892"
                                                          data-name="Path 11892"
                                                          d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                          transform="translate(751.921 5511.583)"
                                                        />
                                                      </g>
                                                    </svg>
                                                  </span>
                                                ) : (
                                                  <Link
                                                    to={
                                                      roleid === "1"
                                                        ? "/CourseDraft"
                                                        : "/EditCourse"
                                                    }
                                                    state={{ cid: course.id }}
                                                  >
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="15"
                                                        height="15"
                                                        viewBox="0 0 22.58 22.583"
                                                      >
                                                        <g
                                                          id="Group_11449"
                                                          data-name="Group 11449"
                                                          transform="translate(-2402.326 -6200.833)"
                                                        >
                                                          <path
                                                            id="Path_11890"
                                                            data-name="Path 11890"
                                                            d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                            transform="translate(752.656 5510.995)"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                          />
                                                          <path
                                                            id="Path_11892"
                                                            data-name="Path 11892"
                                                            d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                            transform="translate(751.921 5511.583)"
                                                          />
                                                        </g>
                                                      </svg>
                                                    </span>
                                                  </Link>
                                                )}
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {(permissions &&
                                            permissions.includes("mc4")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button
                                                onClick={
                                                  course.student_count ===
                                                    null || roleid === "1"
                                                    ? handleShow
                                                    : null
                                                }
                                                className={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? "delete disabled"
                                                    : "delete"
                                                }
                                                title="Delete Course"
                                                data-bs-target={course.id}
                                              >
                                                <span>
                                                  <svg
                                                    id="delete"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="15"
                                                    viewBox="0 0 16.079 20.664"
                                                  >
                                                    <path
                                                      id="Path_13633"
                                                      data-name="Path 13633"
                                                      d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                                      transform="translate(-57 512)"
                                                    />
                                                    <path
                                                      id="Path_13634"
                                                      data-name="Path 13634"
                                                      d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                                      transform="translate(-83.869 402.606)"
                                                    />
                                                  </svg>
                                                </span>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {(permissions &&
                                            permissions.includes("mc5")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button className="manage-learners">
                                                <Link
                                                  to={"/ManageParticipants"}
                                                  state={{ cid: course.id }}
                                                  title="Manage Participants"
                                                >
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="20"
                                                      height="15"
                                                      viewBox="0 0 164.427 134.024"
                                                    >
                                                      <g
                                                        id="user-_3_"
                                                        transform="translate(-56.783 418.477)"
                                                      >
                                                        <path
                                                          id="Path_14330"
                                                          data-name="Path 14330"
                                                          d="M150.651-418.239a28.85,28.85,0,0,0-23.3,19.961,32.523,32.523,0,0,0-.66,14.228,29.2,29.2,0,0,0,7.63,14.022c5.526,5.568,11.465,8.248,19.012,8.537a27.048,27.048,0,0,0,18.435-5.485,29.885,29.885,0,0,0,10.681-15.837,32.241,32.241,0,0,0-.206-14.847,26.661,26.661,0,0,0-7.465-12.414,26.885,26.885,0,0,0-18.435-8.372A35.585,35.585,0,0,0,150.651-418.239Z"
                                                          transform="translate(-40.783)"
                                                        />
                                                        <path
                                                          id="Path_14331"
                                                          data-name="Path 14331"
                                                          d="M293.127-299.34c-.371.454-.66,2.227-.784,5.031l-.206,4.289-2.474.907a35.358,35.358,0,0,0-10.022,5.774,29.492,29.492,0,0,1-2.392,1.9c-.124,0-1.691-.866-3.505-1.9-3.588-2.062-4.99-2.351-6.145-1.155-1.443,1.567-6.6,11.011-6.6,12.166,0,1.443.536,1.98,4.372,4.165,2.969,1.691,3.093,1.815,2.887,3.011a53.131,53.131,0,0,0-.247,13.28,8.471,8.471,0,0,1,.165,3.3c-.206.082-1.856.99-3.67,2.1-4.5,2.639-4.5,2.969-.577,9.9,1.65,2.887,3.258,5.568,3.588,5.939.99,1.2,2.639.866,6.1-1.113a23.048,23.048,0,0,1,3.712-1.856,3.284,3.284,0,0,1,1.4,1.031c2.062,2.186,8.248,5.732,11.877,6.846l1.732.495v4.289c0,6.062-.247,5.9,8.661,5.9s8.661.165,8.661-5.9v-4.207l2.8-.99a34.094,34.094,0,0,0,10.063-5.774,8.217,8.217,0,0,1,2.309-1.691,23.946,23.946,0,0,1,3.629,1.856c3.505,1.98,5.031,2.268,6.021,1.113a123.187,123.187,0,0,0,6.434-11.259c.66-1.65-.165-2.722-3.835-4.866l-3.3-1.938.206-2.062a88.761,88.761,0,0,0-.124-13.774l-.33-1.856,3.464-2.021c4-2.309,4.784-3.341,3.835-5.238-1.361-2.763-6.1-10.64-6.681-11.094-1.031-.907-2.433-.536-5.9,1.443-2.928,1.691-3.464,1.856-4.083,1.4-.371-.33-1.526-1.237-2.557-2.062a35.177,35.177,0,0,0-9.238-5.155l-2.516-.907-.206-4.289c-.124-2.8-.412-4.578-.784-5.031-.495-.577-1.567-.66-7.877-.66S293.622-299.918,293.127-299.34Zm13.857,29.529a14.485,14.485,0,0,1,6.351,6.516,15.492,15.492,0,0,1,.165,11.218,15.229,15.229,0,0,1-6.681,6.8c-1.98.99-2.8,1.155-5.815,1.155s-3.835-.165-5.815-1.155a15.229,15.229,0,0,1-6.681-6.8,15.492,15.492,0,0,1,.165-11.218,14.48,14.48,0,0,1,8.619-7.465,17.309,17.309,0,0,1,4.33-.206A10.891,10.891,0,0,1,306.984-269.812Z"
                                                          transform="translate(-119.996 -69.616)"
                                                        />
                                                        <path
                                                          id="Path_14332"
                                                          data-name="Path 14332"
                                                          d="M100.011-251.182a57.317,57.317,0,0,0-20.249,8.331,64.033,64.033,0,0,0-13.115,12.785,52.512,52.512,0,0,0-9.857,29.611c-.041,3.629.082,4.454.99,6.392a13.521,13.521,0,0,0,6.516,6.516l2.268,1.072h47.427c36.54,0,47.675-.124,48.582-.495.949-.454,1.113-.742,1.113-2.186,0-1.567-.082-1.691-2.145-2.722-1.2-.619-2.8-1.526-3.629-2.062l-1.526-1.031L154.16-193.9a10.722,10.722,0,0,1-9.815-.247,12.833,12.833,0,0,1-3.505-2.557,144.325,144.325,0,0,1-7.753-13.4,12.076,12.076,0,0,1,0-8.826c.866-1.98,3.712-5.073,5.073-5.485.7-.247.784-.7.784-4.7,0-4.372,0-4.454-1.072-5.031a13.691,13.691,0,0,1-5.155-6.434,12.7,12.7,0,0,1,.247-7.671c.742-1.773.949-1.608-3.3-2.639C125.539-251.842,104.712-252.048,100.011-251.182Z"
                                                          transform="translate(0 -97.978)"
                                                        />
                                                      </g>
                                                    </svg>
                                                  </span>
                                                </Link>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {(permissions &&
                                            permissions.includes("mc3")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button
                                                className={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? "edit disabled"
                                                    : "edit"
                                                }
                                                disabled={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {course.student_count > 0 &&
                                                roleid !== "1" ? (
                                                  <span>
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="15"
                                                        height="15"
                                                        viewBox="0 0 22.58 22.583"
                                                      >
                                                        <g
                                                          id="Group_11449"
                                                          data-name="Group 11449"
                                                          transform="translate(-2402.326 -6200.833)"
                                                        >
                                                          <path
                                                            id="Path_11890"
                                                            data-name="Path 11890"
                                                            d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                            transform="translate(752.656 5510.995)"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                          />
                                                          <path
                                                            id="Path_11892"
                                                            data-name="Path 11892"
                                                            d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                            transform="translate(751.921 5511.583)"
                                                          />
                                                        </g>
                                                      </svg>
                                                    </span>
                                                  </span>
                                                ) : (
                                                  <Link
                                                    to={
                                                      roleid === "1"
                                                        ? "/CourseDraft"
                                                        : "/EditCourse"
                                                    }
                                                    state={{ cid: course.id }}
                                                  >
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="15"
                                                        height="15"
                                                        viewBox="0 0 22.58 22.583"
                                                      >
                                                        <g
                                                          id="Group_11449"
                                                          data-name="Group 11449"
                                                          transform="translate(-2402.326 -6200.833)"
                                                        >
                                                          <path
                                                            id="Path_11890"
                                                            data-name="Path 11890"
                                                            d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                            transform="translate(752.656 5510.995)"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                          />
                                                          <path
                                                            id="Path_11892"
                                                            data-name="Path 11892"
                                                            d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                            transform="translate(751.921 5511.583)"
                                                          />
                                                        </g>
                                                      </svg>
                                                    </span>
                                                  </Link>
                                                )}
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {(permissions &&
                                            permissions.includes("mc4")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button
                                                onClick={
                                                  course.student_count > 0 ||
                                                  roleid === "1"
                                                    ? handleShow
                                                    : null
                                                }
                                                className={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? "delete disabled"
                                                    : "delete"
                                                }
                                                title="Delete Course"
                                                data-bs-target={course.id}
                                              >
                                                <span>
                                                  <svg
                                                    id="delete"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="15"
                                                    viewBox="0 0 16.079 20.664"
                                                  >
                                                    <path
                                                      id="Path_13633"
                                                      data-name="Path 13633"
                                                      d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                                      transform="translate(-57 512)"
                                                    />
                                                    <path
                                                      id="Path_13634"
                                                      data-name="Path 13634"
                                                      d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                                      transform="translate(-83.869 402.606)"
                                                    />
                                                  </svg>
                                                </span>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              <td hidden>
                                <span>{course.id}</span>
                              </td>
                              <td>
                                <div className="user-flex">
                                  <div className="user-pic course-img">
                                    {course.course_image ? (
                                      <>
                                        <img
                                          src={
                                            "https://elearning.gaa.aero/cit/" +
                                            course.course_image
                                          }
                                          height="50px"
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={CourseImg}
                                          height="50px"
                                          alt=""
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className="user-info">
                                    <span className="name">{course.name}</span>
                                    <span className="role">{course.email}</span>
                                  </div>
                                </div>
                                {/* </Link> */}
                              </td>
                              <td>
                                <div
                                  className={
                                    course.status === "1" &&
                                    course.pubsts === "1"
                                      ? "user-status cstatus active"
                                      : course.status === "1" ||
                                        course.pubsts === "0"
                                      ? "user-status cstatus draft"
                                      : "user-status cstatus inactive"
                                  }
                                >
                                  <span>
                                    {course.pubsts !== "1"
                                      ? "2"
                                      : course.status}
                                  </span>
                                </div>
                              </td>
                              <td hidden>{course.start_date}</td>
                              <td hidden>{course.category_name}</td>
                              <td hidden>{course.category_id}</td>
                              <td>
                                {course.pathsts === "1" ? (
                                  <>
                                    <button
                                      style={{
                                        background: "var(--body-dark-bg)",
                                        color: "rgb(0, 200, 83)",
                                        width: "50px",
                                        height: "25px",
                                        borderRadius: "25px",
                                      }}
                                    >
                                      <span>Yes</span>
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      style={{
                                        background: "var(--body-dark-bg)",
                                        color: "rgb(216, 67, 21)",
                                        width: "50px",
                                        height: "25px",
                                        borderRadius: "25px",
                                      }}
                                    >
                                      <span>No</span>
                                    </button>
                                  </>
                                )}
                              </td>
                              <td>
                                <span>
                                  {course.activity_count === null
                                    ? 0
                                    : course.activity_count}
                                </span>
                              </td>
                              <td>
                                {course.estore === "1" ? (
                                  <>
                                    <button
                                      style={{
                                        background: "var(--body-dark-bg)",
                                        color: "rgb(0, 200, 83)",
                                        width: "50px",
                                        height: "25px",
                                        borderRadius: "25px",
                                      }}
                                    >
                                      <span>Yes</span>
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      style={{
                                        background: "var(--body-dark-bg)",
                                        color: "rgb(216, 67, 21)",
                                        width: "50px",
                                        height: "25px",
                                        borderRadius: "25px",
                                      }}
                                    >
                                      <span>No</span>
                                    </button>
                                  </>
                                )}
                              </td>
                              <td>
                                <span>
                                  {course.student_count === null
                                    ? 0
                                    : course.student_count}
                                </span>
                              </td>
                              {(permissions &&
                                permissions.includes("mc3") &&
                                permissions.includes("mc4")) ||
                              roleid === "1" ||
                              roleid === "2" ? (
                                <>
                                  <td>
                                    <div className="table-act">
                                      {course.pubsts === "0" ? (
                                        <>
                                          {roleid === "1" || roleid === "2" ? (
                                            <>
                                              <button className="draft">
                                                <Link
                                                  to={"/CourseDraft"}
                                                  state={{ cid: course.id }}
                                                  title="Draft"
                                                >
                                                  {" "}
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="15"
                                                      height="15"
                                                      viewBox="0 0 128.229 132.34"
                                                    >
                                                      <g
                                                        id="pencil_1_"
                                                        data-name="pencil (1)"
                                                        transform="translate(-63.048 -63.438)"
                                                      >
                                                        <g
                                                          id="Group_11718"
                                                          data-name="Group 11718"
                                                          transform="translate(63.048 63.437)"
                                                        >
                                                          <path
                                                            id="Path_14333"
                                                            data-name="Path 14333"
                                                            d="M67.866,63.458q34.334-.083,68.668.167,5.853,2.451,4.833,8.834a7.55,7.55,0,0,1-5.167,5.167q-33.834.334-67.668,0-6.8-2.35-5.167-9.5A8.435,8.435,0,0,1,67.866,63.458Z"
                                                            transform="translate(-63.048 -63.437)"
                                                            fillRule="evenodd"
                                                            opacity="0.986"
                                                          />
                                                        </g>
                                                        <g
                                                          id="Group_11719"
                                                          data-name="Group 11719"
                                                          transform="translate(144.201 84.76)"
                                                        >
                                                          <path
                                                            id="Path_14334"
                                                            data-name="Path 14334"
                                                            d="M321.834,127.436q20.935-.789,29.834,18a29.6,29.6,0,0,1-3.333,27.334l-1.667,1.667q-20.417-19.75-40.167-40.167A28.394,28.394,0,0,1,321.834,127.436Z"
                                                            transform="translate(-306.5 -127.405)"
                                                            fillRule="evenodd"
                                                            opacity="0.977"
                                                          />
                                                        </g>
                                                        <g
                                                          id="Group_11720"
                                                          data-name="Group 11720"
                                                          transform="translate(63.185 91.771)"
                                                        >
                                                          <path
                                                            id="Path_14335"
                                                            data-name="Path 14335"
                                                            d="M68.806,148.458q23.168-.084,46.334.167,6.248,2.242,5.167,8.834a6.461,6.461,0,0,1-4.833,5.167q-23.5.334-47,0a6.877,6.877,0,0,1-5-7,6.72,6.72,0,0,1,2-5.333A24.82,24.82,0,0,1,68.806,148.458Z"
                                                            transform="translate(-63.458 -148.437)"
                                                            fillRule="evenodd"
                                                            opacity="0.983"
                                                          />
                                                        </g>
                                                        <g
                                                          id="Group_11721"
                                                          data-name="Group 11721"
                                                          transform="translate(80.199 101.459)"
                                                        >
                                                          <path
                                                            id="Path_14336"
                                                            data-name="Path 14336"
                                                            d="M168.167,177.5q20.4,20.15,40.668,40.5l-47.668,47.668a10.646,10.646,0,0,1-4,1.333L124.5,271.669q-11.294,1.374-9.834-9.834l4.667-32.667a10.647,10.647,0,0,1,1.333-4Q144.517,201.4,168.167,177.5Z"
                                                            transform="translate(-114.499 -177.5)"
                                                            fillRule="evenodd"
                                                            opacity="0.99"
                                                          />
                                                        </g>
                                                        <g
                                                          id="Group_11722"
                                                          data-name="Group 11722"
                                                          transform="translate(63.122 120.073)"
                                                        >
                                                          <path
                                                            id="Path_14337"
                                                            data-name="Path 14337"
                                                            d="M69.012,233.395a177.5,177.5,0,0,1,18.334.5q5.957,3,4.167,9.5a8.031,8.031,0,0,1-5.5,4.5q-8.5.333-17,0-6.927-2.266-5.5-9.5A8.225,8.225,0,0,1,69.012,233.395Z"
                                                            transform="translate(-63.268 -233.342)"
                                                            fillRule="evenodd"
                                                            opacity="0.959"
                                                          />
                                                        </g>
                                                      </g>
                                                    </svg>
                                                  </span>
                                                </Link>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {(permissions &&
                                            permissions.includes("mc3")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button
                                                className={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? "edit disabled"
                                                    : "edit"
                                                }
                                                disabled={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {course.student_count > 0 &&
                                                roleid !== "1" ? (
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="15"
                                                      height="15"
                                                      viewBox="0 0 22.58 22.583"
                                                    >
                                                      <g
                                                        id="Group_11449"
                                                        data-name="Group 11449"
                                                        transform="translate(-2402.326 -6200.833)"
                                                      >
                                                        <path
                                                          id="Path_11890"
                                                          data-name="Path 11890"
                                                          d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                          transform="translate(752.656 5510.995)"
                                                          fill="none"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        />
                                                        <path
                                                          id="Path_11892"
                                                          data-name="Path 11892"
                                                          d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                          transform="translate(751.921 5511.583)"
                                                        />
                                                      </g>
                                                    </svg>
                                                  </span>
                                                ) : (
                                                  <Link
                                                    to={
                                                      roleid === "1"
                                                        ? "/CourseDraft"
                                                        : "/EditCourse"
                                                    }
                                                    state={{ cid: course.id }}
                                                  >
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="15"
                                                        height="15"
                                                        viewBox="0 0 22.58 22.583"
                                                      >
                                                        <g
                                                          id="Group_11449"
                                                          data-name="Group 11449"
                                                          transform="translate(-2402.326 -6200.833)"
                                                        >
                                                          <path
                                                            id="Path_11890"
                                                            data-name="Path 11890"
                                                            d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                            transform="translate(752.656 5510.995)"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                          />
                                                          <path
                                                            id="Path_11892"
                                                            data-name="Path 11892"
                                                            d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                            transform="translate(751.921 5511.583)"
                                                          />
                                                        </g>
                                                      </svg>
                                                    </span>
                                                  </Link>
                                                )}
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {(permissions &&
                                            permissions.includes("mc4")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button
                                                onClick={
                                                  course.student_count ===
                                                    null || roleid === "1"
                                                    ? handleShow
                                                    : null
                                                }
                                                className={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? "delete disabled"
                                                    : "delete"
                                                }
                                                title="Delete Course"
                                                data-bs-target={course.id}
                                              >
                                                <span>
                                                  <svg
                                                    id="delete"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="15"
                                                    viewBox="0 0 16.079 20.664"
                                                  >
                                                    <path
                                                      id="Path_13633"
                                                      data-name="Path 13633"
                                                      d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                                      transform="translate(-57 512)"
                                                    />
                                                    <path
                                                      id="Path_13634"
                                                      data-name="Path 13634"
                                                      d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                                      transform="translate(-83.869 402.606)"
                                                    />
                                                  </svg>
                                                </span>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {(permissions &&
                                            permissions.includes("mc5")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button className="manage-learners">
                                                <Link
                                                  to={"/ManageParticipants"}
                                                  state={{ cid: course.id }}
                                                  title="Manage Participants"
                                                >
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="20"
                                                      height="15"
                                                      viewBox="0 0 164.427 134.024"
                                                    >
                                                      <g
                                                        id="user-_3_"
                                                        transform="translate(-56.783 418.477)"
                                                      >
                                                        <path
                                                          id="Path_14330"
                                                          data-name="Path 14330"
                                                          d="M150.651-418.239a28.85,28.85,0,0,0-23.3,19.961,32.523,32.523,0,0,0-.66,14.228,29.2,29.2,0,0,0,7.63,14.022c5.526,5.568,11.465,8.248,19.012,8.537a27.048,27.048,0,0,0,18.435-5.485,29.885,29.885,0,0,0,10.681-15.837,32.241,32.241,0,0,0-.206-14.847,26.661,26.661,0,0,0-7.465-12.414,26.885,26.885,0,0,0-18.435-8.372A35.585,35.585,0,0,0,150.651-418.239Z"
                                                          transform="translate(-40.783)"
                                                        />
                                                        <path
                                                          id="Path_14331"
                                                          data-name="Path 14331"
                                                          d="M293.127-299.34c-.371.454-.66,2.227-.784,5.031l-.206,4.289-2.474.907a35.358,35.358,0,0,0-10.022,5.774,29.492,29.492,0,0,1-2.392,1.9c-.124,0-1.691-.866-3.505-1.9-3.588-2.062-4.99-2.351-6.145-1.155-1.443,1.567-6.6,11.011-6.6,12.166,0,1.443.536,1.98,4.372,4.165,2.969,1.691,3.093,1.815,2.887,3.011a53.131,53.131,0,0,0-.247,13.28,8.471,8.471,0,0,1,.165,3.3c-.206.082-1.856.99-3.67,2.1-4.5,2.639-4.5,2.969-.577,9.9,1.65,2.887,3.258,5.568,3.588,5.939.99,1.2,2.639.866,6.1-1.113a23.048,23.048,0,0,1,3.712-1.856,3.284,3.284,0,0,1,1.4,1.031c2.062,2.186,8.248,5.732,11.877,6.846l1.732.495v4.289c0,6.062-.247,5.9,8.661,5.9s8.661.165,8.661-5.9v-4.207l2.8-.99a34.094,34.094,0,0,0,10.063-5.774,8.217,8.217,0,0,1,2.309-1.691,23.946,23.946,0,0,1,3.629,1.856c3.505,1.98,5.031,2.268,6.021,1.113a123.187,123.187,0,0,0,6.434-11.259c.66-1.65-.165-2.722-3.835-4.866l-3.3-1.938.206-2.062a88.761,88.761,0,0,0-.124-13.774l-.33-1.856,3.464-2.021c4-2.309,4.784-3.341,3.835-5.238-1.361-2.763-6.1-10.64-6.681-11.094-1.031-.907-2.433-.536-5.9,1.443-2.928,1.691-3.464,1.856-4.083,1.4-.371-.33-1.526-1.237-2.557-2.062a35.177,35.177,0,0,0-9.238-5.155l-2.516-.907-.206-4.289c-.124-2.8-.412-4.578-.784-5.031-.495-.577-1.567-.66-7.877-.66S293.622-299.918,293.127-299.34Zm13.857,29.529a14.485,14.485,0,0,1,6.351,6.516,15.492,15.492,0,0,1,.165,11.218,15.229,15.229,0,0,1-6.681,6.8c-1.98.99-2.8,1.155-5.815,1.155s-3.835-.165-5.815-1.155a15.229,15.229,0,0,1-6.681-6.8,15.492,15.492,0,0,1,.165-11.218,14.48,14.48,0,0,1,8.619-7.465,17.309,17.309,0,0,1,4.33-.206A10.891,10.891,0,0,1,306.984-269.812Z"
                                                          transform="translate(-119.996 -69.616)"
                                                        />
                                                        <path
                                                          id="Path_14332"
                                                          data-name="Path 14332"
                                                          d="M100.011-251.182a57.317,57.317,0,0,0-20.249,8.331,64.033,64.033,0,0,0-13.115,12.785,52.512,52.512,0,0,0-9.857,29.611c-.041,3.629.082,4.454.99,6.392a13.521,13.521,0,0,0,6.516,6.516l2.268,1.072h47.427c36.54,0,47.675-.124,48.582-.495.949-.454,1.113-.742,1.113-2.186,0-1.567-.082-1.691-2.145-2.722-1.2-.619-2.8-1.526-3.629-2.062l-1.526-1.031L154.16-193.9a10.722,10.722,0,0,1-9.815-.247,12.833,12.833,0,0,1-3.505-2.557,144.325,144.325,0,0,1-7.753-13.4,12.076,12.076,0,0,1,0-8.826c.866-1.98,3.712-5.073,5.073-5.485.7-.247.784-.7.784-4.7,0-4.372,0-4.454-1.072-5.031a13.691,13.691,0,0,1-5.155-6.434,12.7,12.7,0,0,1,.247-7.671c.742-1.773.949-1.608-3.3-2.639C125.539-251.842,104.712-252.048,100.011-251.182Z"
                                                          transform="translate(0 -97.978)"
                                                        />
                                                      </g>
                                                    </svg>
                                                  </span>
                                                </Link>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {(permissions &&
                                            permissions.includes("mc3")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button
                                                className={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? "edit disabled"
                                                    : "edit"
                                                }
                                                disabled={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {course.student_count > 0 &&
                                                roleid !== "1" ? (
                                                  <span>
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="15"
                                                        height="15"
                                                        viewBox="0 0 22.58 22.583"
                                                      >
                                                        <g
                                                          id="Group_11449"
                                                          data-name="Group 11449"
                                                          transform="translate(-2402.326 -6200.833)"
                                                        >
                                                          <path
                                                            id="Path_11890"
                                                            data-name="Path 11890"
                                                            d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                            transform="translate(752.656 5510.995)"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                          />
                                                          <path
                                                            id="Path_11892"
                                                            data-name="Path 11892"
                                                            d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                            transform="translate(751.921 5511.583)"
                                                          />
                                                        </g>
                                                      </svg>
                                                    </span>
                                                  </span>
                                                ) : (
                                                  <Link
                                                    to={
                                                      roleid === "1"
                                                        ? "/CourseDraft"
                                                        : "/EditCourse"
                                                    }
                                                    state={{ cid: course.id }}
                                                  >
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="15"
                                                        height="15"
                                                        viewBox="0 0 22.58 22.583"
                                                      >
                                                        <g
                                                          id="Group_11449"
                                                          data-name="Group 11449"
                                                          transform="translate(-2402.326 -6200.833)"
                                                        >
                                                          <path
                                                            id="Path_11890"
                                                            data-name="Path 11890"
                                                            d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                            transform="translate(752.656 5510.995)"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                          />
                                                          <path
                                                            id="Path_11892"
                                                            data-name="Path 11892"
                                                            d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                            transform="translate(751.921 5511.583)"
                                                          />
                                                        </g>
                                                      </svg>
                                                    </span>
                                                  </Link>
                                                )}
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {(permissions &&
                                            permissions.includes("mc4")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button
                                                onClick={
                                                  course.student_count ===
                                                    null || roleid === "1"
                                                    ? handleShow
                                                    : null
                                                }
                                                className={
                                                  course.student_count > 0 &&
                                                  roleid !== "1"
                                                    ? "delete disabled"
                                                    : "delete"
                                                }
                                                title="Delete Course"
                                                data-bs-target={course.id}
                                              >
                                                <span>
                                                  <svg
                                                    id="delete"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="15"
                                                    viewBox="0 0 16.079 20.664"
                                                  >
                                                    <path
                                                      id="Path_13633"
                                                      data-name="Path 13633"
                                                      d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                                      transform="translate(-57 512)"
                                                    />
                                                    <path
                                                      id="Path_13634"
                                                      data-name="Path 13634"
                                                      d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                                      transform="translate(-83.869 402.606)"
                                                    />
                                                  </svg>
                                                </span>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Course</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Course?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={handleDeleteCourse}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CoursesList;
