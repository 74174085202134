import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CourseService from "../../services/course.service";
import CoursesStatistic from "./CoursesStatistic";
import "./admin-dash.css";
import "./Responsive.css";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import CoursesList from "../../common/CoursesList";
import GraphService from "../../services/graph.service";
import AllEvents from "../../common/AllEvents";

const AdminDashbaord = ({ permissions, roleid }) => {
  const [courses, setCourses] = useState([]);
  const [date, setDate] = useState(new Date());
  const userdata = useSelector((state) => state.userDataReducer);
  const themeMode = useSelector((state) => state.themeReducer.themeMode);
  const [currentUser, setCurrentUser] = useState({});
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [currentCourse, setCurrentCourse] = useState(1);
  const [lastSevenDates, setLastSevenDates] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);
  const [courseEnrolmentValue, setCourseEnrolmentValue] = useState([]);
  const [courseComplitionValue, setCourseComplitionValue] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const [defaultReportFilter, setDefaultReportFilter] = useState("");

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const days =
      Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000)) + 1;
    const weekNumber = Math.ceil(days / 7);
    return weekNumber;
  }

  const today = new Date();

  useEffect(() => {
    setCurrentUser(userdata);
    getLastSevenDays();
    getMonths();
    getWeeks();

    GraphService.getStudentEnrollmentReportByCourseId(currentCourse)
      .then((resp) => {
        if (resp.data !== false) {
          const arr = resp.data.enrollmentLast15Days.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLast15Days.split(",");
          setCourseComplitionValue(newArr);
        }
      })
      .catch((e) => console.log(e));

    CourseService.getCourse(currentUser.user)
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const getLastSevenDays = () => {
    const lastFifteenDates = [];
    const today = new Date();
    const currentDate = new Date(today);

    for (let i = 0; i < 15; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
      lastFifteenDates.push(date);
    }

    const sortedDatesFifteen = lastFifteenDates.slice().sort((a, b) => a - b);

    const formattedDates = sortedDatesFifteen.map((date) => {
      const day = date.getDate().toString().padStart(2, "0");
      const month = monthNames[date.getMonth()];
      return `${month} ${day}`;
    });

    setLastSevenDates(formattedDates);
    setYAxisData(formattedDates);
  };

  const getMonths = () => {
    const lastSevenMonths = [];

    for (let i = 0; i < 12; i++) {
      const monthStartDate = new Date(today);
      monthStartDate.setMonth(monthStartDate.getMonth() - i);
      lastSevenMonths.push(monthStartDate);
    }

    const sortedMonths = lastSevenMonths.slice().sort((a, b) => {
      const monthA = a.getMonth();
      const monthB = b.getMonth();
      const yearA = a.getFullYear();
      const yearB = b.getFullYear();

      if (yearA === yearB) {
        return monthA - monthB;
      } else {
        return yearA - yearB;
      }
    });

    const formattedDates = sortedMonths.map((date) => {
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return `${month} ${year}`;
    });

    setMonths(formattedDates);
  };

  const getWeeks = () => {
    const lastSevenWeeks = [];

    for (let i = 0; i < 10; i++) {
      const weekStartDate = new Date(today);
      weekStartDate.setDate(today.getDate() - i * 7);
      const weekNumber = getWeekNumber(weekStartDate);
      const formattedWeek = `Week ${weekNumber}`;

      lastSevenWeeks.push({ formattedWeek, startDate: weekStartDate });
    }

    lastSevenWeeks.sort((a, b) => {
      return a.startDate - b.startDate;
    });

    const sortedWeeks = lastSevenWeeks.map((week) => week.formattedWeek);

    setWeeks(sortedWeeks);
  };

  const courseFilter = (id) => {
    setCurrentCourse(id);
    if (defaultReportFilter == 0) {
      setYAxisData(lastSevenDates);
      GraphService.getStudentEnrollmentReportByCourseId(id)
        .then((resp) => {
          const arr = resp.data.enrollmentLast15Days.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLast15Days.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (defaultReportFilter == 1) {
      setYAxisData(weeks);
      GraphService.getStudentEnrollmentReportByCourseId(id)
        .then((resp) => {
          const arr = resp.data.enrollmentLastWeek.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLastWeek.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (defaultReportFilter == 2) {
      setYAxisData(months);
      GraphService.getStudentEnrollmentReportByCourseId(id)
        .then((resp) => {
          const arr = resp.data.enrollmentLastMonth.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLastMonth.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    }
  };

  const filterReport = (e, cid) => {
    setDefaultReportFilter(e.target.value);
    if (e.target.value == 0) {
      setYAxisData(lastSevenDates);
      GraphService.getStudentEnrollmentReportByCourseId(cid)
        .then((resp) => {
          const arr = resp.data.enrollmentLast15Days.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLast15Days.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 1) {
      setYAxisData(weeks);
      GraphService.getStudentEnrollmentReportByCourseId(cid)
        .then((resp) => {
          const arr = resp.data.enrollmentLastWeek.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLastWeek.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 2) {
      setYAxisData(months);
      GraphService.getStudentEnrollmentReportByCourseId(cid)
        .then((resp) => {
          const arr = resp.data.enrollmentLastMonth.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLastMonth.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <>
      <div className="container-fluid admin-dash">
        <div className="row">
          <div className="col-xl-8 col-lg-8 mb-3">
            <div className="outer-box filter-courses">
              <div className="cflex">
                <div className="heading">
                  <h1>Filter by Courses</h1>
                </div>
                <div className="heading">
                  <h1>Courses Statistic</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-3">
                  <div className="course-list">
                    {Array.isArray(courses)
                      ? courses.map((course) => {
                          return (
                            <div
                              key={course.id}
                              className={
                                currentCourse == course.id
                                  ? "course-item active"
                                  : "course-item"
                              }
                            >
                              <button
                                onClick={(e) => courseFilter(course.id)}
                                title={course.name}
                              >
                                <span>{course.shortname}</span>
                              </button>
                            </div>
                          );
                        })
                      : null}

                    <div className="course-item">
                      <Link to="/ManageCourses">
                        <button>
                          <span>View All</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9">
                  <div className="inner-box">
                    {Array.isArray(courses)
                      ? courses.map((coursenew) => {
                          return (
                            <div key={coursenew.id}>
                              {currentCourse == coursenew.id ? (
                                <>
                                  <div className="cflex">
                                    <div className="heading">
                                      <h1>{coursenew.name}</h1>
                                    </div>
                                    <div className="error-req flex-end">
                                      <div className="">
                                        <div className="select">
                                          <select
                                            name=""
                                            id=""
                                            value={defaultReportFilter}
                                            onChange={(e) =>
                                              filterReport(e, coursenew.id)
                                            }
                                          >
                                            <option value="0">Day</option>
                                            <option value="1">Week</option>
                                            <option value="2">Month</option>
                                          </select>
                                          <div className="icon">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16.16"
                                              height="17.486"
                                              viewBox="0 0 16.16 17.486"
                                            >
                                              <g
                                                id="sort"
                                                transform="translate(0 0)"
                                              >
                                                <path
                                                  id="Path_11988"
                                                  data-name="Path 11988"
                                                  d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                                  transform="translate(-49 479.938)"
                                                />
                                                <path
                                                  id="Path_11989"
                                                  data-name="Path 11989"
                                                  d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                                  transform="translate(-221.306 356.907)"
                                                />
                                              </g>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <CoursesStatistic
                                    yAxisData={yAxisData}
                                    corsid={coursenew.id}
                                    courseEnrolmentValue={courseEnrolmentValue}
                                    courseComplitionValue={
                                      courseComplitionValue
                                    }
                                    themeMode={themeMode}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 mb-3">
            <div className="outer-box quick-actions">
              <div className="heading">
                <h1>Learners</h1>
              </div>
              <div className="learners-grid">
                {(permissions && permissions.includes("ml2")) ||
                roleid === "2" ? (
                  <>
                    <div className="learner-item">
                      <Link to="/AddUser">
                        <button className="flex g-10">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="28"
                              viewBox="0 0 40 38"
                            >
                              <g
                                id="users-group"
                                transform="translate(0.1 490.563)"
                              >
                                <path
                                  id="Path_11736"
                                  data-name="Path 11736"
                                  d="M145.62-490.517a5.219,5.219,0,0,0-4.094,2.959,4.408,4.408,0,0,0-.527,2.31,4.435,4.435,0,0,0,.543,2.343,4.006,4.006,0,0,0,.989,1.378,5.046,5.046,0,0,0,3.737,1.564,4.933,4.933,0,0,0,3.769-1.589,4.8,4.8,0,0,0,1.524-3.737,4.926,4.926,0,0,0-1.654-3.8A5.256,5.256,0,0,0,145.62-490.517Z"
                                  transform="translate(-130.221)"
                                />
                                <path
                                  id="Path_11737"
                                  data-name="Path 11737"
                                  d="M352.421-428.721a4.907,4.907,0,0,0-3.672,4.175l-.049.535.357.251a6.154,6.154,0,0,1,2.351,3.68c.089.5.113.543.389.665a4.036,4.036,0,0,0,1.832.316,4.07,4.07,0,0,0,1.459-.211,4.907,4.907,0,0,0,3.178-6.039,4.616,4.616,0,0,0-1.256-2.083,5.244,5.244,0,0,0-2.294-1.3A5.9,5.9,0,0,0,352.421-428.721Z"
                                  transform="translate(-322.037 -56.722)"
                                />
                                <path
                                  id="Path_11738"
                                  data-name="Path 11738"
                                  d="M46.7-367.721a4.757,4.757,0,0,0-2.74,1.954,4.241,4.241,0,0,0-.819,2.44,4.411,4.411,0,0,0,.478,2.489,4.813,4.813,0,0,0,4.361,2.7,4.442,4.442,0,0,0,2.051-.438c.43-.195.559-.3.592-.462a6.311,6.311,0,0,1,1.637-3.1l.608-.632-.016-.5a4.16,4.16,0,0,0-.511-1.921,4.9,4.9,0,0,0-3.186-2.561A5.853,5.853,0,0,0,46.7-367.721Z"
                                  transform="translate(-39.728 -112.744)"
                                />
                                <path
                                  id="Path_11739"
                                  data-name="Path 11739"
                                  d="M252.693-367.75a4.988,4.988,0,0,0-3.575,3.615c-.211.916-.186,1.029.381,1.443a6.137,6.137,0,0,1,2.14,3.323c.073.332.146.648.162.705.073.259,1.159.527,2.148.527a4.558,4.558,0,0,0,3.242-1.3,4.662,4.662,0,0,0,1.556-3.542,4.634,4.634,0,0,0-1.451-3.5,5.006,5.006,0,0,0-2.367-1.313A6.484,6.484,0,0,0,252.693-367.75Z"
                                  transform="translate(-229.865 -112.749)"
                                />
                                <path
                                  id="Path_11740"
                                  data-name="Path 11740"
                                  d="M162.986-354.681a.864.864,0,0,0-.349.1,1.693,1.693,0,0,0,.259.665,5.809,5.809,0,0,1,.657,2.108c0,.559.016.559.811.259a5.937,5.937,0,0,1,3.818-.105,2.117,2.117,0,0,0,.519.146c.008-.016.057-.292.113-.624a6.1,6.1,0,0,1,.778-2.091c.186-.284.195-.308.057-.389S163.578-354.754,162.986-354.681Z"
                                  transform="translate(-150.12 -124.836)"
                                />
                                <path
                                  id="Path_11741"
                                  data-name="Path 11741"
                                  d="M152.826-305.516a5.033,5.033,0,0,0-3.323,2.634,4.378,4.378,0,0,0-.5,2.148,4.378,4.378,0,0,0,.5,2.148,4.931,4.931,0,0,0,3.388,2.643,4.915,4.915,0,0,0,5.593-3.153,4.943,4.943,0,0,0-.422-4.175,5.315,5.315,0,0,0-2.74-2.132A5.669,5.669,0,0,0,152.826-305.516Z"
                                  transform="translate(-137.589 -169.964)"
                                />
                                <path
                                  id="Path_11742"
                                  data-name="Path 11742"
                                  d="M363.016-303.822c-.024.105-.057.373-.089.6a6.058,6.058,0,0,1-1.338,2.951l-.389.462.632.259a7.421,7.421,0,0,1,3.9,3.7,6.35,6.35,0,0,1,.7,3.315l.041,1.443.786-.057a18.218,18.218,0,0,0,5.666-1.265l.5-.227v-2.78c0-1.532-.041-3.015-.089-3.3a6.314,6.314,0,0,0-4.953-5.147,16.913,16.913,0,0,0-2.975-.13h-2.359Z"
                                  transform="translate(-333.524 -171.44)"
                                />
                                <path
                                  id="Path_11743"
                                  data-name="Path 11743"
                                  d="M4.893-242.619A6.423,6.423,0,0,0,.1-238.08a16.83,16.83,0,0,0-.17,3.615L-.1-231.328l.267.081a33.251,33.251,0,0,0,4.539,1.062,21.6,21.6,0,0,0,2.553.267,7.262,7.262,0,0,0,.113-1.41,9.075,9.075,0,0,1,.211-1.97,7.632,7.632,0,0,1,2.634-3.98,9.837,9.837,0,0,1,2.108-1.167l.308-.089-.405-.5a6.287,6.287,0,0,1-1.362-3.153l-.057-.511L8.1-242.717A20.994,20.994,0,0,0,4.893-242.619Z"
                                  transform="translate(0 -227.754)"
                                />
                                <path
                                  id="Path_11744"
                                  data-name="Path 11744"
                                  d="M263.051-242.254a6.108,6.108,0,0,1-1.419,3.048l-.332.381.624.251a7.605,7.605,0,0,1,3.972,3.785,7.57,7.57,0,0,1,.705,3.364l.049,1.321.657-.049a19.625,19.625,0,0,0,5.52-1.192l.649-.276.024-2.87a19.6,19.6,0,0,0-.105-3.494,6.265,6.265,0,0,0-3.769-4.556c-.965-.405-1.451-.462-4.053-.462h-2.4Z"
                                  transform="translate(-241.303 -227.495)"
                                />
                                <path
                                  id="Path_11745"
                                  data-name="Path 11745"
                                  d="M111.85-180.783a6.355,6.355,0,0,0-5.163,4.588c-.178.616-.186.746-.186,3.721v3.08l1.054.308c5.326,1.548,11.227,1.5,14.988-.122l.616-.259v-2.926c0-3.129-.041-3.526-.43-4.5a5.969,5.969,0,0,0-1.394-2.075,6.128,6.128,0,0,0-3.526-1.808C117.216-180.872,112.49-180.88,111.85-180.783Z"
                                  transform="translate(-98.484 -284.606)"
                                />
                              </g>
                            </svg>
                          </span>
                          <span>Add Learners</span>
                        </button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {(permissions && permissions.includes("ml1")) ||
                roleid === "2" ? (
                  <>
                    <div className="learner-item">
                      <Link to="/AllLearners">
                        <button className="flex g-10">
                          <span>
                            <svg
                              id="Group_10768"
                              data-name="Group 10768"
                              xmlns="http://www.w3.org/2000/svg"
                              width="34"
                              height="28"
                              viewBox="0 0 34.454 41.017"
                            >
                              <defs>
                                <clipPath id="clipPath">
                                  <rect
                                    id="Rectangle_179"
                                    data-name="Rectangle 179"
                                    width="34.454"
                                    height="41.017"
                                  />
                                </clipPath>
                              </defs>
                              <g
                                id="Group_10841"
                                data-name="Group 10841"
                                clipPath="url(#clip-path)"
                              >
                                <path
                                  id="Path_11720"
                                  data-name="Path 11720"
                                  d="M104.912,0a17.647,17.647,0,0,1,1.764.483A6.8,6.8,0,1,1,103.244.07a1.453,1.453,0,0,0,.225-.07Z"
                                  transform="translate(-86.967)"
                                />
                                <path
                                  id="Path_11721"
                                  data-name="Path 11721"
                                  d="M42.68,195.926c-1.035-.386-2.044-.761-3.052-1.139l-8.686-3.258a1.308,1.308,0,0,1-1.065-1.517c0-.732,0-1.465,0-2.224.3-.018.579-.014.85-.055a3.453,3.453,0,0,0,3.094-3.093,21.157,21.157,0,0,0,0-4.158,3.493,3.493,0,0,0-3.6-3.136c-.093,0-.186,0-.343,0v-1.194c0-.454,0-.908,0-1.362a1.208,1.208,0,0,1,1.745-1.2q3.243,1.206,6.48,2.426c1.423.534,2.844,1.073,4.27,1.6a.41.41,0,0,1,.333.448q-.014,8.774-.008,17.549c0,.09-.01.181-.018.312"
                                  transform="translate(-26.678 -154.909)"
                                />
                                <path
                                  id="Path_11722"
                                  data-name="Path 11722"
                                  d="M184.865,187.819c0,.919.033,1.8-.011,2.675-.03.591-.469.886-.992,1.08q-3.018,1.119-6.029,2.255l-5.242,1.964c-.16.06-.323.114-.517.183-.008-.145-.02-.259-.02-.372q0-8.715-.007-17.429a.459.459,0,0,1,.356-.515q5.324-1.978,10.639-3.979a1.227,1.227,0,0,1,1.833,1.272q0,1.162,0,2.324a.281.281,0,0,1-.02.072,11.877,11.877,0,0,0-1.291.189,3.458,3.458,0,0,0-2.657,3.1,27.307,27.307,0,0,0,.005,3.963,3.541,3.541,0,0,0,3.544,3.214c.12,0,.24,0,.415,0"
                                  transform="translate(-153.627 -154.964)"
                                />
                                <path
                                  id="Path_11723"
                                  data-name="Path 11723"
                                  d="M73.643,139.032a13.5,13.5,0,0,1,4.179-2.573.548.548,0,0,1,.4.073,9.1,9.1,0,0,0,9.512.012.632.632,0,0,1,.471-.062,13.356,13.356,0,0,1,4.005,2.449.85.85,0,0,1,.091.131l-2.211.829c-2.244.843-4.487,1.692-6.737,2.52a1.064,1.064,0,0,1-.679.014c-2.9-1.07-5.79-2.16-8.683-3.246-.1-.037-.195-.082-.346-.146"
                                  transform="translate(-65.759 -121.836)"
                                />
                                <path
                                  id="Path_11724"
                                  data-name="Path 11724"
                                  d="M4.8,234.834c0,.507.006,1.015,0,1.522a1.207,1.207,0,0,1-1.262,1.259,4.043,4.043,0,0,1-2-.3,2.8,2.8,0,0,1,1.159-5.3c.307-.01.615-.013.921,0A1.193,1.193,0,0,1,4.8,233.191c.016.547,0,1.1,0,1.643"
                                  transform="translate(0 -207.164)"
                                />
                                <path
                                  id="Path_11725"
                                  data-name="Path 11725"
                                  d="M276.883,234.849c0-.508-.006-1.015,0-1.522a1.193,1.193,0,0,1,1.234-1.243,7.09,7.09,0,0,1,1.354.074,2.771,2.771,0,0,1,2.21,2.96,2.814,2.814,0,0,1-2.672,2.565c-.293.012-.588.01-.881,0a1.2,1.2,0,0,1-1.244-1.235c-.009-.534,0-1.068,0-1.6"
                                  transform="translate(-247.238 -207.236)"
                                />
                              </g>
                            </svg>
                          </span>
                          <span>Manage Learners</span>
                        </button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {(permissions && permissions.includes("mgc2")) ||
                roleid === "2" ? (
                  <>
                    <div className="learner-item">
                      <Link to="/AddCohort">
                        <button className="flex g-10">
                          <div className="flex">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="38"
                                height="28"
                                viewBox="0 0 47.6 34.609"
                              >
                                <g
                                  id="Group_11663"
                                  data-name="Group 11663"
                                  transform="translate(8751 6542.751)"
                                >
                                  <path
                                    id="Path_14233"
                                    data-name="Path 14233"
                                    d="M327.414-188.609a1.2,1.2,0,0,0-.64.5l-.154.243-.018,1.82-.018,1.82-1.826.018-1.826.018-.237.16a1.163,1.163,0,0,0-.533.889.9.9,0,0,0,.142.64.928.928,0,0,0,.486.486l.314.166h3.486v3.474l.154.308a1.012,1.012,0,0,0,1.031.6,1.012,1.012,0,0,0,1.031-.6l.154-.308v-3.474h3.486l.314-.166a.928.928,0,0,0,.486-.486.872.872,0,0,0,.136-.646,1.159,1.159,0,0,0-.622-.937c-.255-.136-.29-.136-2.027-.136h-1.766l-.018-1.826c-.018-1.8-.018-1.82-.16-2.045a1.135,1.135,0,0,0-.9-.545A1.422,1.422,0,0,0,327.414-188.609Z"
                                    transform="translate(-9046.522 -6354.11)"
                                  />
                                  <path
                                    id="Path_14243"
                                    data-name="Path 14243"
                                    d="M220.887-408.511a5.805,5.805,0,0,0-3.433,3,8.354,8.354,0,0,0-.215,5.056c1.235,5.185,5.415,7.21,8.5,4.122a9.321,9.321,0,0,0,2.4-6.923,4.339,4.339,0,0,0-.5-2.327A5.588,5.588,0,0,0,220.887-408.511Z"
                                    transform="translate(-8949.408 -6124.365)"
                                  />
                                  <path
                                    id="Path_14241"
                                    data-name="Path 14241"
                                    d="M128.98-387.451a4.336,4.336,0,0,0-2.7,2.6,8.27,8.27,0,0,0,0,3.663c1.063,4.051,4.381,5.487,6.679,2.858a7.941,7.941,0,0,0,1.35-6.55A4.363,4.363,0,0,0,128.98-387.451Z"
                                    transform="translate(-8871.577 -6142.452)"
                                  />
                                  <path
                                    id="Path_14246"
                                    data-name="Path 14246"
                                    d="M329.871-387.487a4.541,4.541,0,0,0-2.715,2.571,8.525,8.525,0,0,0,.575,5.286c1.25,2.743,3.936,3.4,5.875,1.465a8.4,8.4,0,0,0,1.695-3.375,6.97,6.97,0,0,0-.3-3.806A4.313,4.313,0,0,0,329.871-387.487Z"
                                    transform="translate(-9044.316 -6142.739)"
                                  />
                                  <path
                                    id="Path_14242"
                                    data-name="Path 14242"
                                    d="M93.273-305.456a8.468,8.468,0,0,0-4.97,3.835,5.544,5.544,0,0,0-.445,2.787l-.057,1.953,1.221.3a38,38,0,0,0,6.521.977l1.767.1v-1.781a12.845,12.845,0,0,1,.215-2.643,9.86,9.86,0,0,1,3.3-5.113c.546-.388.618-.488.417-.56a1.475,1.475,0,0,0-.919.316,6.138,6.138,0,0,1-2.729.833,6.887,6.887,0,0,1-2.887-.847l-.661-.4Z"
                                    transform="translate(-8838.8 -6212.64)"
                                  />
                                  <path
                                    id="Path_14244"
                                    data-name="Path 14244"
                                    d="M177.5-305.641c-3.146,1.106-5.415,2.988-6.248,5.214a11.173,11.173,0,0,0-.273,3.332s22.942.3,24.547-.9c0-3.232-2.14-5.846-6.061-7.412-1.724-.689-1.925-.689-2.916-.086a6,6,0,0,1-3.375.934,5.922,5.922,0,0,1-3.361-.934,4.1,4.1,0,0,0-1.077-.5A7.024,7.024,0,0,0,177.5-305.641Z"
                                    transform="translate(-8910.018 -6212.383)"
                                  />
                                  <path
                                    id="Path_14245"
                                    data-name="Path 14245"
                                    d="M330.136-305.614c-.2.072-.1.2.488.646a9.788,9.788,0,0,1,3.217,5.042,12.845,12.845,0,0,1,.215,2.643v1.781l1.767-.1a38,38,0,0,0,6.521-.977l1.221-.3-.057-1.953a5.544,5.544,0,0,0-.445-2.787,8.414,8.414,0,0,0-4.97-3.835l-.776-.244-.661.4a6.887,6.887,0,0,1-2.887.847,6.138,6.138,0,0,1-2.729-.833A1.593,1.593,0,0,0,330.136-305.614Z"
                                    transform="translate(-9046.966 -6212.963)"
                                  />
                                </g>
                              </svg>
                            </span>
                            <span>Add Cohort</span>
                          </div>
                        </button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {(permissions && permissions.includes("mgc1")) ||
                roleid === "2" ? (
                  <>
                    <div className="learner-item">
                      <Link to="/Cohorts">
                        <button className="flex g-10">
                          <div className="flex">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="28"
                                viewBox="0 0 42.826 39.883"
                              >
                                <g
                                  id="Group_11664"
                                  data-name="Group 11664"
                                  transform="translate(8280 6657)"
                                >
                                  <path
                                    id="Path_14234"
                                    data-name="Path 14234"
                                    d="M220.441-408.542a5.145,5.145,0,0,0-3.043,2.661,7.4,7.4,0,0,0-.191,4.481c1.095,4.6,4.8,6.391,7.537,3.654a8.262,8.262,0,0,0,2.126-6.136,3.846,3.846,0,0,0-.446-2.062A4.952,4.952,0,0,0,220.441-408.542Z"
                                    transform="translate(-8480.518 -6248.219)"
                                  />
                                  <path
                                    id="Path_14235"
                                    data-name="Path 14235"
                                    d="M128.65-387.475a3.843,3.843,0,0,0-2.393,2.3,7.33,7.33,0,0,0,0,3.246c.942,3.59,3.883,4.863,5.92,2.533a7.038,7.038,0,0,0,1.2-5.805A3.867,3.867,0,0,0,128.65-387.475Z"
                                    transform="translate(-8401.203 -6266.65)"
                                  />
                                  <path
                                    id="Path_14236"
                                    data-name="Path 14236"
                                    d="M329.539-387.511a4.025,4.025,0,0,0-2.406,2.279,7.556,7.556,0,0,0,.509,4.685c1.108,2.432,3.488,3.017,5.207,1.3a7.445,7.445,0,0,0,1.5-2.992,6.178,6.178,0,0,0-.267-3.374A3.823,3.823,0,0,0,329.539-387.511Z"
                                    transform="translate(-8576.502 -6266.614)"
                                  />
                                  <path
                                    id="Path_14237"
                                    data-name="Path 14237"
                                    d="M92.65-305.484a7.505,7.505,0,0,0-4.4,3.4,4.914,4.914,0,0,0-.395,2.47l-.051,1.731,1.082.267a33.681,33.681,0,0,0,5.78.866l1.566.089v-1.579a11.384,11.384,0,0,1,.191-2.342,8.739,8.739,0,0,1,2.928-4.532c.484-.344.547-.433.369-.5a1.308,1.308,0,0,0-.815.28,5.44,5.44,0,0,1-2.419.738,6.1,6.1,0,0,1-2.559-.751l-.586-.356Z"
                                    transform="translate(-8367.8 -6338.177)"
                                  />
                                  <path
                                    id="Path_14238"
                                    data-name="Path 14238"
                                    d="M175.95-305.682c-2.788.98-4.8,2.648-5.538,4.621-.356.917-.509,2.954-.242,2.954.1,0,.356-.293.56-.649a6.424,6.424,0,0,1,1.018-1.209c1.54-1.362,3.234-1.617,4.57-.687l.535.356.153-.738c.382-1.82,2.113-2.839,4.481-2.635,2.062.178,3.3,1.171,3.514,2.826l.089.56.535-.369c1.349-.929,3.043-.675,4.583.687a5.592,5.592,0,0,1,1.018,1.209c.522.878.7.84.7-.153,0-2.864-1.9-5.181-5.372-6.569-1.528-.611-1.706-.611-2.584-.076a5.317,5.317,0,0,1-2.992.828,5.249,5.249,0,0,1-2.979-.828,3.635,3.635,0,0,0-.955-.446A6.22,6.22,0,0,0,175.95-305.682Z"
                                    transform="translate(-8439.566 -6337.915)"
                                  />
                                  <path
                                    id="Path_14239"
                                    data-name="Path 14239"
                                    d="M330.126-305.624c-.178.064-.089.178.433.573a8.676,8.676,0,0,1,2.852,4.468,11.385,11.385,0,0,1,.191,2.342v1.579l1.566-.089a33.682,33.682,0,0,0,5.78-.866l1.082-.267-.051-1.731a4.914,4.914,0,0,0-.395-2.47,7.457,7.457,0,0,0-4.4-3.4l-.687-.216-.586.356a6.1,6.1,0,0,1-2.559.751,5.44,5.44,0,0,1-2.419-.738A1.413,1.413,0,0,0,330.126-305.624Z"
                                    transform="translate(-8579.203 -6338.177)"
                                  />
                                  <path
                                    id="Path_14240"
                                    data-name="Path 14240"
                                    d="M178.366-270.681a2.021,2.021,0,0,0-.471.331c-.255.229-.293.382-.293,1.311v1.044l-.662.216a9.124,9.124,0,0,0-1.248.509l-.573.293-.789-.751c-.509-.5-.891-.751-1.095-.751-.5,0-1.68.98-2.292,1.91-.369.547-.267.853.535,1.693l.751.789-.229.446a8.1,8.1,0,0,0-.509,1.235l-.28.8h-1.044c-.968,0-1.069.025-1.362.331s-.306.42-.306,1.731c0,1.375,0,1.4.356,1.706.318.267.484.306,1.362.306h.993l.28.789a7.792,7.792,0,0,0,.509,1.248l.229.446-.751.789c-.5.509-.751.891-.751,1.095a4.607,4.607,0,0,0,2.139,2.393c.509.191.789.064,1.54-.7l.687-.7.586.293a9.123,9.123,0,0,0,1.248.509l.662.216.038,1.095c.038,1.031.064,1.108.395,1.362.318.229.535.267,1.706.267,1.3,0,1.337-.013,1.642-.356.267-.318.306-.484.306-1.362v-.993l.8-.28a8.1,8.1,0,0,0,1.235-.509l.446-.229.789.751c.509.5.891.751,1.095.751a4.607,4.607,0,0,0,2.393-2.139c.191-.509.064-.789-.7-1.54l-.7-.687.293-.586a9.3,9.3,0,0,0,.509-1.26l.229-.662h1.006c.9,0,1.057-.038,1.324-.306.382-.382.5-.98.42-2.19-.051-.764-.127-1.006-.356-1.26-.267-.293-.395-.318-1.349-.318h-1.044l-.28-.8a8.1,8.1,0,0,0-.509-1.235l-.229-.446.751-.789c.5-.509.751-.891.751-1.095,0-.5-.98-1.68-1.91-2.292-.547-.369-.853-.267-1.693.535l-.789.751-.573-.293a9.121,9.121,0,0,0-1.248-.509l-.662-.216v-1.044c0-.955-.025-1.082-.318-1.349s-.458-.318-1.566-.344A7.366,7.366,0,0,0,178.366-270.681Zm3.081,6.938a4.772,4.772,0,0,1,2.3,2.253,3.313,3.313,0,0,1,.407,1.922,3.312,3.312,0,0,1-.407,1.922,4.828,4.828,0,0,1-2.3,2.253,3.454,3.454,0,0,1-1.808.344,3.454,3.454,0,0,1-1.808-.344,4.828,4.828,0,0,1-2.3-2.253,3.312,3.312,0,0,1-.407-1.922,3.385,3.385,0,0,1,.331-1.8A4.569,4.569,0,0,1,181.447-263.742Z"
                                    transform="translate(-8438.226 -6368.69)"
                                  />
                                </g>
                              </svg>
                            </span>
                            <span>Manage Cohort</span>
                          </div>
                        </button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {(permissions && permissions.includes("mp2")) ||
                roleid === "2" ? (
                  <>
                    <div className="learner-item">
                      <Link to="/CreatePathway">
                        <button className="flex g-10">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="28"
                              viewBox="0 0 35.005 33.709"
                            >
                              <g
                                id="Group_11665"
                                data-name="Group 11665"
                                transform="translate(-8090 -24258)"
                              >
                                <path
                                  id="Path_14247"
                                  data-name="Path 14247"
                                  d="M52.011-431.894a1.346,1.346,0,0,0-.681.721c-.065.162-.089-.122-.089,7.8v11.458h-1.3v-18.167l-.567.032a1.265,1.265,0,0,0-1.167.583c-.17.219-.17.276-.194,9.537L48-410.615H62.885l.049-1a8.24,8.24,0,0,1,1.167-3.946,8.4,8.4,0,0,1,6.75-4.108,8.463,8.463,0,0,1,7.268,3.411l.348.478-.016-9.643-.024-5.753-.235-.3c-.438-.575.551-.535-13.354-.535C54.531-432,52.214-431.983,52.011-431.894Zm1.823,1.831v.648h-1.3v-1.3h1.3Zm2.593,0v.648h-1.3v-1.3h1.3Zm2.593,0v.648h-1.3v-1.3h.648Zm18.151,1.3v.648H73.282v-1.3h3.889Zm-20.1,2.593v.648H52.538v-1.3h4.538Zm20.1,0v.648H73.282v-1.3h3.889Zm-22.089,2.69c.365.105,1.183.851,1.183,1.07,0,.113.219.13,1.783.13s1.783-.016,1.783-.13c0-.219.818-.964,1.183-1.07a1.8,1.8,0,0,1,1.961.527,1.822,1.822,0,0,1,.421.543c0,.113.186.13,1.459.13s1.459-.016,1.459-.13c0-.219.818-.964,1.183-1.07a1.8,1.8,0,0,1,1.961.527,1.822,1.822,0,0,1,.421.543c0,.113.219.13,1.783.13s1.783-.016,1.783-.13c0-.219.818-.964,1.183-1.07a1.775,1.775,0,0,1,1.937.51,1.752,1.752,0,0,1,0,2.674,1.758,1.758,0,0,1-2.69-.016,1.759,1.759,0,0,1-.429-.543c0-.113-.219-.13-1.783-.13s-1.783.016-1.783.13c0,.219-.818.964-1.183,1.07a1.787,1.787,0,0,1-1.953-.527,1.759,1.759,0,0,1-.429-.543c0-.113-.186-.13-1.459-.13s-1.459.016-1.459.13c0,.219-.818.964-1.183,1.07a1.787,1.787,0,0,1-1.953-.527,1.759,1.759,0,0,1-.429-.543c0-.113-.219-.13-1.783-.13s-1.783.016-1.783.13c0,.219-.818.964-1.183,1.07a1.775,1.775,0,0,1-1.937-.51,1.752,1.752,0,0,1,0-2.674A1.789,1.789,0,0,1,55.082-423.483Zm.7,5.737v.648H53.186v-1.3h2.593Zm7.131,0v.648H60.317v-1.3H62.91Zm-.648,3.241v.648H53.186v-1.3h9.075Z"
                                  transform="translate(8042 24690.008)"
                                />
                                <path
                                  id="Path_14249"
                                  data-name="Path 14249"
                                  d="M120.337-311.8a.64.64,0,0,0,.446,1.094.684.684,0,0,0,.648-.648.684.684,0,0,0-.648-.648A.632.632,0,0,0,120.337-311.8Z"
                                  transform="translate(7975.699 24579.725)"
                                />
                                <path
                                  id="Path_14250"
                                  data-name="Path 14250"
                                  d="M208.337-311.8a.64.64,0,0,0,.446,1.094.684.684,0,0,0,.648-.648.684.684,0,0,0-.648-.648A.632.632,0,0,0,208.337-311.8Z"
                                  transform="translate(7894.83 24579.725)"
                                />
                                <path
                                  id="Path_14251"
                                  data-name="Path 14251"
                                  d="M288.2-311.8a.632.632,0,0,0-.2.446.632.632,0,0,0,.2.446.632.632,0,0,0,.446.2.632.632,0,0,0,.446-.2.632.632,0,0,0,.2-.446.632.632,0,0,0-.2-.446.632.632,0,0,0-.446-.2A.632.632,0,0,0,288.2-311.8Z"
                                  transform="translate(7821.448 24579.725)"
                                />
                                <path
                                  id="Path_14252"
                                  data-name="Path 14252"
                                  d="M376.2-311.8a.632.632,0,0,0-.2.446.632.632,0,0,0,.2.446.632.632,0,0,0,.446.2.632.632,0,0,0,.446-.2.632.632,0,0,0,.2-.446.632.632,0,0,0-.2-.446.632.632,0,0,0-.446-.2A.632.632,0,0,0,376.2-311.8Z"
                                  transform="translate(7740.578 24579.725)"
                                />
                                <path
                                  id="Path_14253"
                                  data-name="Path 14253"
                                  d="M440-398.276v9.724h1.945l-.016-9.3-.024-9.31-.235-.3a1.35,1.35,0,0,0-1.224-.535H440Z"
                                  transform="translate(7681.764 24667.945)"
                                />
                                <path
                                  id="Path_14254"
                                  data-name="Path 14254"
                                  d="M254.387-263.77a9.365,9.365,0,0,0-1.937.551,7.153,7.153,0,0,0-3.241,10.186,8.194,8.194,0,0,0,2.447,2.447,7.143,7.143,0,0,0,8.695-1.078,7.426,7.426,0,0,0,1.9-3.379,8.557,8.557,0,0,0-.024-3.4,7.171,7.171,0,0,0-5.656-5.267A8.7,8.7,0,0,0,254.387-263.77Zm2,1.353a5.4,5.4,0,0,1,2.107.851,6.094,6.094,0,0,1,2,2.22,5.011,5.011,0,0,1,.608,2.731,4.422,4.422,0,0,1-.219,1.661,5.548,5.548,0,0,1-1.475,2.35,5.549,5.549,0,0,1-2.35,1.475,4.421,4.421,0,0,1-1.661.219,5.012,5.012,0,0,1-2.731-.608,6.143,6.143,0,0,1-2.779-3.055,5.989,5.989,0,0,1,.381-5.04,7.865,7.865,0,0,1,2.074-2.1A5.8,5.8,0,0,1,256.389-262.417Z"
                                  transform="translate(7858.018 24535.441)"
                                />
                                <path
                                  id="Path_14255"
                                  data-name="Path 14255"
                                  d="M284.065-231.716a5,5,0,0,0-2.245,1.232,4.244,4.244,0,0,0-1.321,3.217,4.224,4.224,0,0,0,1.3,3.193,4.224,4.224,0,0,0,3.193,1.3,4.223,4.223,0,0,0,3.193-1.3,4.224,4.224,0,0,0,1.3-3.193,3.907,3.907,0,0,0-.551-2.2,5.311,5.311,0,0,0-1.572-1.661A5.118,5.118,0,0,0,284.065-231.716Zm.932,1.839v.624l-.413.1a2.007,2.007,0,0,0-1.475,1.475l-.1.413h-1.28l.057-.429a3.8,3.8,0,0,1,.851-1.783,3.474,3.474,0,0,1,2.139-1.021l.219-.008Z"
                                  transform="translate(7828.34 24506.012)"
                                />
                                <path
                                  id="Path_14256"
                                  data-name="Path 14256"
                                  d="M48-150.906c0,1.264.073,1.523.535,1.872l.3.235,7.933.024,7.925.016-.276-.381a10.254,10.254,0,0,1-1.11-2.277L63.12-152H48Z"
                                  transform="translate(8042 24432.689)"
                                />
                                <path
                                  id="Path_14257"
                                  data-name="Path 14257"
                                  d="M425.91-151.417a10.635,10.635,0,0,1-.5,1.207l-.308.616.438.429.429.421.729-.024c.786-.032,1-.13,1.345-.592.154-.194.17-.357.195-1.434L428.26-152H426.1Z"
                                  transform="translate(7695.457 24432.689)"
                                />
                                <path
                                  id="Path_14258"
                                  data-name="Path 14258"
                                  d="M403.5-108.49l-.5.51.527.519.527.527.5-.51.51-.5-.527-.527-.527-.527Z"
                                  transform="translate(7715.766 24393.174)"
                                />
                                <path
                                  id="Path_14259"
                                  data-name="Path 14259"
                                  d="M172.335-95.9c-.016.057-.373,1.224-.778,2.6l-.745,2.488H168v1.3h14.261v-1.3h-2.82l-.122-.381c-.057-.219-.324-1.1-.583-1.977l-.478-1.588-.567-.284a8.124,8.124,0,0,1-1.021-.624L176.233-96H174.3A6.273,6.273,0,0,0,172.335-95.9Z"
                                  transform="translate(7931.724 24381.227)"
                                />
                                <path
                                  id="Path_14260"
                                  data-name="Path 14260"
                                  d="M427.986-83.514l-.486.494,1.629,1.613c1.442,1.442,1.653,1.621,1.888,1.621a.8.8,0,0,0,.737-.737c0-.235-.178-.438-1.6-1.872-.883-.883-1.621-1.6-1.645-1.6A4.656,4.656,0,0,0,427.986-83.514Z"
                                  transform="translate(7693.251 24370.199)"
                                />
                              </g>
                            </svg>
                          </span>
                          <span>Add Pathway</span>
                        </button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {(permissions && permissions.includes("mp1")) ||
                roleid === "2" ? (
                  <>
                    <div className="learner-item">
                      <Link to="/ManagePathway">
                        <button className="flex g-10">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="28"
                              viewBox="0 0 33.717 33.709"
                            >
                              <g
                                id="Group_11666"
                                data-name="Group 11666"
                                transform="translate(-8090 -24258)"
                              >
                                <path
                                  id="Path_14247"
                                  data-name="Path 14247"
                                  d="M52.011-431.894a1.346,1.346,0,0,0-.681.721c-.065.162-.089-.122-.089,7.8v11.458h-1.3v-18.167l-.567.032a1.265,1.265,0,0,0-1.167.583c-.17.219-.17.276-.194,9.537L48-410.615H62.885l.049-1a8.24,8.24,0,0,1,1.167-3.946,8.4,8.4,0,0,1,6.75-4.108,8.463,8.463,0,0,1,7.268,3.411l.348.478-.016-9.643-.024-5.753-.235-.3c-.438-.575.551-.535-13.354-.535C54.531-432,52.214-431.983,52.011-431.894Zm1.823,1.831v.648h-1.3v-1.3h1.3Zm2.593,0v.648h-1.3v-1.3h1.3Zm2.593,0v.648h-1.3v-1.3h.648Zm18.151,1.3v.648H73.282v-1.3h3.889Zm-20.1,2.593v.648H52.538v-1.3h4.538Zm20.1,0v.648H73.282v-1.3h3.889Zm-22.089,2.69c.365.105,1.183.851,1.183,1.07,0,.113.219.13,1.783.13s1.783-.016,1.783-.13c0-.219.818-.964,1.183-1.07a1.8,1.8,0,0,1,1.961.527,1.822,1.822,0,0,1,.421.543c0,.113.186.13,1.459.13s1.459-.016,1.459-.13c0-.219.818-.964,1.183-1.07a1.8,1.8,0,0,1,1.961.527,1.822,1.822,0,0,1,.421.543c0,.113.219.13,1.783.13s1.783-.016,1.783-.13c0-.219.818-.964,1.183-1.07a1.775,1.775,0,0,1,1.937.51,1.752,1.752,0,0,1,0,2.674,1.758,1.758,0,0,1-2.69-.016,1.759,1.759,0,0,1-.429-.543c0-.113-.219-.13-1.783-.13s-1.783.016-1.783.13c0,.219-.818.964-1.183,1.07a1.787,1.787,0,0,1-1.953-.527,1.759,1.759,0,0,1-.429-.543c0-.113-.186-.13-1.459-.13s-1.459.016-1.459.13c0,.219-.818.964-1.183,1.07a1.787,1.787,0,0,1-1.953-.527,1.759,1.759,0,0,1-.429-.543c0-.113-.219-.13-1.783-.13s-1.783.016-1.783.13c0,.219-.818.964-1.183,1.07a1.775,1.775,0,0,1-1.937-.51,1.752,1.752,0,0,1,0-2.674A1.789,1.789,0,0,1,55.082-423.483Zm.7,5.737v.648H53.186v-1.3h2.593Zm7.131,0v.648H60.317v-1.3H62.91Zm-.648,3.241v.648H53.186v-1.3h9.075Z"
                                  transform="translate(8042 24690.008)"
                                />
                                <path
                                  id="Path_14249"
                                  data-name="Path 14249"
                                  d="M120.337-311.8a.64.64,0,0,0,.446,1.094.684.684,0,0,0,.648-.648.684.684,0,0,0-.648-.648A.632.632,0,0,0,120.337-311.8Z"
                                  transform="translate(7975.699 24579.725)"
                                />
                                <path
                                  id="Path_14250"
                                  data-name="Path 14250"
                                  d="M208.337-311.8a.64.64,0,0,0,.446,1.094.684.684,0,0,0,.648-.648.684.684,0,0,0-.648-.648A.632.632,0,0,0,208.337-311.8Z"
                                  transform="translate(7894.83 24579.725)"
                                />
                                <path
                                  id="Path_14251"
                                  data-name="Path 14251"
                                  d="M288.2-311.8a.632.632,0,0,0-.2.446.632.632,0,0,0,.2.446.632.632,0,0,0,.446.2.632.632,0,0,0,.446-.2.632.632,0,0,0,.2-.446.632.632,0,0,0-.2-.446.632.632,0,0,0-.446-.2A.632.632,0,0,0,288.2-311.8Z"
                                  transform="translate(7821.448 24579.725)"
                                />
                                <path
                                  id="Path_14252"
                                  data-name="Path 14252"
                                  d="M376.2-311.8a.632.632,0,0,0-.2.446.632.632,0,0,0,.2.446.632.632,0,0,0,.446.2.632.632,0,0,0,.446-.2.632.632,0,0,0,.2-.446.632.632,0,0,0-.2-.446.632.632,0,0,0-.446-.2A.632.632,0,0,0,376.2-311.8Z"
                                  transform="translate(7740.578 24579.725)"
                                />
                                <path
                                  id="Path_14253"
                                  data-name="Path 14253"
                                  d="M440-398.276v9.724h1.945l-.016-9.3-.024-9.31-.235-.3a1.35,1.35,0,0,0-1.224-.535H440Z"
                                  transform="translate(7681.764 24667.945)"
                                />
                                <path
                                  id="Path_14256"
                                  data-name="Path 14256"
                                  d="M48-150.906c0,1.264.073,1.523.535,1.872l.3.235,7.933.024,7.925.016-.276-.381a10.254,10.254,0,0,1-1.11-2.277L63.12-152H48Z"
                                  transform="translate(8042 24432.689)"
                                />
                                <path
                                  id="Path_14257"
                                  data-name="Path 14257"
                                  d="M425.91-151.417a10.635,10.635,0,0,1-.5,1.207l-.308.616.438.429.429.421.729-.024c.786-.032,1-.13,1.345-.592.154-.194.17-.357.195-1.434L428.26-152H426.1Z"
                                  transform="translate(7695.457 24432.689)"
                                />
                                <path
                                  id="Path_14259"
                                  data-name="Path 14259"
                                  d="M172.335-95.9c-.016.057-.373,1.224-.778,2.6l-.745,2.488H168v1.3h14.261v-1.3h-2.82l-.122-.381c-.057-.219-.324-1.1-.583-1.977l-.478-1.588-.567-.284a8.124,8.124,0,0,1-1.021-.624L176.233-96H174.3A6.273,6.273,0,0,0,172.335-95.9Z"
                                  transform="translate(7931.724 24381.227)"
                                />
                                <path
                                  id="Path_14261"
                                  data-name="Path 14261"
                                  d="M174.138-270.7a1.157,1.157,0,0,0-.269.189c-.145.131-.167.218-.167.749v.6l-.378.124a5.22,5.22,0,0,0-.713.291l-.327.167-.451-.429c-.291-.283-.509-.429-.626-.429-.284,0-.96.56-1.31,1.09-.211.313-.153.487.306.967l.429.451-.131.254a4.626,4.626,0,0,0-.291.7l-.16.458h-.6c-.553,0-.611.015-.778.189s-.175.24-.175.988c0,.785,0,.8.2.974.182.153.276.174.778.174h.567l.16.451a4.449,4.449,0,0,0,.291.712l.131.254-.429.451c-.284.291-.429.509-.429.625A2.631,2.631,0,0,0,171-259.338c.291.109.451.036.88-.4l.393-.4.335.167a5.218,5.218,0,0,0,.713.291l.378.124.022.625c.022.589.036.632.226.778s.306.153.975.153c.742,0,.764-.007.938-.2.153-.182.175-.276.175-.778v-.567l.458-.16a4.622,4.622,0,0,0,.706-.291l.255-.131.451.429c.291.283.509.429.626.429a2.632,2.632,0,0,0,1.368-1.221c.109-.291.036-.451-.4-.879l-.4-.392.167-.334a5.3,5.3,0,0,0,.291-.719l.131-.378h.575c.517,0,.6-.022.757-.174.218-.218.284-.56.24-1.25-.029-.436-.073-.574-.2-.719-.153-.167-.226-.182-.771-.182h-.6l-.16-.458a4.629,4.629,0,0,0-.291-.7l-.131-.254.429-.451c.284-.291.429-.509.429-.625,0-.283-.56-.959-1.091-1.308-.313-.211-.487-.153-.968.305l-.451.429-.327-.167a5.219,5.219,0,0,0-.713-.291l-.378-.124v-.6c0-.545-.015-.618-.182-.77s-.262-.182-.895-.2A4.221,4.221,0,0,0,174.138-270.7Zm1.761,3.961a2.726,2.726,0,0,1,1.317,1.286,1.889,1.889,0,0,1,.233,1.1,1.889,1.889,0,0,1-.233,1.1,2.757,2.757,0,0,1-1.317,1.286,1.975,1.975,0,0,1-1.033.2,1.975,1.975,0,0,1-1.033-.2,2.757,2.757,0,0,1-1.317-1.286,1.889,1.889,0,0,1-.233-1.1,1.93,1.93,0,0,1,.189-1.025A2.612,2.612,0,0,1,175.9-266.744Z"
                                  transform="translate(7938.316 24543.229)"
                                />
                              </g>
                            </svg>
                          </span>
                          <span>Manage Pathway</span>
                        </button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="learner-item">
                  <Link to="/Reports">
                    <button className="flex g-10">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 40.62 39.217"
                        >
                          <g
                            id="data-analytics"
                            transform="translate(-0.195 502.875)"
                          >
                            <path
                              id="Path_14262"
                              data-name="Path 14262"
                              d="M60.909-502.8a3.724,3.724,0,0,0-2.541,2.366,4,4,0,0,0-.032,2.184l.143.389L56.3-495.675,54.111-493.5l-.516-.254a3.668,3.668,0,0,0-3.081.048,3.638,3.638,0,0,0-1.342,1.128c-.111.183-.23.333-.27.333s-.794-.381-1.691-.85l-1.62-.85.064-.238a6.515,6.515,0,0,0,.064-1,2.261,2.261,0,0,0-.3-1.374,3.512,3.512,0,0,0-4.7-1.771,3.941,3.941,0,0,0-1.945,2.35,4.455,4.455,0,0,0-.008,1.715c.071.246.048.27-2.176,2.493l-2.255,2.255-.461-.135a3.108,3.108,0,0,0-1.58-.135,3.543,3.543,0,0,0-2.978,4.042,3.528,3.528,0,0,0,4.995,2.668,3.579,3.579,0,0,0,2.017-2.8,2.433,2.433,0,0,0-.087-1.294l-.167-.675,2.112-2.112,2.12-2.12.4.191a3.589,3.589,0,0,0,2.93.008l.413-.183,2.287,1.191,2.287,1.2.183.445a3,3,0,0,0,.905,1.278,3.532,3.532,0,0,0,5.654-1.239,3.592,3.592,0,0,0,.238-1.834l-.079-.476,2.311-2.311,2.311-2.319.453.167a3.187,3.187,0,0,0,1.12.159,3.543,3.543,0,0,0,3.51-2.946,3.574,3.574,0,0,0-2.779-4.058A3.867,3.867,0,0,0,60.909-502.8Z"
                              transform="translate(-26.759)"
                            />
                            <path
                              id="Path_14263"
                              data-name="Path 14263"
                              d="M393.839-352.662a1.22,1.22,0,0,0-.421.254c-.445.413-.421-.27-.421,11.419v10.8h6.988v-21.487l-.175-.349c-.341-.667-.381-.675-3.24-.691C395.2-352.726,393.966-352.7,393.839-352.662Z"
                              transform="translate(-361.612 -138.232)"
                            />
                            <path
                              id="Path_14264"
                              data-name="Path 14264"
                              d="M150.667-286.676a1.372,1.372,0,0,0-.437.373l-.191.27-.024,8.274L150-269.476h6.988v-8.2c0-7.829-.008-8.211-.151-8.473a1.507,1.507,0,0,0-.357-.437c-.206-.151-.341-.159-2.89-.175A13.445,13.445,0,0,0,150.667-286.676Z"
                              transform="translate(-137.91 -198.946)"
                            />
                            <path
                              id="Path_14265"
                              data-name="Path 14265"
                              d="M271.754-248.853a1.459,1.459,0,0,0-.683.842c-.032.175-.071,3.248-.071,6.837v6.535h6.988v-6.734c0-6.575-.008-6.734-.159-6.988a1.46,1.46,0,0,0-.413-.413c-.246-.151-.4-.159-2.882-.151C273.1-248.924,271.842-248.892,271.754-248.853Z"
                              transform="translate(-249.302 -233.784)"
                            />
                            <path
                              id="Path_14266"
                              data-name="Path 14266"
                              d="M29.572-210.724a1.461,1.461,0,0,0-.413.413c-.151.254-.159.413-.159,5.479v5.225h6.908v-10.569l-.31-.334-.318-.333-2.724-.024C29.945-210.883,29.826-210.875,29.572-210.724Z"
                              transform="translate(-26.518 -268.816)"
                            />
                            <path
                              id="Path_14267"
                              data-name="Path 14267"
                              d="M.83-38.873a1.164,1.164,0,0,0-.492,1.6,1.463,1.463,0,0,0,.476.484l.3.167H20.523c18.74,0,19.407-.008,19.677-.151a1.222,1.222,0,0,0,.4-1.755c-.365-.516,1.159-.476-20.106-.476C2.784-39.008,1.069-38.992.83-38.873Z"
                              transform="translate(0 -427.032)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Manage Reports</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 mb-3">
            <div className="outer-box">
              <div className="inner-box">
                <div className="cflex">
                  <div className="heading">
                    <h1>Manage Courses</h1>
                  </div>
                </div>
                <CoursesList permissions={permissions} roleid={roleid} />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 mb-3">
            <div className="outer-box">
              <div className="cflex">
                <div className="heading">
                  <h1>Schedule and Submissions</h1>
                </div>
                <button>
                  <Link to={"/Calendar"}>Create New Event</Link>
                </button>
              </div>
              <div className="flex-cal">
                <Calendar onChange={setDate} value={date} />
                <AllEvents />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDashbaord;
