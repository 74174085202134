import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import "./login.css";
import VerigyOTPService from "../../services/verifyotp.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ChangePassword = () => {
  const checkBtn = useRef();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [vid, setVid] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);

  const onChangeOTP = (e) => {
    const otp = e.target.value;
    setOtp(otp);
  };
  const onChangeNewPassword = (e) => {
    const password = e.target.value;
    setNewPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const password = e.target.value;
    setConfirmPassword(password);
  };

  const toggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const verifyOTP = () => {
    const email = localStorage.getItem("email");
    const data = {
      emla: email,
      vaota: otp,
    };

    if (data.vaota !== "") {
      VerigyOTPService.verifyOTP(data)
        .then((res) => {
          if (res.data.vid) {
            setVid(res.data.vid);
            setVerifyOtp(true);

            toast.success(
              "OTP Verified succesfully you can now change your password.",
              {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          } else {
            toast.warn("*Please enter a valid otp!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((e) => console.log(e));
    } else {
      toast.warn("*Please enter otp!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const changePassword = () => {
    if (newPassword !== "" && confirmPassword !== "") {
      if (newPassword === confirmPassword) {
        const data = {
          evid: vid,
          pasres: confirmPassword,
        };
        VerigyOTPService.changePassword(data)
          .then((res) => {
            if (res.data !== false) {
              toast.success("Password changed Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((e) => console.log(e));
      } else {
        toast.warn("*Password not matched!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast.warn("*Please fill all required fields!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <div className="login_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <Form>
                <div className="login active">
                  <div className="heading">
                    {verifyOtp ? (
                      <h1>Create New Password</h1>
                    ) : (
                      <h1>Verify OTP</h1>
                    )}
                  </div>
                  <ToastContainer />
                  <div className="form">
                    {verifyOtp ? (
                      <>
                        <div className="form-group togglePassword">
                          <input
                            type={showNewPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="input password"
                            required
                            value={newPassword}
                            onChange={onChangeNewPassword}
                            validations={[required]}
                          />
                          <label htmlFor="">New Password *</label>
                          <i
                            className={
                              showNewPassword
                                ? "uil uil-eye"
                                : "uil uil-eye-slash"
                            }
                            id="eye-icon"
                            onClick={toggleNewPassword}
                          ></i>
                        </div>
                        <div className="form-group togglePassword">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="input password"
                            required
                            value={confirmPassword}
                            onChange={onChangeConfirmPassword}
                            validations={[required]}
                          />
                          <label htmlFor="">Confirm Password *</label>
                          <i
                            className={
                              showConfirmPassword
                                ? "uil uil-eye"
                                : "uil uil-eye-slash"
                            }
                            id="eye-icon"
                            onClick={toggleConfirmPassword}
                          ></i>
                        </div>
                        <div className="form-group action">
                          <div className="flex_form-group">
                            <button
                              className="fill_btn"
                              type="button"
                              name="btn_login"
                              onClick={changePassword}
                            >
                              <span>Submit</span>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group togglePassword otp active">
                          <input
                            type="text"
                            id="otp"
                            name="otp"
                            className="input password"
                            required
                            value={otp}
                            onChange={onChangeOTP}
                            validations={[required]}
                          />
                          <label htmlFor="">OTP</label>
                        </div>
                        <div className="form-group action">
                          <div className="flex_form-group">
                            <button
                              className="fill_btn"
                              type="button"
                              name="btn_login"
                              onClick={verifyOTP}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
            </div>
            <div className="col-xl-6 col-lg-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
