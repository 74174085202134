import React, { useState, useEffect } from "react";

// import { AreaChart, Area, LineChart, Legend, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { Line } from "react-chartjs-2";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const CourseEnrolment = ({
  courseEnrolmentData,
  courseEnrolmentValue,
  courseCompletionValue,
  themeMode,
}) => {
  var style = getComputedStyle(document.body);

  if (themeMode === "0") {
    var whiteColor = "#f5f5f5";
  } else if (themeMode === "1") {
    var whiteColor = "#010001";
  } else {
    var whiteColor = style.getPropertyValue("--whiteColor");
  }

  const data = {
    labels: courseEnrolmentData,
    datasets: [
      {
        label: "Completion",
        data: courseCompletionValue,
        backgroundColor: "#56D364",
        borderColor: "#56D364",
        fill: false,
        borderWidth: 2,
        tension: 0.5,
      },
      {
        label: "Enrollment",
        data: courseEnrolmentValue,
        backgroundColor: "#1158C7",
        borderColor: "#1158C7",
        fill: false,
        borderWidth: 2,
        tension: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        // text: (ctx) => 'Student Course Enrollment'
      },
      tooltip: {
        mode: "index",
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          filter: function (legendItem, data) {
            return legendItem.index != 1;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1, // set the step size to 1 to display integer values
        },
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <>
      <div className="line-area">
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default CourseEnrolment;
