import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./EditProfile.css";
import UserService from "../services/user.service";
const ChangeLoggedinPassword = () => {
  const [profileDetails, setProfileDetails] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });

  const [validated, setValidated] = useState(false);

  const [changeData, setChangeData] = useState(false);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const toggleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfPassword = () => {
    setShowConfPassword(!showConfPassword);
  };

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfileDetails({ ...profileDetails, [name]: value });
    setChangeData(true);
  };

  const updateProfile = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      toast.warn("*Please fill all required fields!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setValidated(true);
    event.preventDefault();

    if (profileDetails.password === profileDetails.confirm_password) {
      const data = {
        cfpstd: profileDetails.old_password,
        pasof: profileDetails.password,
      };
      console.log(data);

      UserService.changePassword(data)
        .then((resp) => {
          console.log(resp.data);
          if (resp.data === true) {
            toast.success("Password has been changed successfully.!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((e) => console.log(e));
    } else {
      toast.warn("Password and confirm password are not matching.!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer addUser">
              <div className="box-inner">
                <div className="heading">
                  <h1>Change Password</h1>
                </div>
                <ToastContainer />
                <Form noValidate validated={validated} onSubmit={updateProfile}>
                  <div className="add-user-form">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Old Password *</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type={showOldPassword ? "text" : "password"}
                          placeholder="Enter Old Password"
                          name="old_password"
                          required
                          value={profileDetails.old_password}
                          onChange={handleChange}
                          validations={[required]}
                        />
                        <span className="icon eye-icon">
                          <i
                            className={
                              showOldPassword
                                ? "uil uil-eye"
                                : "uil uil-eye-slash"
                            }
                            id="eye-icon"
                            onClick={toggleOldPassword}
                          ></i>
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Password *</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                          name="password"
                          required
                          value={profileDetails.password}
                          onChange={handleChange}
                          validations={[required]}
                        />
                        <span className="icon eye-icon">
                          <i
                            className={
                              showPassword ? "uil uil-eye" : "uil uil-eye-slash"
                            }
                            id="eye-icon"
                            onClick={togglePassword}
                          ></i>
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicDesignation"
                    >
                      <Form.Label>Confirm Password *</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type={showConfPassword ? "text" : "password"}
                          placeholder="Confirm Password"
                          name="confirm_password"
                          required
                          value={profileDetails.confirm_password}
                          onChange={handleChange}
                          validations={[required]}
                        />
                        <span className="icon eye-icon">
                          <i
                            className={
                              showConfPassword
                                ? "uil uil-eye"
                                : "uil uil-eye-slash"
                            }
                            id="eye-icon"
                            onClick={toggleConfPassword}
                          ></i>
                        </span>
                      </div>
                    </Form.Group>
                  </div>

                  <div className="act-btn">
                    <button disabled={!changeData}>Submit</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeLoggedinPassword;
