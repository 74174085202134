import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Loading from "../assets/images/Loading.gif";

const Conversation = () => {
  return (
    <>
      <div className="inner-box-flex">
        <div className="inner-box left">
          <div className="search-bar">
            <input type="text" placeholder="Search..." />
          </div>
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
            <Tab eventKey="home" title="All">
              <div className="user-list">
                <div className="loading">
                  <h1>Chatbot is not available right now</h1>
                  <img src={Loading} width="200px" alt="" />
                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="People">
              <div className="user-list">
                <div className="loading">
                  <h1>No Courses Assigned yet</h1>
                  <img src={Loading} width="200px" alt="" />
                </div>
              </div>
            </Tab>
            <Tab eventKey="contact" title="Group">
              <div className="user-list">
                <div className="loading">
                  <h1>No Courses Assigned yet</h1>
                  <img src={Loading} width="200px" alt="" />
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default Conversation;
