import React, { useState, useEffect } from "react";
import ReactRouterPrompt from "react-router-prompt";
const useUnsavedChangesWarning = (
  message = "Logout before closing the window!"
) => {
  const [isDirty, setDirty] = useState(false);

  useEffect(() => {
    //Detecting browser closing
    window.onbeforeunload = isDirty && (() => message);

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  const routerPrompt = <ReactRouterPrompt when={isDirty} message={message} />;

  return [routerPrompt, () => setDirty(true), () => setDirty(false)];
};

export default useUnsavedChangesWarning;
