import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { Modal, Form } from "react-bootstrap";
import Select from "react-select";
import UserService from "../../services/user.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CourseService from "../../services/course.service";

const Settings = ({ settings, hideSettingsMenu }) => {
  const [roleid, setRoleid] = useState("");
  const [show, setShow] = useState(false);
  const [showAtmp, setShowAtmp] = useState(false);
  const [learners, setLearners] = useState([]);
  const [courses, setCourses] = useState([]);
  const [activities, setActivities] = useState([]);
  const [coursId, setCoursId] = useState("");
  const [actId, setActId] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCourseOption, setSelectedCourseOption] = useState(null);
  const [selectedActivityOption, setSelectedActivityOption] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const [profileDetails, setProfileDetails] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });

  const [isFocused, setIsFocused] = useState(false);
  const [isCFocused, setIsCFocused] = useState(false);

  const [matched, setMatched] = useState(false);

  const [isValid, setIsValid] = useState(false);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setProfileDetails({ ...profileDetails, email: selectedOption.label });
  };

  const handleCourseChange = (selectedOption) => {
    setSelectedCourseOption(selectedOption);
    setCoursId(selectedOption.value);

    CourseService.getCourseActivity(selectedOption.value)
      .then((resp) => {
        const actData = resp.data;
        setActivities(
          Array.isArray(actData)
            ? actData.map((act) => ({
                value: act.id,
                label: act.activity_name,
              }))
            : null
        );
      })
      .catch((e) => console.log(e));
  };

  const handleActivityChange = (selectedOption) => {
    setSelectedActivityOption(selectedOption);
    setActId(selectedOption.value);
  };

  const handlePasswordChange = (e) => {
    setChangeData(true);
    const newPassword = e.target.value;
    setProfileDetails({ ...profileDetails, password: newPassword });
    const passwordIsValid = validatePassword(newPassword);
    setIsValid(passwordIsValid);
  };

  const handleCPasswordChange = (e) => {
    setChangeData(true);
    const newPassword = e.target.value;
    setProfileDetails({ ...profileDetails, confirm_password: newPassword });
    if (
      profileDetails.password &&
      profileDetails.confirm_password &&
      profileDetails.password === newPassword
    ) {
      setMatched(true);
    } else {
      setMatched(false);
    }
  };

  const handleFocus = () => {
    if (isValid) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  const handleBlur = () => {
    if (isValid) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  const handleCFocus = () => {
    setIsCFocused(true);
  };

  const handleCBlur = () => {
    setIsCFocused(false);
  };

  const validatePassword = (password) => {
    const user = AuthService.getCurrentUser();
    const regex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*[a-zA-Z\d])(?!.*(?:GAA|gaa|Gaa)).{8,}$/;

    if (!regex.test(password)) {
      return false;
    }

    if (
      (user.fname &&
        (password.toLowerCase().includes(user.fname.toLowerCase()) ||
          password.toUpperCase().includes(user.fname.toUpperCase()))) ||
      (user.lname &&
        (password.toLowerCase().includes(user.lname.toLowerCase()) ||
          password.toUpperCase().includes(user.lname.toUpperCase())))
    ) {
      return false;
    }

    // Check for consecutive numbers in ascending order (e.g., 123, 456)
    for (let i = 0; i < password.length - 2; i++) {
      const currentCharCode = password.charCodeAt(i);
      const nextCharCode = password.charCodeAt(i + 1);
      const afterNextCharCode = password.charCodeAt(i + 2);

      if (
        isDigit(currentCharCode) &&
        isDigit(nextCharCode) &&
        isDigit(afterNextCharCode) &&
        parseInt(password[i + 1]) - parseInt(password[i]) === 1 &&
        parseInt(password[i + 2]) - parseInt(password[i + 1]) === 1
      ) {
        return false;
      }
    }

    // Check for consecutive numbers in descending order (e.g., 321, 654)
    for (let i = 0; i < password.length - 2; i++) {
      const currentCharCode = password.charCodeAt(i);
      const nextCharCode = password.charCodeAt(i + 1);
      const afterNextCharCode = password.charCodeAt(i + 2);

      if (
        isDigit(currentCharCode) &&
        isDigit(nextCharCode) &&
        isDigit(afterNextCharCode) &&
        parseInt(password[i]) - parseInt(password[i + 1]) === 1 &&
        parseInt(password[i + 1]) - parseInt(password[i + 2]) === 1
      ) {
        return false;
      }
    }

    return true;
  };

  function isDigit(charCode) {
    return charCode >= 48 && charCode <= 57; // ASCII values for digits 0-9
  }

  const handleClose = () => {
    setShow(false);
    setProfileDetails({
      email: "",
      password: "",
      confirm_password: "",
    });
    setSelectedOption(null);
    setIsFocused(false);
    setIsValid(false);
    setIsCFocused(false);
  };

  const handleShow = (e) => {
    setShow(true);
  };

  const handleAtmpClose = () => {
    setShowAtmp(false);
  };

  const handleAtmpShow = (e) => {
    setShowAtmp(true);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfPassword = () => {
    setShowConfPassword(!showConfPassword);
  };

  const getAllLearnersData = () => {
    UserService.getLearnersResetPsw()
      .then((response) => {
        const learnersData = response.data;

        setLearners(
          Array.isArray(learnersData)
            ? learnersData.map((learner) => ({
                value: learner.id,
                label: learner.email,
              }))
            : null
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllCourses = () => {
    CourseService.getCourse()
      .then((resp) => {
        const courseData = resp.data;
        setCourses(
          Array.isArray(courseData)
            ? courseData.map((course) => ({
                value: course.id,
                label: course.name,
              }))
            : null
        );
      })
      .catch((e) => console.log(e));
  };

  const resetPassword = (e) => {
    e.preventDefault();

    console.log("Hi");

    if (profileDetails.password === profileDetails.confirm_password) {
      const data = {
        maildo: profileDetails.email,
        pasof: profileDetails.password,
      };

      console.log(data);

      UserService.resetPassword(data)
        .then((resp) => {
          if (resp.data === true) {
            toast.success("Password has been changed successfully!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((e) => console.log(e));
    } else {
      toast.warn("*Password are not matching!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setRoleid(user.role_id);
    }

    if (user && user.role_id !== "4") {
      getAllLearnersData();
      getAllCourses();
    }
  }, []);

  const resetAssessmentAtmp = (e) => {
    e.preventDefault();

    const data = {
      emelo: profileDetails.email,
      corsid: coursId,
      acrto: actId,
    };

    UserService.resetAssessmentAtmpAdmin(data)
      .then((resp) => {
        if (resp.data === true) {
          toast.success("Course Attempt reset successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warn(resp.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <ToastContainer />
      <div className={settings ? "settings-menu active" : "settings-menu"}>
        <div className="deleteIcon" onClick={hideSettingsMenu}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 16 16"
            >
              <g id="incorrect" transform="translate(-42.855 468.853)">
                <path
                  id="Path_11894"
                  data-name="Path 11894"
                  d="M49.823-468.8a8.028,8.028,0,0,0-6.569,5.449,8.179,8.179,0,0,0-.353,3.393,8.029,8.029,0,0,0,6.978,7.057,10.241,10.241,0,0,0,1.858.015,8.016,8.016,0,0,0,6.306-4.453,8.037,8.037,0,0,0,.394-6.076,8.068,8.068,0,0,0-4.2-4.7,8.362,8.362,0,0,0-2.5-.707A11.241,11.241,0,0,0,49.823-468.8Zm-1.438,4.4c.068.034.653.586,1.3,1.229L50.855-462l1.175-1.172c.642-.643,1.224-1.195,1.287-1.229a.752.752,0,0,1,.92.162.754.754,0,0,1,.161.921c-.034.064-.586.646-1.227,1.289L52-460.855l1.171,1.172c.642.646,1.2,1.236,1.235,1.308a.986.986,0,0,1,.008.7,1.245,1.245,0,0,1-.405.4,1.019,1.019,0,0,1-.676-.019c-.071-.038-.661-.594-1.3-1.236l-1.175-1.172-1.171,1.172c-.646.643-1.227,1.2-1.291,1.229a.752.752,0,0,1-.92-.162.754.754,0,0,1-.161-.921c.034-.064.586-.646,1.227-1.293l1.171-1.172-1.171-1.176c-.642-.643-1.194-1.225-1.227-1.289a.821.821,0,0,1,.488-1.142A.935.935,0,0,1,48.385-464.4Z"
                />
              </g>
            </svg>
          </span>
        </div>
        <div className="menu">
          <ul>
            <li>
              <Link to="/ThemeSettings" onClick={hideSettingsMenu}>
                <span>Theme Settings</span>
              </Link>
            </li>

            {roleid === "1" ||
            roleid === "2" ||
            roleid === "3" ||
            roleid === "4" ? (
              <>
                <li>
                  <Link to="/ReconfigureLMS" onClick={hideSettingsMenu}>
                    <span>Reconfigure LMS</span>
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}

            {roleid === "1" ? (
              <>
                <li>
                  <Link to="/ServerSettings" onClick={hideSettingsMenu}>
                    <span>Server Settings</span>
                  </Link>
                </li>
                <li>
                  <Link to="/CertificateConfigure" onClick={hideSettingsMenu}>
                    <span>Certificate Configuration</span>
                  </Link>
                </li>
              </>
            ) : roleid === "4" ? (
              <>
                <li>
                  <Link to="/TransactionHistory" onClick={hideSettingsMenu}>
                    <span>Transaction History</span>
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}
            {/* <li>
              <Link to="/ChangePassword" onClick={hideSettingsMenu}>
                <span>Change Password</span>
              </Link>
            </li> */}
            {roleid !== "4" ? (
              <>
                <li>
                  <Link
                    to="/#"
                    onClick={() => {
                      hideSettingsMenu();
                      handleShow();
                    }}
                  >
                    <span>Reset Learner Password</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/#"
                    onClick={() => {
                      hideSettingsMenu();
                      handleAtmpShow();
                    }}
                  >
                    <span>Reset Attempts</span>
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Reset Learner Password</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email *</Form.Label>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    height: "50px",
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: state.isFocused
                      ? "var(--body-dark-bg)"
                      : "var(--body-dark-bg)",
                  }),
                  option: (baseStyles, { isFocused, isSelected }) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? "var(--primary-color)"
                      : isFocused
                      ? "var(--secondary-color)"
                      : "var(--body-dark-bg)",
                    color: isSelected
                      ? "var(--body-dark-bg)"
                      : isFocused
                      ? "var(--body-dark-bg)"
                      : "var(--whiteColor)",
                  }),
                }}
                onChange={handleChange}
                value={selectedOption}
                options={learners}
                issearchable="true"
                placeholder="Search email.."
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Password *</Form.Label>
              <div className="input-box">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  name="password"
                  required
                  value={profileDetails.password}
                  onChange={handlePasswordChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <span className="icon eye-icon">
                  <i
                    className={
                      showPassword ? "uil uil-eye" : "uil uil-eye-slash"
                    }
                    id="eye-icon"
                    onClick={togglePassword}
                  ></i>
                </span>
              </div>
            </Form.Group>
            {isFocused && !isValid && (
              <p style={{ color: "#ed4337", fontWeight: "bold" }}>
                Password must contain alpha-numeric with a special character and
                be at least 8 characters long.
              </p>
            )}
            {isFocused && isValid && (
              <p style={{ color: "#49b618", fontWeight: "bold" }}>
                Password is valid!
              </p>
            )}

            <Form.Group className="mb-3" controlId="formBasicDesignation">
              <Form.Label>Confirm Password *</Form.Label>
              <div className="input-box">
                <Form.Control
                  type={showConfPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="confirm_password"
                  required
                  value={profileDetails.confirm_password}
                  onChange={handleCPasswordChange}
                  onFocus={handleCFocus}
                  onBlur={handleCBlur}
                />
                <span className="icon eye-icon">
                  <i
                    className={
                      showConfPassword ? "uil uil-eye" : "uil uil-eye-slash"
                    }
                    id="eye-icon"
                    onClick={toggleConfPassword}
                  ></i>
                </span>
              </div>
            </Form.Group>
            {isCFocused && !isValid && (
              <p style={{ color: "#ed4337", fontWeight: "bold" }}>
                Please enter Password!
              </p>
            )}
            {isCFocused && isValid && matched && (
              <p style={{ color: "#49b618", fontWeight: "bold" }}>
                Passwords are matching!
              </p>
            )}
            {isValid && !matched && (
              <p style={{ color: "#ed4337", fontWeight: "bold" }}>
                Passwords are not matching!
              </p>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button
            className="yes"
            disabled={!changeData}
            onClick={(e) => {
              resetPassword(e);
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAtmp} onHide={handleAtmpClose} centered>
        <Modal.Header>
          <Modal.Title>Reset Attempts</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleAtmpClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email *</Form.Label>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    height: "50px",
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: state.isFocused
                      ? "var(--body-dark-bg)"
                      : "var(--body-dark-bg)",
                  }),
                  option: (baseStyles, { isFocused, isSelected }) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? "var(--primary-color)"
                      : isFocused
                      ? "var(--secondary-color)"
                      : "var(--body-dark-bg)",
                    color: isSelected
                      ? "var(--body-dark-bg)"
                      : isFocused
                      ? "var(--body-dark-bg)"
                      : "var(--whiteColor)",
                  }),
                }}
                onChange={handleChange}
                value={selectedOption}
                options={learners}
                issearchable="true"
                placeholder="Search email.."
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Course</Form.Label>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    height: "50px",
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: state.isFocused
                      ? "var(--body-dark-bg)"
                      : "var(--body-dark-bg)",
                  }),
                  option: (baseStyles, { isFocused, isSelected }) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? "var(--primary-color)"
                      : isFocused
                      ? "var(--secondary-color)"
                      : "var(--body-dark-bg)",
                    color: isSelected
                      ? "var(--body-dark-bg)"
                      : isFocused
                      ? "var(--body-dark-bg)"
                      : "var(--whiteColor)",
                  }),
                }}
                onChange={handleCourseChange}
                value={selectedCourseOption}
                options={courses}
                issearchable="true"
                placeholder="Select course.."
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicDesignation">
              <Form.Label>Activity</Form.Label>
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    height: "50px",
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: state.isFocused
                      ? "var(--body-dark-bg)"
                      : "var(--body-dark-bg)",
                  }),
                  option: (baseStyles, { isFocused, isSelected }) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? "var(--primary-color)"
                      : isFocused
                      ? "var(--secondary-color)"
                      : "var(--body-dark-bg)",
                    color: isSelected
                      ? "var(--body-dark-bg)"
                      : isFocused
                      ? "var(--body-dark-bg)"
                      : "var(--whiteColor)",
                  }),
                }}
                onChange={handleActivityChange}
                value={selectedActivityOption}
                options={activities}
                issearchable="true"
                placeholder="Select course.."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button
            className="yes"
            onClick={(e) => {
              resetAssessmentAtmp(e);
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Settings;
